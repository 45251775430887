import { Box, Grid, IconButton, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useAudioControls } from "../hooks/use-audio-controls";
import { Dropdown } from "components/dropdown";
import { useChatsContext } from "../../chats-context";
import { DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import { slimScroll } from "chakra/theme";
import { PlayIcon } from "components/vectors/play-icon";
import { PauseIcon } from "components/vectors/pause-icon";
import { SendIcon2 } from "components/vectors/send-icon";
import { FaMicrophone } from "react-icons/fa";
import { WhatsAppMessagesApi } from "../hooks/use-whats-app-session-controls/ws-messages-api";
import { useWhatsAppSessionControlsProps } from "../hooks/use-whats-app-session-controls";
import { WhatsAppSession } from "components/chat-window/chat-input";
import { sortArrayBy } from "utils/filter-array-by";

interface InputWhatsAppProps {
  chatStatus: useWhatsAppSessionControlsProps["chatStatus"];
  changeWhatsAppSession: useWhatsAppSessionControlsProps["changeWhatsAppSession"];
  whatsAppPhones: useWhatsAppSessionControlsProps["whatsAppPhones"];
  loadingPhones: useWhatsAppSessionControlsProps["loadingPhones"];
  wsSession: useWhatsAppSessionControlsProps["wsSession"];
  currentSession: WhatsAppSession | undefined;
}

export function InputWhatsApp({
  chatStatus,
  changeWhatsAppSession,
  loadingPhones,
  whatsAppPhones,
  wsSession,
  currentSession,
}: InputWhatsAppProps) {
  const textBox = useRef<HTMLDivElement>(null);
  const [messageBody, setMessageBody] = useState("");
  const { selectedAttendance } = useChatsContext();
  const {
    deleteAudio,
    isPaused,
    isUsingAudio,
    pauseAudio,
    playAudio,
    startAudio,
    stopAudio,
  } = useAudioControls();
  const whatsAppApi = WhatsAppMessagesApi({ wsSession });
  const clearChat = () => {
    textBox.current!.textContent = "";
    setMessageBody("");
  };

  return (
    <>
      <Grid
        w="97%"
        templateColumns={isUsingAudio ? "auto 40px 40px 40px" : "auto 40px"}
        gap="10px"
        flexDir="column"
        alignItems="stretch"
        pt="0px"
        width="97%"
        mx="auto"
      >
        <Dropdown
          options={sortArrayBy(whatsAppPhones, "nome", "ASC")}
          onChange={changeWhatsAppSession}
          value={currentSession?.id}
          leftIcon={<PhoneIcon />}
          borderRadius="5px 5px 0px 0px"
          optionValue="id"
          optionLabel="nome"
          isDisabled={!selectedAttendance}
          bg="#076058"
          _hover={{ bg: "#00a884" }}
          aria-label=""
          h="41px"
          isLoading={loadingPhones}
          // loadingText="Carregando"
          placeholder="Selecione um telefone"
          _loading={{
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          }}
          color="#fff"
          _disabled={{
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          }}
          width="100%"
          justifyContent="flex-start"
        >
          <Text mr="auto">
            {currentSession?.nome
              ? currentSession?.nome
              : "Selecione um telefone"}
          </Text>
        </Dropdown>
      </Grid>
      <Grid
        w="97%"
        mx="auto"
        templateColumns={isUsingAudio ? "auto 40px 40px 40px" : "auto 40px"}
        gap="10px"
        pb="8px"
        borderRadius="10"
      >
        <Box overflow="hidden" bg="#fff" borderRadius="0px 0px 20px 20px">
          <Box
            ref={textBox}
            p="10px 15px"
            minH="40px"
            fontSize="14"
            css={slimScroll}
            overflow="overlay"
            onKeyUp={(e) => {
              const { textContent } = e.currentTarget;
              setMessageBody(textContent || "");
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                whatsAppApi.sendText({ body: textContent || "" });
                clearChat();
              }
            }}
            onKeyDown={(e) =>
              e.key === "Enter" && !e.shiftKey ? e.preventDefault() : undefined
            }
            whiteSpace="pre-wrap"
            _focus={{ border: 0, outline: "none" }}
            pos="relative"
            role="textbox"
            cursor={chatStatus === "authenticated" ? undefined : "not-allowed"}
            _empty={{
              _hover: { cursor: "text" },
              _before: {
                content:
                  chatStatus === "authenticated"
                    ? '"Mensagem"'
                    : '"Sessão whatsapp não autenticada"',
              },
            }}
            maxH="132px"
            contentEditable={chatStatus === "authenticated"}
          />
        </Box>
        {isUsingAudio ? (
          <>
            <IconButton
              mt="auto"
              icon={<DeleteIcon />}
              borderRadius="full"
              bg="#076058"
              w="40px"
              _hover={{ bg: "#00a884" }}
              aria-label=""
              onClick={deleteAudio}
              _disabled={{
                _hover: { bg: "#076058" },
                opacity: 0.5,
                cursor: "not-allowed",
              }}
            />
            <IconButton
              mt="auto"
              icon={isPaused ? <PlayIcon color="#EB4E3D" /> : <PauseIcon />}
              borderRadius="full"
              bg="transparent"
              w="40px"
              _hover={{ bg: "transparent" }}
              aria-label=""
              color="#EB4E3D"
              border="3px solid #EB4E3D"
              onClick={isPaused ? playAudio : pauseAudio}
              _disabled={{
                _hover: { bg: "#076058" },
                opacity: 0.5,
                cursor: "not-allowed",
              }}
            />
          </>
        ) : null}
        <IconButton
          mt="auto"
          icon={
            messageBody.trim() ? (
              <SendIcon2 />
            ) : isUsingAudio ? (
              <SendIcon2 />
            ) : (
              <FaMicrophone />
            )
          }
          borderRadius="full"
          animation={isUsingAudio ? "recording 1.6s linear infinite" : ""}
          bg="#076058"
          w="40px"
          _hover={{ bg: "#00a884" }}
          aria-label=""
          onClick={async () => {
            if (messageBody.trim()) {
              const isSended = whatsAppApi.sendText({
                body: messageBody || "",
              });
              clearChat();
            } else {
              if (isUsingAudio) {
                const { audio } = await stopAudio({ finalize: true });
                if (audio) {
                  await whatsAppApi.sendAudio(audio);
                }
              } else startAudio();
            }
          }}
          disabled={chatStatus !== "authenticated"}
          _disabled={{
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          }}
        />
      </Grid>
    </>
  );
}
