import { useEffect, useId, useRef, useState } from "react";
import { Box, Flex, FlexProps, Spinner, Text } from "@chakra-ui/react";
import { AnimateElement } from "utils/animations";
import { useEventListener } from "services/events";
import { isClickedOut } from "components/popover";
import { Checkbox } from "components/checkbox";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface NewDropdownProps {
  options?: any[];
  value?: any;
  placeholder?: string;
  onChange?: (a: any) => void;
  isSelected?: (option: any) => boolean;
  labelRender?: (option: any) => any;
  placeholderRender?: (currentValue: any) => any;
  optionLabel?: string;
  containerProps?: FlexProps;
  height?: number;
  id?: string | number;
  multiSelect?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  maxH?: number;
}

export function NewDropdown({
  options,
  value,
  optionLabel = "name",
  isLoading,
  isDisabled,
  multiSelect,
  isSelected,
  labelRender,
  placeholderRender,
  onChange,
  containerProps,
  height = 48,
  id,
  maxH = 120,
}: NewDropdownProps) {
  let [isOpen, setIsOpen] = useState(false);
  const labelRef = useRef<HTMLDivElement>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const dropdownKey = useId();

  if (isLoading) isDisabled = true;

  // STEP 1 parallel animation (on opening)
  // STEP 1 animation (on closing)
  const changeBoxHeight = (progress: number) => {
    const currentHeight = `${progress}px`;
    const target = mainContainerRef.current;
    if (target) target.style.height = currentHeight;
  };

  // STEP 1 parallel animation (on opening)
  // STEP 2 animation (on closing)
  const revealTitle = (progress: number) => {
    const target = labelRef.current;
    if (target) {
      target.style.display = "flex";
      target.style.opacity = `${progress}`;
      if (progress === 0) target.style.display = "none";
    }
  };

  const fadeInOption = (target: HTMLDivElement, index: number) => {
    // Start Precision Delay
    AnimateElement(
      index * 50,
      [0, 1],
      () => {},
      () => {
        // Start Real Animation
        AnimateElement(50, [0, 1], (p) => {
          target.style.opacity = `${p}`;
          target.style.transform = `translateY(${(p - 1) * 15}px)`;
        });
      }
    );
  };

  // STEP 2 (on opening)
  const revealContainerOptions = () => {
    const target = optionsContainerRef.current;
    if (target) {
      target.style.display = "block";
      let index = 0;
      for (let element of target.children) {
        fadeInOption(element as HTMLDivElement, index);
        index++;
      }
    }
  };

  const getCurrentValues = () => ({
    currentBoxHeight:
      mainContainerRef.current?.clientHeight || (isOpen ? height : maxH),
    currentTitleOpacity: Number(
      mainContainerRef.current
        ? getComputedStyle(mainContainerRef.current).opacity
        : isOpen
        ? 1
        : 0
    ),
  });

  // const onToggle = () => setIsOpen((state) => !state);

  const onOpenAnimation = () => {
    const { currentBoxHeight, currentTitleOpacity } = getCurrentValues();
    AnimateElement(200, [currentTitleOpacity, 0], revealTitle);
    AnimateElement(200, [currentBoxHeight, maxH], changeBoxHeight, () => {
      revealContainerOptions();
    });
  };

  const onCloseAnimation = () => {
    const { currentBoxHeight, currentTitleOpacity } = getCurrentValues();
    AnimateElement(200, [currentBoxHeight, height], changeBoxHeight, () => {
      AnimateElement(200, [currentTitleOpacity, 1], revealTitle, () => {});
    });
  };

  const onOpen = () => {
    isOpen = true;
    setIsOpen(true);
  };
  const onClose = () => {
    isOpen = false;
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) onOpenAnimation();
    else onCloseAnimation();
  }, [isOpen]);

  useEventListener(
    "globalOnClick",
    (e) => {
      if (isOpen) if (isClickedOut(e, mainContainerRef)) onClose();
    },
    id ? `${id}` : dropdownKey
  );

  const getLabelValue = () => {
    if (Array.isArray(value)) {
      const isEmpty = !value.join();
      if (isEmpty) return "Selecione";
      else
        return value
          ?.map(
            (value: string) => options?.find((c) => c?.value === value)?.name
          )
          .join(", ");
    } else {
      if (value != null) return value;
      else return "Selecione";
    }
  };

  return (
    <>
      <Flex
        flexDir="column"
        ref={mainContainerRef}
        border="1px solid #D9D9D9"
        p="4px 0px"
        maxH={`${maxH}px`}
        w="100%"
        borderRadius="5px"
        height={`${height}px`}
        onClick={() => {
          if (options?.length) onOpen();
        }}
        overflow="auto"
        opacity={isDisabled ? 0.6 : 1}
        pointerEvents={isDisabled ? "none" : "auto"}
        userSelect="none"
        cursor="pointer"
        {...containerProps}
      >
        <Flex
          ref={labelRef}
          fontSize={12}
          mt="auto"
          mb="auto!important"
          fontWeight="medium"
          px="16px"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>
            {placeholderRender?.(value)
              ? placeholderRender(value)
              : getLabelValue()}
          </Text>
          {isLoading ? (
            <Spinner w="16px" h="16px" />
          ) : (
            <ChevronDownIcon w="20px" h="20px" />
          )}
        </Flex>
        {isOpen ? (
          <Box display="none" ref={optionsContainerRef} w="100%">
            {options?.map((option, index) => {
              const isActive =
                isSelected?.(option) ?? Array.isArray(value)
                  ? value.includes(option?.value)
                  : value === option?.value;
              return (
                <Flex
                  key={index}
                  bg={multiSelect ? "" : isActive ? "gray.100" : ""}
                  _hover={{ bg: "gray.100" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange?.(option);
                    if (!multiSelect) onClose();
                  }}
                  cursor="pointer"
                  p="4px 12px"
                  fontSize="12px"
                  alignItems="center"
                  opacity={0}
                >
                  {multiSelect ? (
                    <Checkbox
                      mr="5px"
                      isChecked={isActive}
                      onChange={() => onChange?.(option)}
                    />
                  ) : null}
                  {labelRender?.(option) ?? option?.name}
                </Flex>
              );
            })}
          </Box>
        ) : null}
      </Flex>
    </>
  );
}
