import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import api from "api/api";
import { Navbar3cplusTemplate } from "components/atendimentos-components/navbar-3cplus-template";
import { AttendanceDataForm } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";
import { GetLayout } from "components/get-layout";
import { openModalConfirm } from "components/modal-confirm-new";
import { useAttendanceContext } from "contexts/attendance-context";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useRouter } from "routes/router-control/use-router";
import { Toast } from "components/toast";

export function AtendimentoClientePage(props?: {
  source?: "tela-whats";
  attendanceId?: number;
  init?: boolean;
}) {
  const { formControl, dispatch, setTransparentLoading, transparentLoading } =
    useAttendanceContext();
  const [isDisabledBackButton, setIsDisabledBackButton] = useState(false);
  const router = useRouter();
  const params = new URLSearchParams(router.search);
  const attendanceId = params.get("id") || props?.attendanceId;
  const init =
    params.get("init") || (props?.init != null ? `${props?.init}` : null);
  const telaAtendimento = props?.source !== "tela-whats";

  const updateAtendimentoAndBack = async () => {
    setTransparentLoading(true);
    const { id } = formControl.values;
    try {
      const response = await api.put(
        `/atendimentos/${id}/kanban`,
        formControl.values
      );
      dispatch({ type: "initAttendance", payload: response.data });
      router.push("/atendimentos");
    } catch (error) {
      Toast({
        title: "Erro ao salvar alterações no atendimento!",
        status: "error",
      });
      setTransparentLoading(false);
      return;
    } finally {
      setTransparentLoading(false);
    }
  };

  useEffect(() => {
    if (!attendanceId || !init) router.push(`/atendimentos`);
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: "closeAttendance" });
    };
  }, []);

  return (
    <GetLayout
      withOutLayout={!telaAtendimento}
      HeadRender={Navbar3cplusTemplate}
    >
      {telaAtendimento ? (
        <Flex alignItems="center" mb="30px">
          <IconButton
            w="27px"
            h="27px"
            minW="27px"
            aria-label=""
            isDisabled={isDisabledBackButton}
            icon={<ChevronLeftIcon w="19px" h="19px" />}
            onClick={() => {
              if (formControl.pendingChanges) {
                openModalConfirm({
                  title: "Salvar alterações",
                  confirmButtonStyle: {
                    variant: undefined,
                    children: "Salvar",
                    leftIcon: <FaSave />,
                    loadingText: "Salvando",
                  },
                  rejectButtonStyle: {
                    children: "Descartar",
                    leftIcon: <CloseIcon w="12px" h="12px" />,
                  },
                  message: "Deseja salvar as alterações pendentes?",
                  onConfirm: async () => {
                    updateAtendimentoAndBack();
                  },
                  onReject: () => {
                    router.push("/atendimentos");
                  },
                });
              } else router.push("/atendimentos");
            }}
          />
          <Text color="#343B44" fontSize="24px" ml="12px" fontWeight="semibold">
            Atendimento
          </Text>
        </Flex>
      ) : null}

      <AttendanceDataForm
        attendanceId={attendanceId}
        init={init === "true"}
        setIsDisabledBackButton={setIsDisabledBackButton}
        telaAtendimento={telaAtendimento}
      />
    </GetLayout>
  );
}
