import { Flex } from "@chakra-ui/react";
import { AtendimentoClientePage } from "pages/atendimento-novo-kanban/atendimento";
import { useChatsContext } from "../chats-context";
import { calcTelaChatsHeight } from "../..";

export function AtendimentoFrame() {
  const { selectedAttendance } = useChatsContext();
  return selectedAttendance ? (
    <Flex
      flexDir="column"
      overflow="auto"
      maxH={calcTelaChatsHeight()}
      pos="relative"
      bg="#fff"
    >
      <AtendimentoClientePage
        source="tela-whats"
        attendanceId={selectedAttendance?.id}
        init={selectedAttendance.etapa === "PENDENTE"}
      />
    </Flex>
  ) : null;
}
