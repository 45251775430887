import { Text } from "@chakra-ui/react";
import { sortArrayBy } from "utils/filter-array-by";
import { useState } from "react";
import { useEventListener } from "services/events";
import { createWebSocketEventListener } from "utils/web-socket-events";
import { Message } from "..";
import MessageTemplate from "../message-template";
import { ModalWhatsAppImage } from "../message-template/modal-image";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";

export function MessagesChat({
  isUpdatingNumber,
  wsSession,
}: {
  isUpdatingNumber: boolean;
  wsSession: WebSocketHook<any, MessageEvent<any> | null>;
}) {
  const [messages, setMessages] = useState<Map<string, Message>>(new Map());

  const messagesArray = sortArrayBy(
    Array.from(messages.values()),
    "timestamp",
    "DESC"
  ) as Message[];

  useEventListener("clear-whatsapp-chat", () => setMessages(new Map()));

  createWebSocketEventListener("whatsapp-messages", (data) => {
    // setPageState("chat");
    const messages = data.whatsappMessages;
    setMessages(messages);
  });

  createWebSocketEventListener("whatsapp-new-message", (data) => {
    const message = data.whatsappNewMessage;
    const appendedMessage = messages.set(message.id, message);
    setMessages(appendedMessage);
  });

  return (
    <>
      {/* EMPTY MESSAGE */}
      {!isUpdatingNumber && !messagesArray?.length ? (
        <Text>Não há mensagens para esse atendimento.</Text>
      ) : null}
      {/* END EMPTY MESSAGE */}

      {/* CHAT */}
      {messagesArray.map((message, index) => (
        <MessageTemplate wsSession={wsSession} key={message.id} {...message} />
      ))}
      <ModalWhatsAppImage />
      {/* END CHAT */}
    </>
  );
}
