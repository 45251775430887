import { EditIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { useState, Dispatch } from "react";
import { Attendance } from "../types";
import { Toast } from "components/toast";

export function BtnLinkFormalizacao({
  formValues,
  dispatch,
}: {
  formValues: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  console.log(formValues.digitadoApi, "formValues.digitadoApi");
  return formValues.bancoDigitacao === "PAN" ? (
    <Button
      size="sm"
      px="18px"
      onClick={async () => {
        setIsLoading(true);
        try {
          const response = await api.get(
            `/propostas/generate-link/${formValues.id}`
          );
          dispatch({
            type: "changeFieldWithInitial",
            payload: {
              fieldName: "linkFormalizacao",
              data: response.data.linkFormalizacao,
            },
          });
        } catch {
          Toast({
            title: "Erro ao gerar Link Formalização!",
          });
        } finally {
          setIsLoading(false);
        }
      }}
      isLoading={isLoading}
      loadingText="Gerando Link"
      leftIcon={<EditIcon />}
      disabled={!formValues.digitadoApi}
    >
      Gerar Link
    </Button>
  ) : null;
}
