import { objectSchema } from "utils/object-methods";
import { CronowiseHigienizacaoProps } from ".";
import { fieldValidation } from "utils/field-validation";

const parseConvenio = (key: string) => {
  return (
    {
      "1": "GOV_SP",
      "4": "ECONSIG",
    } as any
  )[key];
};

export const validateCronowiseHigienizacao = (
  modalData: CronowiseHigienizacaoProps
) => {
  return objectSchema(
    {
      convenio: (value: number | undefined) => {
        const { isValid } = fieldValidation({ value }).required();
        return {
          valid: isValid,
          message: "Preencha o campo",
          format: parseConvenio(`${value}`),
        };
      },
      idSubConvenio: (value: number | undefined) => {
        const { isValid } = fieldValidation({ value }).required();
        return { valid: isValid, message: "Preencha o campo" };
      },
      idBanco: (value: number | undefined) => {
        let { isValid } = fieldValidation({ value }).required();
        if (
          modalData.convenio === 4 ||
          modalData.convenio === 5 ||
          modalData.convenio === 3
        ) {
          isValid = true;
        }
        return { valid: isValid, message: "Preencha o campo" };
      },
    },
    modalData
  );
};
