import { DropdownOptionProps } from "components/dropdown";
import { Convenio, SituacaoJornada } from "./types";
import { TabulacaoType } from "../kanban-and-table-atendimento/kanban/parts/types";
import { sortArrayBy } from "utils/filter-array-by";

export const dropdownStatus: DropdownOptionProps[] = [
  { name: "Pendente", value: "PENDENTE" },
  { name: "Em atendimento", value: "EM_ATENDIMENTO" },
  { name: "Aguardando documentos", value: "AGUARDANDO_DOCUMENTOS" },
  { name: "Digitação pendente", value: "DIGITACAO_PENDENTE" },
  { name: "Em digitação", value: "EM_DIGITACAO" },
  { name: "Digitado", value: "DIGITADO" },
  { name: "Aguardando Formalização", value: "AGUARDANDO_FORMALIZACAO" },
  { name: "Aguardando Averbação", value: "AGUARDANDO_AVERBACAO" },
  { name: "Pago", value: "PAGO" },
  { name: "Sem Interesse", value: "SEM_INTERESSE" },
  { name: "Sem Saldo", value: "SEM_SALDO" },
  { name: "Reprovado", value: "REPROVADO" },
  { name: "Não é o cliente", value: "NAO_E_O_CLIENTE" },
  { name: "Persistir muito (Top)", value: "PERSISTIR_MUITO_TOP" },
  { name: "Nada a oferecer", value: "NADA_A_OFERECER" },
  { name: "Em negociação", value: "EM_NEGOCIACAO" },
  { name: "Sem interesse (Quente)", value: "SEM_INTERESSE_QUENTE" },
];

export const dropdownTabulacao: { name: string; value: TabulacaoType }[] = [
  { name: "Aguardando Assinatura", value: "AGUARDANDO_ASSINATURA" },
  { name: "Aguardando Averbação", value: "AGUARDANDO_AVERBACAO" },
  { name: "Aguardando Fatura", value: "AGUARDANDO_FATURA" },
  { name: "Aguardando Formalização", value: "AGUARDANDO_FORMALIZACAO" },
  { name: "Aguardando Pagamento", value: "AGUARDANDO_PAGAMENTO" },
  { name: "Aguardando Validação", value: "AGUARDANDO_VALIDACAO" },
  { name: "Aguardando documentos", value: "AGUARDANDO_DOCUMENTOS" },
  { name: "Baixa Renda", value: "BAIXA_RENDA" },
  { name: "Banco não autorizado FGTS", value: "BANCO_NAO_AUTORIZADO_FGTS" },
  {
    name: "Cliente sem autorização app FGTS",
    value: "CLIENTE_SEM_AUTORIZACAO_APP_FGTS",
  },
  { name: "Consumo Inferior", value: "CONSUMO_INFERIOR" },
  { name: "Digitado", value: "DIGITADO" },
  { name: "Digitação pendente", value: "DIGITACAO_PENDENTE" },
  { name: "Em atendimento", value: "EM_ATENDIMENTO" },
  { name: "Em digitação", value: "EM_DIGITACAO" },
  { name: "Em negociação", value: "EM_NEGOCIACAO" },
  { name: "Nada a oferecer", value: "NADA_A_OFERECER" },
  { name: "Não é o cliente", value: "NAO_E_O_CLIENTE" },
  { name: "Pago", value: "PAGO" },
  { name: "Pendencia", value: "PENDENCIA" },
  { name: "Pendente", value: "PENDENTE" },
  { name: "Persistir muito (Top)", value: "PERSISTIR_MUITO_TOP" },
  { name: "Reprovado", value: "REPROVADO" },
  { name: "Retornar", value: "RETORNAR" },
  { name: "Sem Interesse", value: "SEM_INTERESSE" },
  { name: "Sem Resposta", value: "SEM_RESPOSTA" },
  { name: "Sem interesse (Quente)", value: "SEM_INTERESSE_QUENTE" },
  { name: "Sem saldo saque FGTS", value: "SEM_SALDO_SAQUE_FGTS" },
  { name: "Vendido", value: "VENDIDO" },
];

export const dropdownTipoAtendimento: DropdownOptionProps[] = [
  { name: "WhatsApp", value: "WHATSAPP" },
  { name: "Telefone", value: "TELEFONE" },
];

export const dropdownOrigemAtendimento: DropdownOptionProps[] = [
  { name: "WhatsApp", value: "WABOX" },
  { name: "Telefonia", value: "TELEFONIA" },
  { name: "Landing page", value: "LANDING_PAGE" },
  { name: "Manual", value: "MANUAL" },
  { name: "WhatsApp", value: "WHATSAPP" },
  { name: "URA WhatsApp", value: "URA_WHATSAPP" },
  { name: "SMS", value: "SMS" },
];

export const dropdownConvenioAtendimento: DropdownOptionProps[] = [
  { name: "INSS", value: "INSS" },
  { name: "SIAPE", value: "SIAPE" },
  { name: "Antecipação FGTS", value: "ANTECIPACAO_FGTS" },
  { name: "GOV", value: "GOV" },
  { name: "PREF", value: "PREF" },
  { name: "Exército", value: "EXERCITO" },
];

export const dropdownTipoOperacaoINSS: DropdownOptionProps[] = [
  { name: "Novo INSS", value: "NOVO_INSS" },
  { name: "Refin. INSS", value: "REFIN_INSS" },
  { name: "Port. INSS", value: "PORT_INSS" },
  { name: "Cartão RMC INSS", value: "CARTAO_RMC_INSS" },
  { name: "Cartão Benefício INSS", value: "CARTAO_BENEFICIO_INSS" },
  { name: "Margem Aumento Novo INSS", value: "AUMENTO_MARGEM" },
];

export const dropdownTipoOperacaoSIAPE: DropdownOptionProps[] = [
  { name: "Novo SIAPE", value: "NOVO_SIAPE" },
  { name: "Refin. SIAPE", value: "REFIN_SIAPE" },
  { name: "Port. SIAPE", value: "PORT_SIAPE" },
  { name: "Cartão RMC SIAPE", value: "CARTAO_RMC_SIAPE" },
  { name: "Cartão Benefício SIAPE", value: "CARTAO_BENEFICIO_SIAPE" },
];

export const numerosdeBenefico: any = {
  default: "Matrícula",
  INSS: "Número do benefício",
  SIAPE: "Matrícula",
};

export const dropdownEspecies: DropdownOptionProps[] = [
  { name: "001 - PENSAO POR MORTE DE TRABALHADOR RURAL", value: 1 },
  { name: "002 - PENSAO POR MORTE ACIDENTARIA-TRAB. RURAL", value: 2 },
  { name: "003 - PENSAO POR MORTE DE EMPREGADOR RURAL", value: 3 },
  { name: "004 - APOSENTADORIA POR INVALIDEZ-TRAB. RURAL", value: 4 },
  { name: "005 - APOSENT. INVALIDEZ ACIDENTARIA-TRAB.RUR.", value: 5 },
  { name: "006 - APOSENT. INVALIDEZ EMPREGADOR RURAL", value: 6 },
  { name: "007 - APOSENTADORIA POR VELHICE - TRAB. RURAL", value: 7 },
  { name: "008 - APOSENT. POR IDADE - EMPREGADOR RURAL", value: 8 },
  { name: "009", value: 9 },
  {
    name: "010 - AUXILIO-DOENCA POR ACIDENTE DO TRABALHO DO TRABALHADOR RURAL",
    value: 10,
  },
  {
    name: "011 - RENDA MENSAL VITALICIA POR INVALIDEZ DO TRABALHADOR RURAL",
    value: 11,
  },
  {
    name: "012 - RENDA MENSAL VITALICIA POR IDADE DO TRABALHADOR RURAL",
    value: 12,
  },
  { name: "013 - AUXILIO-DOENCA DO TRABALHADOR RURAL", value: 13 },
  { name: "014 -", value: 14 },
  { name: "015 - AUXILIO-RECLUSAO DO TRABALHADOR RURAL", value: 15 },
  { name: "016 -", value: 16 },
  { name: "017 -", value: 17 },
  { name: "018 -", value: 18 },
  { name: "019 - PENSAO DE ESTUDANTE (LEI 7.004/82)", value: 19 },
  { name: "020 - PENSAO POR MORTE DE EX-DIPLOMATA", value: 20 },
  { name: "021 - PENSAO POR MORTE PREVIDENCIARIA", value: 21 },
  { name: "022 - PENSAO POR MORTE ESTATUTARIA", value: 22 },
  { name: "023 - PENSAO POR MORTE DE EX-COMBATENTE", value: 23 },
  { name: "024 - PENSAO ESPECIAL (ATO INSTITUCIONAL)", value: 24 },
  { name: "025 - AUXILIO-RECLUSAO", value: 18 },
  { name: "026 - PENSAO POR MORTE ESPECIAL", value: 26 },
  { name: "027 - PENSAO MORTE SERVIDOR PUBLICO FEDERAL", value: 27 },
  { name: "028 - PENSAO POR MORTE REGIME GERAL", value: 28 },
  { name: "029 - PENSAO POR MORTE EX-COMBATENTE MARITIMO", value: 29 },
  { name: "030 - RENDA MENSAL VITALICIA POR INVALIDEZ", value: 30 },
  { name: "031 - AUXILIO-DOENCA PREVIDENCIARIO", value: 31 },
  { name: "032 - APOSENTADORIA INVALIDEZ PREVIDENCIARIA", value: 32 },
  { name: "033 - APOSENTADORIA INVALIDEZ AERONAUTA", value: 33 },
  { name: "034 - APOSENT. INVAL. EX-COMBATENTE MARITIMO", value: 34 },
  { name: "035 -", value: 35 },
  { name: "036 - AUXILIO ACIDENTE", value: 36 },
  { name: "037 - APOSENTADORIA EXTRANUMERARIO CAPIN", value: 37 },
  { name: "038 - APOSENT. EXTRANUM. FUNCIONARIO PUBLICO", value: 38 },
  { name: "039 -", value: 39 },
  { name: "040 - RENDA MENSAL VITALICIA POR IDADE", value: 40 },
  { name: "041 - APOSENTADORIA POR IDADE", value: 41 },
  { name: "042 - APOSENTADORIA POR TEMPO DE CONTRIBUICAO", value: 42 },
  { name: "043 - APOSENT. POR TEMPO SERVICO EX-COMBATENTE", value: 43 },
  { name: "044 - APOSENTADORIA ESPECIAL DE AERONAUTA", value: 44 },
  { name: "045 - APOSENT. TEMPO SERVICO JORNALISTA", value: 45 },
  { name: "046 - APOSENTADORIA ESPECIAL", value: 46 },
  { name: "047 - ABONO DE PERMANENCIA EM SERVICO 25%", value: 47 },
  { name: "048 - ABONO DE PERMANENCIA EM SERVICO 20%W", value: 48 },
  { name: "049 - APOSENTADORIA ORDINARIA", value: 49 },
  { name: "050 - AUXILIO-DOENCA (EXTINTO PLANO BASICO)", value: 50 },
  { name: "051 - APOSENT. INVALIDEZ EXTINTO PLANO BASICO", value: 51 },
  { name: "052 - APOSENT. IDADE EXTINTO PLANO BASICO", value: 52 },
  { name: "053 -", value: 53 },
  { name: "054 - PENSAO ESPECIAL VITALICIA - LEI 9793/99", value: 54 },
  { name: "055 - PENSAO POR MORTE EXTINTO PLANO BASICO", value: 55 },
  { name: "056 - PENSAO VITALICIA SINDROME TALIDOMIDA", value: 56 },
  { name: "057 - APOSENT. TEMPO DE SERVICO DE PROFESSOR", value: 57 },
  { name: "058 - APOSENTADORIA DE ANISTIADOS", value: 58 },
  { name: "059 - PENSAO POR MORTE DE ANISTIADOS", value: 59 },
  { name: "060 - PENSAO ESPECIAL PORTADOR DE SIDA", value: 60 },
  { name: "061 -", value: 61 },
  { name: "062 -", value: 62 },
  { name: "063 -", value: 63 },
  { name: "064 -", value: 64 },
  { name: "065 -", value: 65 },
  { name: "066 -", value: 66 },
  { name: "067 -", value: 67 },
  { name: "068 - PECULIO ESPECIAL DE APOSENTADORIA", value: 68 },
  { name: "069 -", value: 69 },
  { name: "070 -", value: 70 },
  { name: "071 -", value: 71 },
  { name: "072 - APOSENT. TEMPO SERVICO - LEI DE GUERRA", value: 72 },
  { name: "073 -", value: 73 },
  { name: "074 -", value: 74 },
  { name: "075 -", value: 75 },
  { name: "076 - SALARIO-FAMILIA ESTATUTARIO DA RFFSA", value: 76 },
  { name: "077 -", value: 77 },
  { name: "078 - APOSENTADORIA IDADE - LEI DE GUERRA", value: 78 },
  {
    name: "079 - ABONO DE SERVIDOR APOSENTADO PELA AUTARQUIA EMPR.",
    value: 79,
  },
  { name: "080 - SALARIO-MATERNIDADE", value: 80 },
  { name: "081 - APOSENTADORIA COMPULSORIA EX-SASSE", value: 81 },
  { name: "082 - APOSENTADORIA TEMPO DE SERVICO EX-SASSE", value: 82 },
  { name: "083 - APOSENTADORIA POR INVALIDEZ EX-SASSE", value: 83 },
  { name: "084 - PENSAO POR MORTE EX-SASSE", value: 84 },
  { name: "085 - PENSAO MENSAL VITALICIA DO SERINGUEIRO", value: 85 },
  { name: "086 - PENSAO MENSAL VITALICIA DO DEP. DO SERINGUEIRO", value: 86 },
  {
    name: "087 - BENEFÍCIO DE PRESTAÇÃO CONTINUADA A PESSOA COM DEFICIÊNCIA (LOAS)",
    value: 87,
  },
  {
    name: "088 - BENEFÍCIO DE PRESTAÇÃO CONTINUADA A PESSOA IDOSA (LOAS)",
    value: 88,
  },
  { name: "089 - PENSAO ESP. VÍTIMAS HEMODIALISE-CARUARU", value: 89 },
  { name: "090 -", value: 90 },
  { name: "091 - AUXILIO-DOENCA POR ACIDENTE DO TRABALHO", value: 91 },
  { name: "092 - APOSENT. INVALIDEZ ACIDENTE TRABALHO", value: 92 },
  { name: "093 - PENSAO POR MORTE ACIDENTE DO TRABALHO", value: 93 },
  { name: "094 - AUXILIO-ACIDENTE POR ACIDENTE DO TRABALHO", value: 94 },
  { name: "095 - AUXILIO-SUPLEMENTAR POR ACIDENTE DO TRABALHO", value: 95 },
  {
    name: "096 - PENSÃO ESPECIAL PARA AS PESSOAS ATINGIDAS PELA HANSENÍASE",
    value: 96,
  },
  { name: "097 -", value: 97 },
  { name: "098 -", value: 98 },
  { name: "099 -", value: 99 },
];

export const dropdownSituacaoBeneficio: DropdownOptionProps[] = [
  { name: "Ativo", value: "ATIVO" },
  { name: "Cancelado", value: "CANCELADO" },
  { name: "Cessado", value: "CESSADO" },
  { name: "Suspenso", value: "SUSPENSO" },
];

export const dropdownSexoAtendimento: DropdownOptionProps[] = [
  { name: "Masculino", value: "MASCULINO" },
  { name: "Feminino", value: "FEMININO" },
  { name: "Outros", value: "OUTROS" },
];

export const dropdownTipoPagamento: DropdownOptionProps[] = [
  { name: "Conta-corrente", value: "CONTA_CORRENTE" },
  { name: "Cartão Magnético", value: "CARTAO_MAGNETICO" },
];

export const dropdownTipoContaPagamento: DropdownOptionProps[] = [
  { name: "Conta-corrente", value: "CONTA_CORRENTE" },
  { name: "Poupança", value: "POUPANCA" },
];

export const dropdownSimNao: DropdownOptionProps[] = [
  { name: "Sim", value: true },
  { name: "Não", value: false },
];

export const dropdownBancoDigitacao: DropdownOptionProps[] = [
  { name: "FACTA", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "BMG", value: "BMG" },
  { name: "MASTER", value: "MASTER" },
  // { name: "PAGBANK", value: "PAGBANK" },
];

export const dropdownPrazo: DropdownOptionProps[] = [
  {
    name: "1",
    value: 1,
  },
  {
    name: "12",
    value: 12,
  },
  {
    name: "24",
    value: 24,
  },
  {
    name: "36",
    value: 36,
  },
  {
    name: "48",
    value: 48,
  },
  {
    name: "60",
    value: 60,
  },
  {
    name: "72",
    value: 72,
  },
  {
    name: "84",
    value: 84,
  },
  {
    name: "96",
    value: 96,
  },
  {
    name: "108",
    value: 108,
  },
  {
    name: "120",
    value: 120,
  },
];

export const dropdownEstadoCivil: DropdownOptionProps[] = [
  { name: "Solteiro", value: "SOLTEIRO" },
  { name: "Casado", value: "CASADO" },
  { name: "Viúvo", value: "VIUVO" },
];

export function calcularSaqueCartao(
  margem: number | string | null | undefined,
  multiplicador: null | string | number | undefined,
  porcentagemSaque: null | string | number | undefined
) {
  const limite = Number(calcularLimiteCartao(margem, multiplicador));
  const porcentagemSaqueNumber = Number(porcentagemSaque);
  if (isNaN(porcentagemSaqueNumber) || isNaN(limite)) return "";
  return Number(limite * (porcentagemSaqueNumber / 100)).toFixed(2);
}

export function calcularLimiteCartao(
  margem: number | string | null | undefined,
  multiplicador: null | string | number | undefined
) {
  margem = margem == null || margem === "" ? undefined : margem;
  multiplicador =
    multiplicador == null || multiplicador === "" ? undefined : multiplicador;
  const margemNumber = Number(margem);
  const multiplicadorNumber = Number(multiplicador);

  if (isNaN(margemNumber) || isNaN(multiplicadorNumber)) return "";

  return Number(margemNumber * multiplicadorNumber).toFixed(2);
}

export function calcularMargemCartao(salario: any) {
  if (typeof salario === "string") {
    //@ts-ignore
    salario = salario.trim();
  }
  if (!salario) {
    return "";
  }
  try {
    let value = salario * 0.05;
    if (isNaN(value)) {
      return "";
    }
    return value.toFixed(2);
  } catch (error) {
    return "";
  }
}

export function calcularMargemLivreNovo(
  margemLivre: any,
  coeficienteNovo: any
) {
  if (typeof margemLivre === "string") {
    margemLivre = margemLivre.trim();
  }
  if (!margemLivre) {
    return "";
  }
  if (typeof coeficienteNovo === "string") {
    coeficienteNovo = coeficienteNovo.trim();
  }
  if (!coeficienteNovo) {
    return "";
  }
  try {
    let value = margemLivre / coeficienteNovo;
    if (isNaN(value)) {
      return "";
    }
    return value.toFixed(2);
  } catch (error) {
    return "";
  }
}

export function getDropdownTipoOperacao(convenio: Convenio) {
  if ("INSS" === convenio) return dropdownTipoOperacaoINSS;
  else if ("SIAPE" === convenio) return dropdownTipoOperacaoSIAPE;
  else return [];
}

export function getDescSituacaoJornada(situacao: SituacaoJornada) {
  if ("ERRO_CONSULTA" === situacao) {
    return "Erro consulta";
  } else if ("SMS_NAO_ENVIADO" === situacao) {
    return "SMS não enviado";
  } else if ("TEMPO_ESPERA_EXCEDIDO" === situacao) {
    return "Tempo espera excedido";
  } else if ("TEMPO_ESPERA_EXCEDIDO_EXTERNO" === situacao) {
    return "Tempo de espera excedido externo";
  } else if ("CONSULTA_NAO_AUTORIZADA" === situacao) {
    return "Consulta não autporizada";
  } else if ("CLIENTE_NAO_ENCONTRADO" === situacao) {
    return "Cliente não encontrado";
  } else if ("CPF_SEM_BENEFICIO" === situacao) {
    return "CPF sem benefício";
  } else if ("SEM_LINHAS_DE_CREDITO" === situacao) {
    return "Sem linhas de crédito";
  } else if ("SEM_LINHAS_DE_CREDITO_RESOLVIVEL" === situacao) {
    return "Sem linhas de crédito resolvível";
  } else if ("CPF_COM_RESTRICAO_NO_BANCO_SIMULADO" === situacao) {
    return "CPF com restrição no banco simulado";
  } else if ("CPF_COM_PROPOSTA_EM_ANDAMENTO" === situacao) {
    return "CPF com proposta em andamento";
  } else if ("CPF_COM_BENEFICIO_BLOQUEADO" === situacao) {
    return "CPF com benefício bloqueado";
  } else if ("CPF_COM_CARTAO_MAGNETICO" === situacao) {
    return "CPF com cartão magnético";
  } else if ("SEM_LINHAS_DE_CREDITO_TEMPORARIO" === situacao) {
    return "Sem linhas de crédito temporário";
  } else if ("CPF_NAO_ADERIU_AO_BENEFICIO" === situacao) {
    return "CPF não aderiu ao benefício";
  } else if ("CPF_NAO_AUTORIZOU_O_BANCO" === situacao) {
    return "CPF não autorizou o banco";
  } else if ("CPF_COM_MARGEM_MAS_SEM_PRODUTOS_PRA_OFERTAR" === situacao) {
    return "CPF com margem mas sem produtos pra ofertar";
  } else if ("CPF_COM_MAXIMO_DE_EMPRESTIMOS_ATIVOS" === situacao) {
    return "CPF com máximo de empréstimos ativos";
  } else if ("SEM_LINHAS_DE_CREDITO_NAO_RESOLVIVEL" === situacao) {
    return "Sem linhas de crédito não resolvível";
  } else if ("CPF_COM_BENEFICIO_SEM_MARGEM" === situacao) {
    return "CPF com benefício sem margem";
  } else if ("CPF_COM_BENEFICIO_NAO_ELEGIVEL" === situacao) {
    return "CPF com benefício não elegível";
  } else if ("CARRINHO_FINALIZADO" === situacao) {
    return "Carrinho finalizado";
  } else {
    return "";
  }
}
