import { Box, Center, Flex, IconButton, Text } from "@chakra-ui/react";
import { Tab } from "components/tabs";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useAttendanceContext } from "contexts/attendance-context";

export function SeletorAbasAtendimento({
  tabs,
  onChangeTab,
}: {
  tabs: Tab[];
  onChangeTab: (tabKey: any) => void;
}) {
  const { formControl } = useAttendanceContext();

  return (
    <Box p="15px">
      {tabs.map((tab, i, list) => {
        const isLast = i + 1 === list.length;
        if (tab.isVisible != null && !tab.isVisible()) return null;
        return (
          <Flex
            key={tab.key}
            _hover={{ bg: "gray.50" }}
            transition="background 0.2s"
            px="22px"
            py="10px"
            justifyContent="space-between"
            alignItems="center"
            border="1px solid #E8EAED"
            borderRadius="5px"
            onClick={() => onChangeTab(tab.key)}
            mb={!isLast ? "8px" : ""}
            cursor="pointer"
          >
            <Flex alignItems="center">
              {tab.icon}
              <Text
                fontSize={{ base: "17px", "2xl": "16px" }}
                ml="10px"
                fontWeight="semibold"
              >
                {tab.name}
              </Text>
            </Flex>
            <Center>
              <IconButton
                rounded="full"
                size="xs"
                aria-label=""
                icon={<ChevronRightIcon w="18px" h="18px" />}
              />
            </Center>
          </Flex>
        );
      })}
    </Box>
  );
}
