import { BoxProps, Flex, IconButton, Text } from "@chakra-ui/react";
import { CircleProgress } from "components/vectors/circle-progress";
import { DownloadCircleIcon } from "components/vectors/download-icon";
import { SyntheticEvent, useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { Slider } from "./slider";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";

import { Message } from "components/chat-window";
import { WhatsAppMessagesApi } from "components/atendimentos-components/whatsapp-attendances/components/whats-app-chat/hooks/use-whats-app-session-controls/ws-messages-api";

export function AudioMessagePlayer({
  src,
  isDownloaded,
  isLoading,
  myMessage,
  wsSession,
  message,
  ...rest
}: {
  src: string | undefined;
  isDownloaded: boolean;
  isLoading?: boolean;
  myMessage: boolean;
  wsSession?: WebSocketHook<any, MessageEvent<any> | null>;
  message: Partial<Message>;
} & BoxProps) {
  const { duration, userFullName } = message;
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const sliderRef = useRef<HTMLInputElement>(null);
  let whatsAppWsApi: {
    sendText: (p: { body: string }) => true | undefined;
    sendAudio: (audio: Blob) => Promise<void>;
    requestMedia: (message: Message) => void;
  } | null = null;
  if (wsSession) whatsAppWsApi = WhatsAppMessagesApi({ wsSession });

  const handleAudioState = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const adjustBgSize = (currentValue: string) => {
    sliderRef.current!.value = currentValue;
    const progress =
      ((Number(currentValue) - 0) / (Number(duration) - 0)) * 100;
    sliderRef.current!.style.backgroundSize = `${progress}% 100%`;
  };

  const handleTimeUpdate = (e: SyntheticEvent<HTMLAudioElement>) => {
    const currentValue = e.currentTarget.currentTime.toFixed(0);
    adjustBgSize(currentValue);
  };

  return (
    <Flex
      flexDir="column"
      // px="10px"
      maxW="300px"
      w="100%"
      bg={myMessage ? "#d9fdd3" : "#ffffff"}
      borderRadius="6"
      // pos="relative"
      {...rest}
    >
      {userFullName ? (
        <Text fontSize="14px" fontWeight="bold" w="100%">
          {userFullName}
        </Text>
      ) : null}
      <Flex alignItems="center" h="50px" w="100%">
        <audio
          src={src}
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => {
            sliderRef.current!.value = "0";
            adjustBgSize("0");
            setIsPlaying(false);
          }}
        />
        <CircleProgress
          width="28px"
          height="28px"
          id={message.id}
          isVisible={!!isLoading}
        />
        {isLoading ? null : (
          <IconButton
            mr="8px"
            aria-label=""
            size="sm"
            variant="ghost"
            _hover={{ bg: "#00a88411" }}
            _active={{ bg: "#00a88422" }}
            icon={
              isDownloaded ? (
                isPlaying ? (
                  <FaPause color="#aaa" />
                ) : (
                  <FaPlay color="#aaa" />
                )
              ) : (
                <DownloadCircleIcon color="#aaa" />
              )
            }
            onClick={() => {
              if (isDownloaded) handleAudioState();
              else {
                whatsAppWsApi?.requestMedia(message as Message);
              }
            }}
          />
        )}
        <Flex flexBasis="calc(100% - 40px)" alignItems="center" pos="relative">
          <Slider
            ref={sliderRef}
            width="100%"
            min={0}
            max={Number(duration)}
            onChange={({ target: { value } }) => {
              audioRef.current!.currentTime = Number(value);
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
