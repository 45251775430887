import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getToken } from "Session";
import { makeWebSocketEvent } from "utils/web-socket-events";
import { AttendanceChatProps, ChatControlsContext } from "./types";
import { useInitWebSocket } from "./utils";
import { useAttendanceContext } from "contexts/attendance-context";

const chatsContext = createContext<ChatControlsContext>({
  sendWsMessage: () => {},
  connectStatus: "CONNECTING",
  selectedAttendance: undefined,
  setSelectedAttendance: () => undefined,
  attendancesMap: new Map(),
  setAttendanceMap: () => undefined,
  setAttendance: () => undefined,
});

const url = `wss://whatsapp.novapowerhub.com.br/api/ws`;

// Keys que vão ser automaticamente atualizadas caso o atendimento seja alterado
export const autoUpdateKeys: string[] = [
  "nome",
  "telefoneContatoNormalizado",
  "etapa",
  "convenio",
  "tabulacao",
];

export const ChatsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [attendancesMap, setAttendanceMap] = useState<
    Map<number, AttendanceChatProps>
  >(new Map());
  const [selectedAttendance, setSelectedAttendance] =
    useState<AttendanceChatProps>();
  const connectHeadersTelaChats = { token: getToken(), client: "tela-chats" };
  const isConnect = true;
  const { formControl } = useAttendanceContext();
  const chatControlsApi = useInitWebSocket({
    headers: connectHeadersTelaChats,
    isConnect,
    onMessage: ({ data }) => {
      const parsedData = JSON.parse(data);
      // Temporário
      parsedData.responseType = "atendimento";
      //
      const { responseType } = parsedData;
      if (responseType) {
        makeWebSocketEvent(responseType, parsedData);
      }
    },
    url,
  });

  const connectStatus = {
    "-1": "UNINSTANTIATED",
    "0": "CONNECTING",
    "1": "CONNECTED",
    "2": "DISCONNECTING",
    "3": "DISCONNECTED",
  }[chatControlsApi.readyState] as ChatControlsContext["connectStatus"];

  const sendWsMessage = (data: any) => {
    chatControlsApi.sendJsonMessage(data);
  };

  useEffect(() => {
    if (formControl.values.id) {
      autoUpdateKeys.forEach((key) => {
        const atendimento = formControl.initialValues as any;
        const selectedAtt = selectedAttendance as any;
        if (atendimento?.[key] !== selectedAtt?.[key]) {
          setSelectedAttendance((prev) => {
            return { ...(prev as any), [key]: atendimento?.[key] };
          });
        }
      });
    }
  }, [formControl]);

  const setAttendance = useCallback((data: AttendanceChatProps) => {
    setAttendanceMap((prevMap) => {
      prevMap.set(data.id, data);
      return new Map(prevMap.entries());
    });
  }, []);

  // Serve para manter sincronizado a linha da listagem com o state da linha selecionada
  useEffect(() => {
    if (selectedAttendance) {
      let updatedRow = attendancesMap.get(selectedAttendance.id);
      if (updatedRow) {
        autoUpdateKeys.forEach((key) => {
          (updatedRow as any)[key as keyof AttendanceChatProps] =
            selectedAttendance[key as keyof AttendanceChatProps];
        });
        setAttendance(updatedRow);
      }
    }
  }, [selectedAttendance]);

  return (
    <chatsContext.Provider
      value={{
        sendWsMessage,
        connectStatus,
        selectedAttendance,
        setSelectedAttendance,
        attendancesMap,
        setAttendanceMap,
        setAttendance,
      }}
    >
      {children}
    </chatsContext.Provider>
  );
};

export const useChatsContext = () => {
  const context = useContext(chatsContext);
  if (context === undefined)
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  return context;
};
