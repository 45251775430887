import api from "api/api";
import { WhatsAppSession } from "components/chat-window/chat-input";
import { Toast } from "components/toast";
//@ts-ignore
import { useEffect, useState } from "react";
import {
  createWebSocketEventListener,
  makeWebSocketEvent,
} from "utils/web-socket-events";
import { useInitWebSocket } from "../../../chats-context/utils";
import { Message } from "components/chat-window";
import { AttendanceChatProps } from "../../../chats-context/types";
import { getToken } from "Session";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { useEventListener } from "services/events";

export type ChatStatus = "authenticated" | "not-authenticated";

export interface useWhatsAppSessionControlsProps {
  chatStatus: ChatStatus;
  whatsAppPhones: WhatsAppSession[];
  loadingPhones: boolean;
  changeWhatsAppSession: (
    id: string,
    session: WhatsAppSession
  ) => Promise<void>;
  wsSession: WebSocketHook<any, MessageEvent<any> | null>;
  currentSession: WhatsAppSession | undefined;
  messages: Map<string, Message>;
  contactInfo: Partial<ContactInfo>;
}
export interface ContactInfo {
  isWAContact: boolean;
  name: string;
  profilePicUrl: string;
}

export function useWhatsAppSessionControls({
  wsId,
  selectedAttendance,
  attendanceFullData,
}: {
  wsId?: string;
  selectedAttendance?: AttendanceChatProps;
  attendanceFullData?: Attendance | Partial<Attendance>;
}): useWhatsAppSessionControlsProps {
  const [messages, setMessages] = useState<Map<string, Message>>(new Map());
  const [contactInfo, setContactInfo] = useState<Partial<ContactInfo>>({});
  const [chatStatus, setChatStatus] = useState<ChatStatus>("not-authenticated");
  const [whatsAppPhones, setWhatsAppPhones] = useState<WhatsAppSession[]>([]);
  const [loadingPhones, setLoadingPhones] = useState(false);
  const [currentSession, setCurrentSession] = useState<WhatsAppSession>();
  const [connectHeaders, setConnectHeaders] = useState<any>();
  const url = `wss://whatsapp.novapowerhub.com.br/api/ws`;
  const wsSession = useInitWebSocket({
    headers: connectHeaders,
    isConnect: connectHeaders != null,
    onMessage: ({ data }) => {
      const parsedData = JSON.parse(data);
      if (parsedData.contentType === "requested-media") {
        const listenerId = parsedData.requestedMedia.messageId;
        makeWebSocketEvent("requested-media", parsedData, listenerId);
      } else {
        makeWebSocketEvent(
          parsedData.state ?? parsedData.contentType,
          parsedData
        );
      }
    },
    url,
  });

  // WebSocket Events
  createWebSocketEventListener(
    "whatsapp-qrcode",
    (data) => setChatStatus("not-authenticated"),
    wsId
  );
  createWebSocketEventListener(
    "chat",
    (state) => setChatStatus("authenticated"),
    wsId
  );
  createWebSocketEventListener(
    "qrcode-generated",
    () => setChatStatus("not-authenticated"),
    wsId
  );
  createWebSocketEventListener(
    "qrcode-not-generated",
    (state) => setChatStatus("not-authenticated"),
    wsId
  );
  createWebSocketEventListener("contact-info", (data) => {
    if (selectedAttendance?.id === data.attendanceId) {
      setContactInfo(data.contactInfo);
    }
  });
  createWebSocketEventListener("whatsapp-messages", (data) => {
    const messages = data.whatsappMessages;
    setMessages(messages);
  });

  createWebSocketEventListener("whatsapp-new-message", (data) => {
    const message = {
      ...data.whatsappNewMessage,
      attendanceId: data.attendanceId,
    };
    const appendedMessage = messages.set(message.id, message);
    setMessages(appendedMessage);
  });
  //

  const getPhones = async () => {
    setLoadingPhones(true);
    try {
      const { data } = await api.get("/whatsapp-sessions");
      setWhatsAppPhones(data);
    } catch (e: any) {
    } finally {
      setLoadingPhones(false);
    }
  };

  const changeWhatsAppSession = async (
    sessionId: string,
    session: WhatsAppSession
  ) => {
    if (selectedAttendance?.id) {
      setLoadingPhones(true);
      try {
        const res = await api.get(
          `/atendimentos/update-whatsapp-session/${selectedAttendance?.id}/${sessionId}`
        );
        setCurrentSession(session);
      } catch (e: any) {
        Toast({
          title: "Não foi possível carregar os telefones whatsapp.",
          status: "error",
        });
      } finally {
        setLoadingPhones(false);
      }
    } else {
      throw new Error("É necessário um id de atendimento");
    }
  };

  const handleConnectWebSocket = async (attendanceFullData: Attendance) => {
    if (attendanceFullData) {
      setConnectHeaders({
        token: getToken(),
        phone: attendanceFullData.telefoneContatoNormalizado,
        client: "tela-atendimento",
        whatsAppObjectId: attendanceFullData.whatsAppSession?.id,
        attendanceId: attendanceFullData.id,
      });
      setCurrentSession(attendanceFullData.whatsAppSession);
    }
  };

  useEffect(() => {
    getPhones();
  }, []);

  useEffect(() => {
    setContactInfo({});
    setMessages(new Map());
  }, [selectedAttendance?.id]);

  useEffect(() => {
    setConnectHeaders(undefined);
    if (attendanceFullData?.id) {
      handleConnectWebSocket(attendanceFullData as Attendance);
    }
  }, [attendanceFullData?.id]);

  useEffect(() => {
    if (selectedAttendance?.telefoneContatoNormalizado) {
      setConnectHeaders((prev: any) => ({
        ...prev,
        phone: selectedAttendance?.telefoneContatoNormalizado,
      }));
      setMessages(new Map());
    }
  }, [selectedAttendance?.telefoneContatoNormalizado]);

  return {
    contactInfo,
    chatStatus,
    whatsAppPhones,
    loadingPhones,
    changeWhatsAppSession,
    wsSession,
    currentSession,
    messages,
  };
}
