import api from "api/api";
import useWebSocket from "react-use-websocket";

export const getAttendanceData = async ({
  attendanceId,
  init,
}: {
  init: boolean;
  attendanceId: number;
}) => {
  const destination = init
    ? `/atendimentos/${attendanceId}/kanban/start`
    : `/atendimentos/${attendanceId}/kanban/open`;
  try {
    const { data } = await api.get(destination);
    return data;
  } catch (e) {
    throw new Error("Erro ao obter atendimento");
  } finally {
  }
};

export const useInitWebSocket = ({
  url,
  headers,
  onMessage,
  isConnect,
}: {
  url: string;
  headers: any;
  onMessage: (data: MessageEvent<any>) => void;
  isConnect: boolean;
}) => {
  return useWebSocket<any>(
    url,
    {
      retryOnError: true,
      reconnectInterval: 5000,
      reconnectAttempts: Number.MAX_VALUE,
      shouldReconnect: () => true,
      queryParams: {
        info: window.btoa(JSON.stringify(headers)),
      },
      onMessage,
    },
    isConnect
  );
};
