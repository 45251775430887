export const dropdownBancos = [
  { name: "Banco do Brasil", value: "BB" },
  { name: "Cetelem", value: "CETELEM" },
  { name: "Banrisul", value: "BANRISUL" },
  { name: "BMG", value: "BMG" },
  { name: "Bradesco", value: "BRADESCO" },
  { name: "C6", value: "C6" },
  { name: "Daycoval", value: "DAYCOVAL" },
  { name: "Facta", value: "FACTA" },
  { name: "Itaú", value: "ITAU" },
  { name: "Master", value: "MASTER" },
  { name: "Olé", value: "OLE" },
  { name: "PAN", value: "PAN" },
  { name: "PagBank", value: "PAGBANK" },
  { name: "Safra", value: "SAFRA" },
  { name: "Crefisa", value: "CREFISA" },
  { name: "Lotus", value: "LOTUS_TAYA" },
  { name: "VCTeX", value: "VCTEX" },
  { name: "Tá Quitado", value: "TA_QUITADO" },
  { name: "Santander", value: "SANTANDER" },
  { name: "Bradesco Digio", value: "BRADESCO_DIGIO" },
  { name: "iCred", value: "ICRED" },
  { name: "Presença", value: "PRESENCA" },
];

export const dropdownBancosTabelas = [
  { name: "FACTA", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "BMG", value: "BMG" },
  { name: "MASTER", value: "MASTER" },
];
