import { Toast } from "components/toast";
//@ts-ignore
import MicRecorder from "mic-recorder-to-mp3";
import { useRef, useState } from "react";

export function useAudioControls() {
  const [isUsingAudio, setIsUsingAudio] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const audioRecorder = useRef<any>();
  const audioParts = useRef<Blob[]>([]);

  const startAudio = async () => {
    try {
      audioRecorder.current = new MicRecorder({ bitRate: 64 });
      await audioRecorder.current?.start();
      setIsUsingAudio(true);
    } catch (e) {
      Toast({
        title: "Erro ao iniciar audio",
        status: "error",
      });
      console.error(e);
    }
  };

  const stopAudio = async ({
    finalize = true,
  }: { finalize?: boolean } = {}) => {
    try {
      const audio = await audioRecorder.current?.stop();
      const [mp3Buffer, mp3Blob]: [Buffer[], Blob] = await audio.getMp3();
      let currentAudio: Blob;
      if (audioParts.current.length) {
        currentAudio = new Blob([...audioParts.current, mp3Blob]);
        audioParts.current = [];
      } else {
        currentAudio = new Blob(mp3Buffer);
        audioParts.current = [];
      }
      if (finalize) setIsUsingAudio(false);
      return { audio: currentAudio };
    } catch (e) {
      console.error(e);
      return { audio: null };
    }
  };

  const pauseAudio = async () => {
    try {
      const { audio } = await stopAudio({ finalize: false });
      setIsPaused(true);
      if (!audio) return;
      audioParts.current.push(audio);
    } catch (e) {
      console.error(e);
    }
  };

  const playAudio = async () => {
    try {
      await startAudio();
      setIsPaused(false);
    } catch (e) {}
  };

  const deleteAudio = async () => {
    try {
      const { audio } = await stopAudio({ finalize: true });
      audioParts.current = [];
      setIsPaused(false);
    } catch (e) {}
  };

  return {
    startAudio,
    stopAudio,
    pauseAudio,
    playAudio,
    deleteAudio,
    isUsingAudio,
    isPaused,
  };
}
