import { Link } from "@chakra-ui/react";
import linkify from "linkify-it";

export function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link
        color="blue.500"
        target="_blank"
        rel="noreferrer"
        href={match.url}
        key={i}
      >
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}
