import { Box, Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createWebSocketEventListener } from "utils/web-socket-events";
import { AttendanceChatProps } from "../chats-context/types";
import { useChatsContext } from "../chats-context";
import { sortArrayBy } from "utils/filter-array-by";
import { SkeletonLoading } from "components/skeleton-loading";
import { calcTelaChatsHeight } from "../..";
import { useAttendanceContext } from "contexts/attendance-context";
import ConversationAttendanceCard from "../conversation-attendance-card";
import { AttendanceFilterChats } from "./attendance-filter-chats";
import { Convenio } from "components/atendimentos-components/atendimento-form/types";
import { TabulacaoType } from "components/atendimentos-components/kanban-and-table-atendimento/kanban/parts/types";
import { CleanIcon } from "components/vectors/clean-icon";

export interface AttendanceChatFilterProps {
  convenio?: Convenio;
  tabulacoes?: TabulacaoType;
  fromDataCriacao?: string;
  toDataCriacao?: string;
  userIds?: number[];
  onlyUnreadMessage?: boolean;
  offset: number;
}
const initialFiltersValues = {
  offset: 0,
};

export function AttendancesList() {
  const [filters, setFilters] =
    useState<AttendanceChatFilterProps>(initialFiltersValues);
  const [copyfilters, setCopyFilters] =
    useState<AttendanceChatFilterProps>(initialFiltersValues);
  const { formControl, dispatch } = useAttendanceContext();
  const {
    sendWsMessage,
    connectStatus,
    selectedAttendance,
    setSelectedAttendance,
    attendancesMap,
    setAttendance,
  } = useChatsContext();

  const attendancesList = Array.from(attendancesMap.entries()).map(
    ([k, v]) => v
  );
  const filteredAttendances = sortArrayBy(
    attendancesList,
    "dataAtualizacao",
    "DESC"
  ).filter((curr: AttendanceChatProps) => {
    if (Object.keys(filters).length > 1) {
      let result = true;
      if (Object.keys(filters).includes("convenio")) {
        result = curr.convenio === filters.convenio;
      }
      if (result && Object.keys(filters).includes("tabulacoes")) {
        result = !!filters.tabulacoes?.includes(curr.tabulacao!);
      }
      if (result && Object.keys(filters).includes("userIds")) {
        result = !!filters.userIds?.includes(curr.userId!);
      }
      if (result && Object.keys(filters).includes("onlyUnreadMessage")) {
        result = curr.unreadMessage === filters.onlyUnreadMessage;
      }

      return result;
    }
    return true;
  });

  const isLoading = connectStatus !== "CONNECTED";

  const loadMoreAttendances = (filters: AttendanceChatFilterProps) => {
    sendWsMessage({ filters, action: "filter-chats" });
  };

  createWebSocketEventListener("atendimento", (data: AttendanceChatProps) => {
    setAttendance(data);
  });

  useEffect(() => {
    sendWsMessage({ filters, action: "filter-chats" });
  }, [filters]);

  useEffect(() => {
    if (
      formControl.values.id &&
      selectedAttendance &&
      selectedAttendance?.etapa !== formControl.values.etapa
    ) {
      setSelectedAttendance((prev) => ({
        ...prev!,
        etapa: formControl.values.etapa,
      }));
    }
  }, [formControl.values.id]);

  return (
    <Box
      py="10px"
      bg="#fff"
      overflow="auto"
      maxH={calcTelaChatsHeight()}
      h={calcTelaChatsHeight()}
      onScroll={(e) => {
        const columnElement = e.currentTarget;
        // Altura do elemento
        const elementHeight = columnElement.clientHeight;
        // Distancia percorrida pelo scroll
        const distance = columnElement.scrollTop;
        // Tamanho total do elemento
        const totalElementHeight = columnElement.scrollHeight;
        if (elementHeight + distance >= totalElementHeight) {
          loadMoreAttendances({
            ...filters,
            offset: filteredAttendances.length,
          });
        }
      }}
    >
      <Flex
        bg="#fff"
        pos="sticky"
        top="-10px"
        zIndex={1}
        w="100%"
        justifyContent="flex-end"
        mt="-10px"
        py="10px"
        px="15px"
        borderBottom="1px solid #E8EAED"
      >
        <Button
          px="10px"
          mr="8px"
          borderRadius="6px"
          variant="ghost"
          alignItems="center"
          cursor="pointer"
          _hover={{ bg: "gray.200" }}
          leftIcon={<CleanIcon />}
          fontSize="14px"
          fontWeight="medium"
          onClick={() => {
            setFilters(initialFiltersValues);
            setCopyFilters(initialFiltersValues);
          }}
          isDisabled={!(Object.keys(filters).length > 1)}
        >
          Limpar Filtros
        </Button>

        <AttendanceFilterChats
          filters={filters}
          setFilter={setFilters}
          copyFilters={copyfilters}
          setCopyFilters={setCopyFilters}
        />
      </Flex>
      <Loading isLoading={isLoading} />
      {filteredAttendances.map((data: AttendanceChatProps, i) => {
        return (
          <ConversationAttendanceCard
            key={data.id}
            setAttendance={setAttendance}
            dispatch={dispatch}
            formControl={formControl}
            selectedAttendance={selectedAttendance}
            setSelectedAttendance={setSelectedAttendance}
            {...data}
          />
        );
      })}
    </Box>
  );
}

function Loading({ isLoading }: { isLoading: boolean }) {
  const loadingArray = Array.from({ length: 10 });
  return isLoading ? (
    <>
      {loadingArray.map((_, i) => {
        return (
          <SkeletonLoading isLoading={true} key={i}>
            <Box zIndex={i} w="100%" h="69px"></Box>
          </SkeletonLoading>
        );
      })}
    </>
  ) : null;
}
