import { Box, Center, Flex, IconButton, Text } from "@chakra-ui/react";
import { Toast } from "components/toast";
import { memo, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { AttendanceCardProps, TabulacaoType } from "../types";
import { HeadPhoneIcon } from "components/vectors/headphone-icon";
import { DiagonalArrowIcon } from "components/vectors/diagonal-arrow";
import { WhatsAppIcon } from "components/vectors/whatsapp-icons";
import {
  convenioColors,
  getColumnColor,
  getNameInitials,
  parseConvenio,
  parseOrigem,
  statusColors,
} from "../utils";
import { openModalUserTransferAttendance } from "components/atendimentos-components/modal-change-user";
import { Dropdown } from "components/dropdown";
import { LabelIcon } from "components/vectors/label-icon";
import { maskCPF } from "utils/string-formats";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { MegaphoneIcon } from "components/vectors/menu-icons/mega-phone-icon";
import { Calendar2Icon } from "components/vectors/calendar-icon";
import { openModalConfirm } from "components/modal-confirm-new";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useAttendanceKanbanContext } from "../../kanban-context";
import api from "api/api";
import { MenuThreeDots, MenuThreeDotsOptions } from "../column/menu-three-dots";
import { dropdownTabulacao } from "components/atendimentos-components/atendimento-form/fields-data";
import { SkeletonLoading } from "components/skeleton-loading";
import { kanbanColumnWidth } from "../..";
import { useRouter } from "routes/router-control/use-router";
import { use3cPlusContext } from "contexts/Context3cPlus";

function AttendanceKanbanCard({
  id,
  origem,
  convenio,
  userName,
  dataCriacaoMilliseconds,
  index,
  unreadMessage,
  etapa,
  nome,
  cpf,
  tabulacao,
  isScrollOn,
}: AttendanceCardProps) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const { kanbanColumns, handleDrop, setKanbanColumns } =
    useAttendanceKanbanContext();
  const { controls3cPlus } = use3cPlusContext();
  const router = useRouter();

  const tabulacaoLabel = dropdownTabulacao.find(
    (option) => option.value === tabulacao
  )?.name;

  const parsedOrigem = parseOrigem.get(origem);

  useEffect(() => {
    setIsDisabled(controls3cPlus.inCall);
  }, [controls3cPlus.inCall]);

  const menuOptions: MenuThreeDotsOptions = kanbanColumns
    .filter((currCol) => currCol.colId !== etapa)
    .map((column) => {
      return {
        label: (
          <Flex alignItems="center" key={column.colId}>
            <Box
              w="12px"
              h="12px"
              borderRadius="20px"
              mr="6px"
              bg={getColumnColor(column.colId)}
            />
            <Text fontSize="12px">
              Mover para <b>{column.colName}</b>
            </Text>
          </Flex>
        ),
        onClick: (e) => {
          e.stopPropagation();
          handleDrop({
            source: { droppableId: etapa, index },
            destination: { droppableId: column.colId, index: 0 },
          });
        },
      };
    });

  const changeTabulacao = async ({
    fromTabulacao,
    toTabulacao,
  }: {
    fromTabulacao: TabulacaoType | null;
    toTabulacao: TabulacaoType | null;
  }) => {
    setIsDragDisabled(true);
    try {
      await api.post(`/atendimentos/${id}/change-tabulacao`, {
        fromTabulacao,
        toTabulacao,
      });
      setKanbanColumns((columns) => {
        return columns.map((column) => {
          if (column.colId === etapa) {
            column.attendancesList = column.attendancesList.map(
              (attendance) => {
                if (attendance.id === id) {
                  attendance.tabulacao = toTabulacao;
                }
                return attendance;
              }
            );
          }
          return column;
        });
      });
    } catch (e) {
      Toast({ title: "Erro ao tabular", status: "error" });
    } finally {
      setIsDragDisabled(false);
    }
  };

  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={`${id}`}
      index={index}
      children={(provided) => {
        const { dragHandleProps, draggableProps } = provided;
        return (
          <SkeletonLoading
            isLoading={isDragDisabled}
            ref={provided.innerRef}
            as="a"
            display="block"
            w={isScrollOn ? `calc(${kanbanColumnWidth} - 11px)` : "100%"}
            opacity={isDragDisabled || isDisabled ? 0.5 : 1}
            pointerEvents={isDisabled ? "none" : undefined}
            bg={unreadMessage ? "#ddffd8" : "#fff"}
            borderRadius="5px"
            borderTop={`4px solid ${getColumnColor(etapa)}`}
            borderLeft="1px solid #EAEAEA"
            borderRight="1px solid #EAEAEA"
            borderBottom="1px solid #EAEAEA"
            onClick={(e) => {
              if (etapa === "PENDENTE") {
                openModalConfirm({
                  message: "Deseja iniciar o atendimento?",
                  onConfirm: () => {
                    router.push(`/atendimento-cliente-page?id=${id}&init=true`);
                  },
                  confirmButtonStyle: {
                    variant: undefined,
                    leftIcon: <CheckIcon />,
                  },
                  rejectButtonStyle: {
                    leftIcon: <CloseIcon w="12px" h="12px" />,
                  },
                });
              } else if (!e.metaKey) {
                e.preventDefault();
                router.push(`/atendimento-cliente-page?id=${id}&init=false`);
              }
            }}
            href={
              etapa !== "PENDENTE"
                ? `/atendimento-cliente-page?id=${id}&init=false`
                : undefined
            }
            mb="10px"
            {...dragHandleProps}
            {...draggableProps}
          >
            {/* HEADER */}
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EAEAEA"
              h="40px"
              p="0 20px"
            >
              {/* TRANSFER USER */}
              <Flex
                alignItems="center"
                cursor="pointer"
                mr="-6px"
                _hover={{ bg: "gray.100" }}
                p="4px 6px"
                borderRadius="4px"
                ml="-6px"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openModalUserTransferAttendance({ id });
                }}
              >
                <Center mr="6px">
                  <HeadPhoneIcon />
                </Center>
                <Text mr="5px">{userName}</Text>
                <Box>
                  <DiagonalArrowIcon />
                </Box>
              </Flex>
              {/* END TRANSFER USER */}

              {unreadMessage ? (
                <IconButton
                  aria-label=""
                  minW="29px"
                  h="29px"
                  variant="none"
                  _hover={{ opacity: "0.8" }}
                  bg="linear-gradient(to left, #59F472, #27D144)"
                  icon={
                    <WhatsAppIcon color="#fff" width="15px" height="15px" />
                  }
                />
              ) : null}
            </Flex>
            {/* END HEADER */}
            <Box p="10px 20px" w="100%">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="16px"
              >
                <Flex h="27px">
                  <Dropdown
                    onChange={(toTabulacao) => {
                      if (toTabulacao !== tabulacao) {
                        changeTabulacao({
                          fromTabulacao: tabulacao!,
                          toTabulacao,
                        });
                      }
                    }}
                    overflow="hidden"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    containerOptionProps={{
                      onClick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      },
                    }}
                    value={tabulacao}
                    borderRadius="6px"
                    options={dropdownTabulacao}
                    border="none"
                    fontWeight="medium"
                    fontSize="11px"
                    size="xs"
                    h="27px"
                    leftIcon={<LabelIcon />}
                    p="3px 5px"
                    bg={statusColors.get(tabulacao!) ?? "#CBFFCD"}
                    mr="10px"
                    iconSpacing="3px"
                    menuListProps={{ fontSize: "12px" }}
                  >
                    {tabulacaoLabel?.length! > 12
                      ? tabulacaoLabel?.slice(0, 12) + "..."
                      : tabulacaoLabel}
                  </Dropdown>
                  {convenio ? (
                    <Flex
                      alignItems="center"
                      borderRadius="6px"
                      fontWeight="medium"
                      fontSize="11px"
                      h="27px"
                      p="3px 8px"
                      lineHeight="1"
                      bg={convenioColors.get(convenio!) ?? "#F3EBA4"}
                    >
                      {parseConvenio(convenio!)}
                    </Flex>
                  ) : null}
                </Flex>
                <MenuThreeDots
                  popoverProps={{
                    position: "left",
                    contentProps: { padding: "0", overflow: "auto" },
                    popupStyles: { right: "100%", minWidth: "268px" },
                    customArrowPos: {
                      top: "20%",
                      right: "-16.8px",
                      transform: "translateY(-50%) rotate(90deg)",
                    },
                    customTranslate: "translate(0%, 80%)",
                  }}
                  options={menuOptions}
                  buttonProps={{ mr: "-16px", ml: "5px" }}
                />
              </Flex>
              <Flex alignItems="center" mb="25px">
                <Center
                  w="40px"
                  h="40px"
                  minW="40px"
                  minH="40px"
                  pos="relative"
                  borderRadius="full"
                  bg="#BCBCBC"
                  mr="10px"
                >
                  <Text fontSize="16px">{getNameInitials(nome)}</Text>
                </Center>
                <Box>
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    opacity={nome ? 1 : 0.5}
                  >
                    {nome ?? "(sem nome)"}
                  </Text>
                  <Flex alignItems="center" fontWeight="medium">
                    <Box mr="3px" transform="translateY(-1px)">
                      <PersonIcon width="9px" height="12px" />
                    </Box>
                    <Text fontSize="12px" opacity={cpf ? 1 : 0.5}>
                      {cpf ? maskCPF(cpf) : "(sem cpf)"}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              {/*  */}
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" w="76px">
                  <Box mr="3px" transform="translateY(-1px)">
                    <MegaphoneIcon width="12px" height="12px" />
                  </Box>{" "}
                  <Text fontSize="12px" fontWeight="medium">
                    {parsedOrigem}
                  </Text>
                </Flex>

                {dataCriacaoMilliseconds ? (
                  <Flex alignItems="center">
                    <Box mr="3px" transform="translateY(-1px)">
                      <Calendar2Icon width="12px" height="12px" />
                    </Box>{" "}
                    <Text fontSize="12px">
                      {new Date(dataCriacaoMilliseconds).toLocaleDateString()}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
            </Box>
          </SkeletonLoading>
        );
      }}
    />
  );
}

export default memo(AttendanceKanbanCard);
