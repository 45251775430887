import { Message } from "components/chat-window";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";
import { fileToBase64 } from "utils/file-to-base64";
import { makeId } from "utils/make-id";

export function WhatsAppMessagesApi({
  wsSession: sessionWs,
}: {
  wsSession: WebSocketHook<any, MessageEvent<any> | null>;
}) {
  function sendText({ body }: { body: string }) {
    body = body.trim();
    const isValid = !(body === "");
    if (isValid) {
      sessionWs.sendJsonMessage({
        contentType: "action",
        action: "send-message",
        whatsappNewMessage: {
          type: "chat",
          body: body,
        },
      });
      return true;
    }
  }

  async function sendAudio(audio: Blob) {
    if (audio) {
      const stringfy = await fileToBase64(audio);
      // Message Configuration
      const partSize = 1000;
      const amountParts = Math.ceil(stringfy.length / partSize);
      const hashId = makeId(10);
      //
      // Send messages
      const length = stringfy.length;
      for (let i = 0; i < amountParts; i++) {
        let dataPart = stringfy.substring(
          i * 2000,
          (i + 1) * 2000 < length ? (i + 1) * 2000 : length
        );
        const message = {
          contentType: "action",
          action: "send-voice",
          voiceMedia: {
            hashId,
            part: i,
            amountParts,
            dataPart,
          },
        };
        sessionWs.sendJsonMessage(message);
      }
    }
  }

  function requestMedia(message: Message) {
    sessionWs.sendJsonMessage({
      action: "request-media",
      contentType: "action",
      requestMessageId: message.id,
      url: message.url,
      mediaKey: message.mediaKey,
      mimetype: message.mimetype,
      type: message.type,
    });
  }

  return { sendText, sendAudio, requestMedia };
}
