const suppressedWarnings = [
  "Support for defaultProps will be removed from function components",
  "Support for defaultProps will be removed from memo components in a future major release.",
];

export function powerhubConfig() {
  const originalConsoleError = console.error;

  console.error = (...args) => {
    if (!suppressedWarnings.some((warning) => args[0]?.includes(warning))) {
      originalConsoleError(...args); // Exibe o erro original se não for suprimido
    }
  };
}
