import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { useChatsContext } from "../chats-context";
import { InputWhatsApp } from "./parts/input-send-message";
import { useWhatsAppSessionControls } from "./hooks/use-whats-app-session-controls";
import { MessageList } from "./parts/message-list";
import { ChatHeader } from "./parts/chat-header";
import { calcTelaChatsHeight } from "../..";
import { useAttendanceContext } from "contexts/attendance-context";

export function WhatsAppChat() {
  const { formControl } = useAttendanceContext();
  const { selectedAttendance } = useChatsContext();
  const {
    wsSession,
    changeWhatsAppSession,
    chatStatus,
    loadingPhones,
    whatsAppPhones,
    currentSession,
    messages,
    contactInfo,
  } = useWhatsAppSessionControls({
    selectedAttendance,
    attendanceFullData: formControl.initialValues,
  });

  return (
    <Flex
      bgImg="url(/assets/backgrounds/whats-app-bg.png), linear-gradient(to bottom, rgba(219, 216, 212, 0.3), rgba(219, 216, 212, 0.3))"
      backgroundRepeat="repeat, no-repeat"
      flexDir="column"
      gridArea={selectedAttendance ? "" : "auto / span 2"}
      maxH={calcTelaChatsHeight()}
      h={calcTelaChatsHeight()}
    >
      {selectedAttendance ? (
        <>
          {/* Header */}
          <ChatHeader contactInfo={contactInfo} />
          {/* Chat */}
          <MessageList wsSession={wsSession} messages={messages} />
          {/* Input Message */}
          <Box>
            <InputWhatsApp
              whatsAppPhones={whatsAppPhones}
              loadingPhones={loadingPhones}
              chatStatus={chatStatus}
              changeWhatsAppSession={changeWhatsAppSession}
              wsSession={wsSession}
              currentSession={currentSession}
            />
          </Box>
        </>
      ) : (
        <Center
          bg="#f0f2f5"
          //  backdropFilter="blur(3px)"
          w="100%"
          flexDir="column"
          h="100%"
        >
          <Image src="assets/backgrounds/tela-whats.png" w="340px" />
          <Text color="#41525d" fontSize="28px">
            WhatsApp
          </Text>

          {/* <Text fontSize="20px">Escolha um atendimento.</Text> */}
        </Center>
      )}
    </Flex>
  );
}
