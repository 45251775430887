import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Grid, Input, Text } from "@chakra-ui/react";
import api from "api/api";
import { getErrorByMessage } from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import { CustomModal } from "components/custom-modal";
import { FilterIcon } from "components/vectors/filter-icon";
import { useState, useRef, Fragment } from "react";
import { useEventListener, makeEvent } from "services/events";
import { ConveniosMailing } from "./convenios";
import { MailingFilterMap, MailingFilterValueProps } from "./types";
import {
  handleGenerateMailing,
  mailingFilters,
} from "../mailing-modal-filter/consts";
import { openModalError } from "components/modal-error";
import { Dropdown } from "components/dropdown";
import {
  TipoOperacaoMailing,
  dropdownTipoOperacaoMailing,
} from "../mailing-upload-modal/consts";
import { banks } from "./mailing-filter/consts";
import { InputBoxSearch } from "components/input-box-search";
import { Toast } from "components/toast";

export type AddedFilterProps = {
  key: string;
  op: string;
  value: number[] | string[];
};

export function GenerateMailingModal() {
  const [convenio, setConvenio] = useState("");
  const [tipoOperacao, setTipoOperacao] = useState<TipoOperacaoMailing | "">(
    ""
  );
  let [bankData, setBankData] = useState<{
    bancosFrom: number[];
    bancosTo: number[];
  }>({
    bancosFrom: [],
    bancosTo: [],
  });
  const [filtersValues, setFiltersValues] = useState<
    Map<string, MailingFilterMap>
  >(new Map());
  const [invalidFields, setInvalidFields] = useState<{ [k: string]: string }>(
    {}
  );
  const [isOpen, setIsOpen] = useState(false);
  const [subConvenio, setSubConvenio] = useState("");
  const [loading, setLoading] = useState(false);
  const quantityRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  const parsedBanks = banks.map(({ name, value }) => {
    let formatedValue = `${value}`;
    while (formatedValue.length < 3) formatedValue = "0" + formatedValue;
    return { name: `${formatedValue} - ${name}`, value: Number(value) };
  });

  const bancosFromLabel = new Map([
    ["NOVO", ""],
    ["CARTAO_RMC_NOVO", ""],
    ["CARTAO_RCC_NOVO", ""],
    ["REFIN_CARTEIRA", "Banco Operação"],
    ["SAQUE_RMC", "Banco Operação"],
    ["SAQUE_RCC", "Banco Operação"],
    ["PORT", "Banco portado (contrato original)"],
    ["PORT_COM_REFIN_DA_PORT", "Banco portado (contrato original)"],
  ]).get(tipoOperacao);

  const bancosToLabel = new Map([
    ["NOVO", "Banco nova operação"],
    ["CARTAO_RMC_NOVO", "Banco nova operação"],
    ["CARTAO_RCC_NOVO", "Banco nova operação"],
    ["REFIN_CARTEIRA", ""],
    ["SAQUE_RMC", ""],
    ["SAQUE_RCC", ""],
    ["PORT", "Banco Destino"],
    ["PORT_COM_REFIN_DA_PORT", "Banco Destino"],
  ]).get(tipoOperacao);

  const tipoOperacaoData = {
    tipoOperacao,
    bancosFromLabel,
    bancosToLabel,
    bankData,
  };

  const onChangeFilter = (field: string, newValue: MailingFilterValueProps) => {
    setFiltersValues(newValue);
    setInvalidFields((prev) => {
      delete prev[field];
      return { ...prev };
    });
  };

  const filtersArray = mailingFilters({
    onChangeFilter,
    setInvalidFields,
    filtersValues,
    invalidFields,
    novaApi: convenio === "INSS",
  });

  console.log(
    filtersArray
      .filter(({ isVisible }) => isVisible("INSS"))
      .map((item) => item.key.toUpperCase())
  );

  console.log(filtersValues);
  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setFiltersValues(new Map());
    setSubConvenio("");
    setConvenio("");
    setTipoOperacao("");
  };

  useEventListener("open-mailing-filter", onOpen);

  const newHandleSubmit = async () => {
    const selectedFilters = Array.from(filtersValues.entries()).map(
      ([key, value]) => ({
        ...value,
        key,
      })
    );
    const { bancosFrom, bancosTo } = bankData;
    const quantity = quantityRef.current?.value;
    const name = nameRef.current?.value.trim();

    let bodyNovo: {
      name: string;
      value: any;
      operator: string;
    }[] = [];

    selectedFilters.forEach((filter) => {
      const { format } = filtersArray.find(({ key }) => key === filter.key)!;
      bodyNovo.push({
        name: filter.key,
        value: format(filter.value, filter.op),
        operator: filter.op.toUpperCase(),
      });
    });

    setLoading(true);
    try {
      const { data } = await api.post(`/mailings/pending`, {
        filters: bodyNovo,
        qtdSolicitada: Number(quantity),
        name,
        convenio,
        subConvenio,
        tipoOperacao,
        bancosFrom,
        bancosTo,
      });
      makeEvent("update-mailings-table");

      Toast({ title: "Solicitação enviada com sucesso", status: "success" });
      onClose();
    } catch (e: any) {
      openModalError({
        title: "Erro",
        message: getErrorByMessage(
          e,
          "Não foi possível obter dados do filtro mailing"
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  async function handleSubmit() {
    const filters = Array.from(filtersValues.entries()).map(([key, value]) => ({
      ...value,
      key,
    }));

    const { bancosFrom, bancosTo } = bankData;
    const quantity = quantityRef.current?.value;
    const name = nameRef.current?.value.trim();

    setLoading(true);
    try {
      const { data } = await api.post(`/mailings`, {
        filters,
        qtdSolicitada: Number(quantity),
        name,
        convenio,
        subConvenio,
        tipoOperacao,
        bancosFrom,
        bancosTo,
      });

      makeEvent("update-mailings-table");

      Toast({ title: "Solicitação enviada com sucesso", status: "success" });
      onClose();
    } catch (e: any) {
      openModalError({
        title: "Erro",
        message: getErrorByMessage(
          e,
          "Não foi possível obter dados do filtro mailing"
        ),
      });
    } finally {
      setLoading(false);
    }
  }

  const modalFooter = (
    <>
      <Button
        leftIcon={<FilterIcon />}
        onClick={() => {
          // Validações
          const quantity = quantityRef.current?.value;
          const name = nameRef.current?.value.trim();
          handleGenerateMailing({
            name,
            quantity,
            convenio,
            subConvenio,
            tipoOperacaoData,
            invalidFields,
            filtersArray,
            filtersValues,
            handleSubmit: convenio === "INSS" ? newHandleSubmit : handleSubmit,
            setInvalidFields,
          });
        }}
        isLoading={loading}
        loadingText="Filtrando"
        isDisabled={filtersValues.size === 0}
      >
        Filtrar
      </Button>
      <Button
        onClick={onClose}
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  const onChangeTipoOperacao = (value: any) => {
    setTipoOperacao(value);
    resetBanks();
  };

  const resetBanks = () => {
    const newValue = { bancosFrom: [], bancosTo: [] };
    setBankData(newValue);
    bankData = newValue;
    const input1 = document.getElementById("bancosTo") as HTMLInputElement;
    const input2 = document.getElementById("bancosFrom") as HTMLInputElement;
    if (input1) input1.value = "";
    if (input2) input2.value = "";
  };

  const onChangeBank = (key: "bancosTo" | "bancosFrom", value: any) => {
    const newValue = { ...bankData, [key]: [value] };
    setBankData(newValue);
    bankData = newValue;
  };

  const visibleFilters = filtersArray.filter(({ isVisible }) =>
    isVisible(convenio)
  );

  const isEmptyVisibleFilters = visibleFilters.length === 0;

  const selectedBancoFrom =
    bankData.bancosFrom
      .map(
        (codBanco) =>
          parsedBanks.find((curr) => curr.value === codBanco)?.name || ""
      )
      .filter((b) => b)
      .join(", ") || "";

  const selectedBancoTo =
    bankData.bancosTo
      .map(
        (codBanco) =>
          parsedBanks.find((curr) => curr.value === codBanco)?.name || ""
      )
      .filter((b) => b)
      .join(", ") || "";

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      modalTitle="Filtros Mailing"
      size="full"
      maxH="90vh"
      isLoading={loading}
      scroll="inside"
      id="mailing-modal"
    >
      <Box>
        <Flex alignItems="flex-start" mb="15px">
          <ConveniosMailing
            convenio={convenio}
            setConvenio={(convenio) => {
              setFiltersValues(new Map());
              setConvenio(convenio);
            }}
            selectedOption={subConvenio}
            setSelected={setSubConvenio}
          />
          <Box mr="8px">
            <Text mb="8px">Tipo Operação:</Text>
            <Dropdown
              w="220px"
              options={dropdownTipoOperacaoMailing}
              onChange={onChangeTipoOperacao}
              value={tipoOperacao}
            />
          </Box>

          {bancosFromLabel ? (
            <Box mr="8px">
              <Text mb="8px">{bancosFromLabel}:</Text>
              <InputBoxSearch
                onChange={(optionValue) => {
                  onChangeBank("bancosFrom", optionValue);
                }}
                openOnFocus
                searchKeys={["name", "value"]}
                checkIsInclude={(option) =>
                  bankData.bancosFrom.includes(option["value"])
                }
                optionLabelRender={(bank) => bank.name}
                options={parsedBanks}
                value={selectedBancoFrom}
                inputBoxProps={{
                  w: "250px",
                  id: "bancosFrom",
                  onFocus: (e) => {
                    e.currentTarget.textContent = "";
                  },
                  onBlur: (e) => {
                    e.currentTarget.textContent =
                      bankData.bancosFrom
                        .map(
                          (codBanco) =>
                            parsedBanks.find((curr) => curr.value === codBanco)
                              ?.name || ""
                        )
                        .filter((b) => b)
                        .join(", ") || "";
                  },
                }}
                // errorMessage={errorMessage}
              />
            </Box>
          ) : null}

          {bancosToLabel ? (
            <Box mr="8px">
              <Text mb="8px">{bancosToLabel}:</Text>
              <InputBoxSearch
                onChange={(optionValue) => {
                  onChangeBank("bancosTo", optionValue);
                }}
                openOnFocus
                searchKeys={["name", "value"]}
                checkIsInclude={(option) =>
                  bankData.bancosTo.includes(option["value"])
                }
                optionLabelRender={(bank) => bank.name}
                options={parsedBanks}
                value={selectedBancoTo}
                inputBoxProps={{
                  w: "250px",
                  id: "bancosTo",
                  onFocus: (e) => {
                    e.currentTarget.textContent = "";
                  },
                  onBlur: (e) => {
                    e.currentTarget.textContent =
                      bankData.bancosTo
                        .map(
                          (codBanco) =>
                            parsedBanks.find((curr) => curr.value === codBanco)
                              ?.name || ""
                        )
                        .filter((b) => b)
                        .join(", ") || "";
                  },
                }}

                // errorMessage={errorMessage}
              />
            </Box>
          ) : null}

          <Box mr="8px">
            <Text mb="8px">Quantidade:</Text>
            <Input
              w="95px"
              ref={quantityRef}
              onChange={({ target, currentTarget }) => {
                const value = target.value.replace(/\D/g, "");
                currentTarget.value = value;
                if (Number(value) > 10000) currentTarget.value = "10000";
              }}
            />
          </Box>
          <Box mr="8px">
            <Text mb="8px">Nome:</Text>
            <Input w={{ base: "250px", "2xl": "350px" }} ref={nameRef} />
          </Box>
        </Flex>

        <Text my="15px">Filtros:</Text>

        <Grid templateColumns="1fr 1fr" gap="16px">
          {visibleFilters.map(({ key, render }) => {
            return <Fragment key={key}>{render}</Fragment>;
          })}
          {isEmptyVisibleFilters ? <Text>Escolha um Convênio!</Text> : null}
        </Grid>
      </Box>
    </CustomModal>
  );
}
