import { AddIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Text,
} from "@chakra-ui/react";
import api from "api/api";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { openModalConfirm } from "components/modal-confirm-new";
import { SkeletonLoading } from "components/skeleton-loading";
import { BankIcon } from "components/vectors/bank-icon";
import { CreditCardIcon } from "components/vectors/credit-card-icon";
import { LogoTransparent } from "components/vectors/logo";
import { PIXIcon } from "components/vectors/pix-icon";
import {
  CellPhoneIcon,
  ConversationIcon,
  PaperIcon,
  RedWhatsAppIcon,
} from "components/vectors/wallet-icons";
import { openModalInsertCoin } from "components/carteira-components/modal-insert-coin";
import { WalletTable } from "components/carteira-components/wallet-table";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useState } from "react";
import { openModalAdicionarSaldoPIX } from "components/carteira-components/modal-adicionar-saldo-pix";
import { AddPerson } from "components/vectors/add-person";
import { Toast } from "components/toast";

export interface SelectedCustomer {
  id: number;
  name: string;
  codigoNova: string;
  cashback: "ENABLED" | "DISABLED" | "PENDING" | null;
}

const parseCashbackInfo = (value: SelectedCustomer["cashback"]) => {
  return new Map<SelectedCustomer["cashback"], string>([
    ["ENABLED", "Ativo"],
    ["DISABLED", "Inativo"],
    ["PENDING", "Aguardando Aprovação"],
  ]).get(value);
};

export function WalletPage() {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>({
    id: -1,
    name: "",
    codigoNova: "",
    cashback: "PENDING",
  });
  const [isVisibleSaldo, setIsVisibleSaldo] = useState(true);
  const { codigoNova, id, name, cashback } = selectedCustomer;
  const { user } = useApplicationContext();
  const [saldoGeral, setSaldoGeral] = useState("0.00");
  const [saldoFgts, setSaldoFgts] = useState("0.00");
  const cashbackButtonLabel =
    cashback === "ENABLED"
      ? "Desativar Cashback"
      : cashback === "DISABLED"
        ? "Ativar Cashback"
        : null;
  const isVisibleCashbackButton =
    cashbackButtonLabel === "Ativar Cashback"
      ? ["SUPER", "FINANCEIRO", "COMERCIAL", "PARCEIRO_MASTER"].includes(
        user.userData.type!
      )
      : cashbackButtonLabel === "Desativar Cashback"
        ? ["SUPER", "FINANCEIRO"].includes(user.userData.type!)
        : null;

  const columns: Column[] = [
    {
      name: "Produto",
      render: (row) => {
        return row.product;
      },
      cellStyle: () => ({ fontSize: 14 }),
    },
    {
      name: "Preço (Pré-pago)",
      render: (row) => {
        return row.price;
      },
      cellStyle: () => ({ fontSize: 14 }),
    },
  ];

  return (
    <GetLayout>
      <Flex alignItems="center" mb="30px">
        <Text color="#343B44" fontSize="24px" fontWeight="semibold">
          Carteira
        </Text>
      </Flex>
      <Grid templateColumns="calc(100% - 15px - 401px) 401px" gap="15px">
        <Box bg="#fff" p="20px 30px" borderRadius="5px">
          <WalletTable
            selectedCustomer={selectedCustomer}
            setSaldoGeral={setSaldoGeral}
            setSaldoFgts={setSaldoFgts}
            setSelectedCustomer={setSelectedCustomer}
            isNotLoaded={id === -1}
          />
        </Box>
        <Box>
          <Box
            w="100%"
            bg="linear-gradient(to right, #141B3B, #B21F17)"
            p="25px 30px"
            color="white"
            borderRadius="5px"
            pos="relative"
            overflow="hidden"
          >
            <Box top="10px" right="15px" pos="absolute">
              <LogoTransparent />
            </Box>
            <Flex>
              <Grid templateColumns="1fr" gap="10px" w="100%">
                <Grid templateColumns="1fr 1fr" alignItems="center">
                  <SkeletonLoading borderRadius="5" isLoading={id === -1} w="80%">
                    <Box>
                      <Text mb="5px" fontSize="16px" fontWeight="medium">
                        Código:
                      </Text>
                    </Box>
                  </SkeletonLoading>
                  <SkeletonLoading borderRadius="5" isLoading={id === -1} w="80%">
                    <Box>
                      <Text fontSize="20px" fontWeight="semibold" mb="10px" maxW="400px">
                        {codigoNova}
                      </Text>
                    </Box>
                  </SkeletonLoading>
                </Grid>
                <Grid templateColumns="1fr">
                  <SkeletonLoading borderRadius="5" isLoading={id === -1} w="80%">
                    <Box>
                      <Text mb="5px" fontSize="16px" fontWeight="medium">
                        Nome:
                      </Text>
                    </Box>
                  </SkeletonLoading>
                  <SkeletonLoading borderRadius="5" isLoading={id === -1} w="80%">
                    <Box>
                      <Text fontSize="20px" fontWeight="semibold" mb="10px" maxW="400px">
                        {name}
                      </Text>
                    </Box>
                  </SkeletonLoading>
                </Grid>
                {/* <SkeletonLoading borderRadius="5" isLoading={id === -1} w="80%">
                  <Text
                    fontSize="20px"
                    fontWeight="semibold"
                    maxW="300px"
                    mb="10px"
                  >
                    {`${codigoNova ? `${codigoNova}  - ` : ""}${name}`}
                    &nbsp;
                  </Text>
                </SkeletonLoading> */}
                {/* <IconButton
                  aria-label=""
                  variant="ghost"
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }}
                  size="xs"
                  mr="8px"
                  icon={isVisibleSaldo ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={() => setIsVisibleSaldo((state) => !state)}
                /> */}
              </Grid>
            </Flex>
            <Grid templateColumns="1fr" gap="8px" mt="10px">
              <Grid templateColumns="1fr 1fr" alignItems="center">
                <Box mr="10px">
                  <Text mb="5px" fontSize="16px" fontWeight="medium">
                    Saldo GERAL:
                  </Text>
                </Box>
                <Box mr="10px">
                  <SkeletonLoading isLoading={id === -1}>
                    <Text mb="5px" fontSize="20px" fontWeight="semibold">
                      R$ {isVisibleSaldo ? saldoGeral.replace(".", ",") : "***"}
                    </Text>
                  </SkeletonLoading>
                </Box>
              </Grid>
              <Grid templateColumns="1fr 1fr" alignItems="center">
                <Box mr="10px">
                  <Text mb="5px" fontSize="16px" fontWeight="medium">
                    Saldo FGTS:
                  </Text>
                </Box>
                <Box mr="10px">
                  <SkeletonLoading isLoading={id === -1}>
                    <Text mb="5px" fontSize="20px" fontWeight="semibold">
                      R$ {isVisibleSaldo ? saldoFgts.replace(".", ",") : "***"}
                    </Text>
                  </SkeletonLoading>
                </Box>
              </Grid>
              <Grid templateColumns="1fr 1fr" alignItems="center">
                <Box>
                  <Text mb="5px" fontSize="16px" fontWeight="medium">
                    Cashback:
                  </Text>
                </Box>
                <Box>
                  <SkeletonLoading isLoading={id === -1}>
                    <Text mb="5px" fontSize="20px" fontWeight="semibold">
                      {parseCashbackInfo(cashback)}&nbsp;
                    </Text>
                  </SkeletonLoading>
                </Box>
              </Grid>

            </Grid>
            <Grid templateColumns="1fr 1fr" gap="16px" mt="16px">
              {user.userData.customerId === selectedCustomer.id ? (
                <Button
                  color="#141B3B"
                  bg="#fff"
                  _hover={{ bg: "rgba(255,255,255,0.9)" }}
                  _active={{ bg: "rgba(255,255,255,0.8)" }}
                  onClick={() => openModalAdicionarSaldoPIX()}
                  leftIcon={<PIXIcon width="18px" height="18px" />}
                  gridArea={
                    isVisibleCashbackButton ? undefined : "auto / span 2"
                  }
                  size="sm"
                >
                  Depósito PIX
                </Button>
              ) : null}
              {isVisibleCashbackButton ? (
                <Button
                  color="#141B3B"
                  bg="#fff"
                  _hover={{ bg: "rgba(255,255,255,0.9)" }}
                  _active={{ bg: "rgba(255,255,255,0.8)" }}
                  size="sm"
                  onClick={() => {
                    if (cashbackButtonLabel === "Ativar Cashback") {
                      openModalConfirm({
                        message: "Deseja solicitar a ativação do cashback?",
                        onConfirm: async () => {
                          try {
                            const { data } = await api.get(
                              `/customers/${selectedCustomer.id}/change-cashback?status=PENDING`
                            );
                            Toast({ title: "Cashback Solicitado" });
                            setSelectedCustomer({
                              ...selectedCustomer,
                              cashback: "PENDING",
                            });
                          } catch (e) {
                            Toast({ title: "Erro ao solicitar cashback" });
                          }
                        },
                        confirmButtonStyle: { variant: undefined },
                      });
                    } else if (cashbackButtonLabel === "Desativar Cashback") {
                      openModalConfirm({
                        message: "Deseja desativar o cashback?",
                        onConfirm: async () => {
                          try {
                            const { data } = await api.get(
                              `/customers/${user.userData.customerId}/change-cashback?status=DISABLED`
                            );
                            Toast({ title: "Cashback Desativado" });
                            setSelectedCustomer({
                              ...selectedCustomer,
                              cashback: "DISABLED",
                            });
                          } catch (e) {
                            Toast({ title: "Erro ao desativar cashback" });
                          }
                        },
                      });
                    }
                  }}
                  disabled={id === -1}
                  gridArea={
                    user.userData.customerId !== selectedCustomer.id
                      ? "auto / span 2"
                      : undefined
                  }
                >
                  {cashbackButtonLabel}
                </Button>
              ) : null}
              {["SUPER", "FINANCEIRO"].includes(user.userData.type!) ? (
                <Button
                  gridArea="auto / span 2"
                  size="sm"
                  leftIcon={<AddIcon w="13px" h="13px" />}
                  color="#141B3B"
                  bg="#fff"
                  _hover={{ bg: "rgba(255,255,255,0.9)" }}
                  _active={{ bg: "rgba(255,255,255,0.8)" }}
                  onClick={openModalInsertCoin}
                  disabled={id === -1}
                >
                  Adicionar Saldo
                </Button>
              ) : null}
            </Grid>
          </Box>
          <Box mt="20px" bg="#fff" borderRadius="5px" p="20px 30px" overflow="auto" height="485px">
            <Text mb="15px" fontSize="22px" fontWeight="semibold">
              Valores
            </Text>
            {servicosList.map((item, i) => {
              const isLast = i + 1 === servicosList.length;
              return (
                <Flex key={`produto-${i}`} w="100%" mb={isLast ? "" : "30px"}>
                  <Center
                    w="56px"
                    h="56px"
                    bg="#FFF3F3"
                    borderRadius="5px"
                    mr="18px"
                  >
                    {item.icon}
                  </Center>
                  <Flex justifyContent="center" flexDir="column">
                    <Text fontSize="15px">{item.product}</Text>
                    <Text fontSize="17px" fontWeight="semibold">
                      {item.price}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </GetLayout>
  );
}

export const valoresServicos = [
  {
    product: "Consulta FGTS",
    price: "R$ 0,05 por consulta",
    icon: <PaperIcon />,
    id: "API_CONSULTA_FGTS",
  },
  {
    product: "Higienização Refin",
    price: "R$ 19,90 mensal (ou R$ 0,05 por consulta)",
    icon: <BankIcon color="#B21F17" />,
    id: "DATAFAST",
  },
  {
    product: "Consulta Saque Complementar",
    price: "R$ 19,90 mensal (ou R$ 0,05 por consulta)",
    icon: <CreditCardIcon color="#B21F17" />,
    id: "API_BANCO_CARTAO",
  },
];

export const valoresServicosMensais = [
  {
    product: "Sessão WhatsApp",
    price: "R$ 15,00 mensal",
    icon: <RedWhatsAppIcon />,
    id: "EXTRATO_ONLINE",
  },
  {
    product: "Higienização Refin (fila por banco)",
    price: "R$ 19,90 mensal (ou R$ 0,05 por consulta)",
    icon: <BankIcon color="#B21F17" />,
    id: "DATAFAST",
  },
  {
    product: "Consulta Saque Complementar",
    price: "R$ 19,90 mensal (ou R$ 0,05 por consulta)",
    icon: <CreditCardIcon color="#B21F17" />,
    id: "API_BANCO_CARTAO",
  },
  {
    product: "Conta Usuário",
    price: "R$ 25,00 mensal (por conta)",
    icon: <AddPerson color="#B21F17" withOutFill />,
    id: "API_BANCO_CARTAO",
  },
];

export const tabelaCobrancas = [
  {
    product: "Geração Mailing",
    price: "R$ 0,0025 por lead",
    icon: <PaperIcon />,
  },
  {
    product: "Consulta Offline",
    price: "R$ 0,015 por consulta",
    icon: <PaperIcon />,
  },
  { product: "Extrato", price: "R$ 4,00 por extrato", icon: <PaperIcon /> },
  { product: "IN100", price: "R$ 0,50 por consulta", icon: <PaperIcon /> },
  {
    product: "Higienização Telefones",
    price: "R$ 0,08 por consulta",
    icon: <CellPhoneIcon />,
  },
  {
    product: "Envio SMS",
    price: "R$ 0,15 por envio",
    icon: <ConversationIcon />,
  },
  {
    product: "Simulação FGTS",
    price: "R$ 0,10 por consulta",
    icon: <PaperIcon />,
    id: "SIMULACAO_FGTS",
  },
];

export const servicosList = [...tabelaCobrancas, ...valoresServicosMensais];
