import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { defaultScroll, defaultTransition } from "chakra/theme";
import Navbar, { NavbarProps, navbarWidth } from "components/navbar";
import SideMenu from "components/side-menu";
import { ReactNode } from "react";

export const pageContentPadding = "30px";

export function GetLayout({
  children,
  HeadRender,
  pageContentProps,
  withOutLayout,
}: {
  children?: ReactNode;
  HeadRender?: NavbarProps["HeadRender"];
  pageContentProps?: BoxProps;
  withOutLayout?: boolean;
}) {
  if (withOutLayout) return <>{children}</>;
  return (
    <>
      <Navbar HeadRender={HeadRender} />
      <Flex w="100%" h={`calc(100vh - ${navbarWidth})`} bg="bg-gray">
        <SideMenu />
        <Box
          id="page-content"
          outline="none"
          maxW={{ base: "calc(100% - 100px)" }}
          w="100%"
          p={{ base: "30px", xl: "30px 30px 30px 30px" }}
          maxH={`calc(100vh - ${navbarWidth})`}
          overflow="auto"
          transition={defaultTransition}
          display={"block"}
          bg={`#F5F7F9`}
          sx={defaultScroll}
          {...pageContentProps}
        >
          {children}
        </Box>
      </Flex>
    </>
  );
}
