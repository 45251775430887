import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { Dispatch, SetStateAction, useState } from "react";
import { PartnersSearch } from "../modal-partners-search";
import apiCarteira from "api/api-carteira";
import { SaqueIcon } from "components/vectors/saque-icon";
import { DepositoIcon } from "components/vectors/deposito-icon";
import { DocumentIcon } from "components/vectors/document-icon";
import { Tabs } from "components/tabs";
import { SelectedCustomer } from "pages/carteira";
import { ModalInsertCoin } from "../modal-insert-coin";
import { useApplicationContext } from "contexts/ApplicationContext";
import { ModalAdicionarSaldoPIX } from "../modal-adicionar-saldo-pix";
import { RepeatIcon } from "@chakra-ui/icons";
import { Toast } from "components/toast";

interface Transation {
  descricao: string;
  valor: number;
  data: string;
  tipoValor: "CREDITO" | "DEBITO";
}
export type WalletServiceProps = {
  id: number;
  servico: string;
  isExpirado: boolean;
  possuiRecorrencia: boolean;
  expirationDate: string;
  isRenovationDay: boolean;
  isValidLicence: boolean;
  banco: string;
};

interface ChildUser {
  customer: {
    cnpj: string | null;
    codigoNova: null;
    comercialUser: null;
    creditoApiBmgCartaoSaque: null;
    creditoApiInssHistOff: null;
    email: string | null;
    enabled: boolean;
    enabled3cplus: null;
    id: number;
    name: string;
    regionalCustomer: null;
    telefone: null;
    token3cplus: null;
  };
  enabled: boolean;
  enabledWabox: boolean;
  foneWabox: string;
  id: number;
  name: string;
  password: null;
  type: string;
  userId3cplus: number;
  username: string;
}

export function WalletTable({
  setSaldoGeral,
  setSaldoFgts,
  setSelectedCustomer,
  selectedCustomer,
  isNotLoaded,
}: {
  setSelectedCustomer: Dispatch<SetStateAction<SelectedCustomer>>;
  selectedCustomer: SelectedCustomer;
  setSaldoGeral: Dispatch<SetStateAction<string>>;
  setSaldoFgts: Dispatch<SetStateAction<string>>;
  isNotLoaded: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [transations, setTransations] = useState<Transation[]>([]);
  const { user } = useApplicationContext();

  const onChangePartner = async ({
    id,
    name,
    codigoNova,
    cashback,
  }: SelectedCustomer) => {
    setIsLoading(true);
    try {
      const { data } = await apiCarteira.get(`/carteiras/extrato/${id}`);
      data.saldos!.map((item: any) => {
        if (item.tipoCarteira === "GERAL") {
          console.log("saldo do GERAL: " + item.saldo);
          setSaldoGeral(item.saldo.toFixed(2));
        } else if (item.tipoCarteira === "FGTS") {
          console.log("saldo do FGTS: " + item.saldo);
          setSaldoFgts(item.saldo.toFixed(2));
        }
      })
      setSelectedCustomer({ id, name, codigoNova, cashback });
      setTransations(data.transacoes);
      // setSaldoGeral(data.saldo.toFixed(2));
    } catch (e) {
      Toast({
        title: "Não foi possível obter dados",
        status: "error",
      });
      setSaldoGeral(Number(0).toFixed(2));
      setTransations([]);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column[] = [
    {
      name: "Descrição",
      render: (row: Transation) => {
        const isCredit = row.tipoValor === "CREDITO";
        return (
          <Flex alignItems="center">
            <Center mr="8px">
              {" "}
              {isCredit ? <DepositoIcon /> : <SaqueIcon />}
            </Center>{" "}
            {row.descricao}
          </Flex>
        );
      },
      cellStyle: () => ({ h: "50px" }),
    },
    {
      name: <Flex ml="auto">Valor (R$)</Flex>,
      render: (row: Transation, index) => {
        const isDebit = row.valor.toFixed(2).indexOf("-") !== -1;

        return row.valor ? (
          <Flex justifyContent="flex-end">
            {isDebit ? "-" : "+"} R$ {row.valor.toFixed(2).replace("-", "")}
          </Flex>
        ) : null;
      },
      key: "valor",
      cellStyle: () => ({ textAlign: "end" }),
    },
    {
      name: "Data",
      render: (row: Transation) => row.data,
    },
  ];

  return (
    <Flex flexDir="column">
      <Flex w="100%">
        <Box w="calc(100% - 120px)" maxW="600px" mr="10px">
          <PartnersSearch
            selectedCustomer={selectedCustomer}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            onChangePartner={onChangePartner}
          />
        </Box>
        <Box ml="auto">
          <Text mb="8px">&nbsp;</Text>
          <Button
            h="36px"
            size="sm"
            leftIcon={<RepeatIcon />}
            onClick={() => onChangePartner(selectedCustomer)}
          >
            Atualizar Transações
          </Button>
        </Box>
      </Flex>
      <Tabs
        activeTab={"transacoes"}
        onChangeTab={() => {}}
        tabs={[
          {
            icon: <DocumentIcon width="20px" h="20px" />,
            name: "Transações",
            key: "transacoes",
          },
          // { icon: <SettingsIcon />, name: "Serviços", key: "servicos" },
        ]}
        isDisabled={isNotLoaded}
      />

      <DynamicTable
        columns={columns}
        rows={transations}
        isLoading={isLoading}
        headerStyle={(header) => ({
          textAlign: header.key === "valor" ? "end" : "center",
          bg: "#fff",
          color: "#656F7D",
          textTransform: "uppercase",
          fontSize: "12px",
          fontWeight: "medium",
          _hover: { bg: "gray.50" },
        })}
        containerStyle={{ boxShadow: "none" }}
      />

      <ModalInsertCoin
        selectedCustomer={selectedCustomer}
        onChangePartner={onChangePartner}
      />
      <ModalAdicionarSaldoPIX
        updateTransacoes={() => onChangePartner(selectedCustomer)}
      />
    </Flex>
  );
}
