import { Box, Button, Grid, Text } from "@chakra-ui/react";
import { Dropdown } from "components/dropdown";
import { openModalConfirm } from "components/modal-confirm-new";
import { DocumentIcon } from "components/vectors/document-icon";
import { useAttendanceContext } from "contexts/attendance-context";
import {
  fazerConsultaIn100,
  fazerConsultaOffline,
  gerarExtrato,
} from "../variables";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";
import { openModalConsultaCPF } from "../modal-consulta-cpf";
import { fieldValidation } from "utils/field-validation";
import { Toast } from "components/toast";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CheckIcon } from "@chakra-ui/icons";
import { Dispatch, SetStateAction, useState } from "react";
import { openLastStatementModal } from "../last-statement-modal";

export function Consultas({
  onClose,
  loadingStatus,
  isLoading,
  setIsLoadingStatus,
}: {
  onClose?: () => void;
  loadingStatus: string;
  setIsLoadingStatus: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}) {
  const [loadingConsultaCPF, setLoadingConsultaCPF] = useState(false);
  const { user } = useApplicationContext();
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values;
  const isLoadingConsultaOffline = loadingStatus === "consulta-offline";
  const isLoadingConsultaIn100 = loadingStatus === "consulta-in100";
  const isLoadingGerandoExtrato = loadingStatus === "gerando-extrato";

  return (
    <Grid templateColumns="1fr 1fr" gap="5px">
      {["GOV", "PREF", "INSS", "SIAPE"].includes(formValues.convenio!) ? (
        <Button
          aria-label=""
          px="5px"
          fontSize="11px"
          variant="outline"
          leftIcon={<DocumentIcon width="15px" height="15px" />}
          loadingText="Consultando"
          isLoading={isLoadingConsultaOffline}
          isDisabled={isLoadingConsultaIn100 || isLoadingGerandoExtrato}
          onClick={() => {
            openModalConfirm({
              message: ({ onClose }) => (
                <Box w="100%">
                  <Text>
                    Deseja usar o <b>Número do benefício</b> ou <b>CPF</b>?
                  </Text>
                  <Dropdown
                    w="100%"
                    // mt="10px"
                    onChange={(value) => {
                      if (value === "cpf") {
                        fazerConsultaOffline({
                          dispatch,
                          formControl,
                          setIsLoadingStatus,
                          mode: "cpf",
                        });
                        onClose();
                      } else if (value === "nb") {
                        fazerConsultaOffline({
                          dispatch,
                          formControl,
                          setIsLoadingStatus,
                          mode: "nb",
                        });
                        onClose();
                      }
                    }}
                    options={[
                      { name: "Número benefício", value: "nb" },
                      { name: "CPF", value: "cpf" },
                    ]}
                  />
                </Box>
              ),
              confirmButtonStyle: {
                children: "CPF",
                variant: undefined,
                display: "none",
              },
              rejectButtonStyle: {
                children: "Número benefício",
                variant: undefined,
                display: "none",
              },
            });
            onClose?.();
          }}
          size="xs"
        >
          Consulta Offline
        </Button>
      ) : null}
      {(!isColomboUser(user) || user.userData?.verIn100) &&
      formValues.convenio === "INSS" ? (
        <Button
          aria-label=""
          px="5px"
          fontSize="11px"
          // ml="10px"
          variant="outline"
          leftIcon={<DocumentIcon width="15px" height="15px" />}
          isLoading={isLoadingConsultaIn100}
          isDisabled={isLoadingConsultaOffline || isLoadingGerandoExtrato}
          loadingText="Consultando"
          onClick={() => {
            fazerConsultaIn100({
              dispatch,
              formControl,
              setIsLoadingStatus,
            });
            onClose?.();
          }}
          size="xs"
        >
          Consulta IN100
        </Button>
      ) : null}

      <Button
        leftIcon={<DocumentIcon width="15px" height="15px" />}
        variant="outline"
        px="5px"
        fontSize="11px"
        onClick={() => {
          if (fieldValidation({ value: formValues.cpf }).cpf().isValid) {
            openModalConsultaCPF({ setLoadingConsultaCPF });
          } else {
            Toast({ title: "Insira um CPF" });
            dispatch({
              type: "setInvalidField",
              payload: { fieldName: "cpf", action: "append" },
            });
          }
          onClose?.();
        }}
        disabled={isLoading}
        isLoading={loadingConsultaCPF}
        loadingText="Consultando"
        size="xs"
      >
        Consulta CPF
      </Button>

      {!isColomboUser(user) && formValues.convenio === "INSS" ? (
        <Button
          leftIcon={<DocumentIcon width="15px" height="15px" />}
          px="5px"
          fontSize="11px"
          onClick={() => {
            gerarExtrato({
              formValues: formValues as Attendance,
              setIsLoadingStatus,
              dispatch,
            });
            onClose?.();
          }}
          isLoading={isLoadingGerandoExtrato}
          loadingText="Gerando"
          disabled={isLoading}
          // ml="10px"
          size="xs"
        >
          Novo extrato
        </Button>
      ) : null}
      {!isColomboUser(user) && formValues.extratoOnline ? (
        <Button
          px="5px"
          fontSize="11px"
          leftIcon={<CheckIcon w="12px" h="12px" />}
          onClick={() => {
            openLastStatementModal();
            onClose?.();
          }}
          disabled={isLoading}
          // ml="10px"
          size="xs"
        >
          Ver último extrato
        </Button>
      ) : null}
    </Grid>
  );
}
