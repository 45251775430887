import { Box, Grid } from "@chakra-ui/react";
import { InputField } from "components/input-field";

export function CalendarFilter({
  onChange,
  filter,
  keyDataFim,
  keyDataInicio,
}: {
  onChange: (value: any, key: any) => void;
  filter: any;
  keyDataInicio: string;
  keyDataFim: string;
}) {
  return (
    <Grid templateColumns="1fr 1fr" w="100%" gap="7px">
      <Box>
        <InputField
          inputProps={{
            variant: "outline-gray",
            height: "48px",
            type: "date",
            onClick: (e) => e.stopPropagation(),
          }}
          value={filter[keyDataInicio] || ""}
          onChange={(e) => {
            onChange(e.target.value, keyDataInicio);
          }}
        />
      </Box>
      <Box>
        <InputField
          inputProps={{
            variant: "outline-gray",
            height: "48px",
            type: "date",
            onClick: (e) => e.stopPropagation(),
          }}
          value={filter[keyDataFim] || ""}
          onChange={(e) => {
            onChange(e.target.value, keyDataFim);
          }}
        />
      </Box>
    </Grid>
  );
}
