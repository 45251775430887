import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { enviarTelefoneContato } from "components/chat-window/functions";
import { CustomModal } from "components/custom-modal";
import { WhatsAppIcon2 } from "components/vectors/whatsapp-icons";
import { useAttendanceContext } from "contexts/attendance-context";
import { Dispatch, SetStateAction, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { maskPhone } from "utils/string-formats";

export function TelefoneContatoDialog({
  isLoading,
  setLoading,
}: {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { dispatch, formControl } = useAttendanceContext();
  const [telefoneContato, setTelefoneContato] = useState("");
  const { id } = formControl.values;

  const saveNumber = () => {
    enviarTelefoneContato(telefoneContato, id!, setLoading, dispatch, onClose);
  };

  useEventListener("open-modal-change-number", onOpen);

  const footer = (
    <>
      <Button onClick={onClose} variant="outline">
        Cancelar
      </Button>
      <Button
        onClick={saveNumber}
        isLoading={isLoading}
        loadingText="Salvando"
        disabled={!telefoneContato}
      >
        Salvar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      modalTitle={
        <Flex>
          <Box mr="15px">
            <WhatsAppIcon2 />
          </Box>
          <Text>Adicionar whatsapp</Text>
        </Flex>
      }
      onClose={onClose}
      modalFooter={footer}
      size="xl"
    >
      <Text mb="10px">Informe o novo telefone (WhatsApp) do contato</Text>
      <Input
        value={telefoneContato}
        placeholder="Novo número"
        variant="outline-custom"
        onChange={(e) => setTelefoneContato(maskPhone(e.target.value))}
        onKeyDown={(e) => (e.key === "Enter" ? saveNumber() : undefined)}
        mb="10px"
      />
    </CustomModal>
  );
}

export const openTelefoneContatoDialog = () =>
  makeEvent(`open-modal-change-number`);
