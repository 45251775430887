import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import apiCarteira from "api/api-carteira";
import { CustomModal } from "components/custom-modal";
import { DolarIcon2 } from "components/vectors/dolar-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { currencyMask } from "utils/string-formats";
import { SelectedCustomer } from "pages/carteira";
import { Toast } from "components/toast";
import { Dropdown } from "components/dropdown";

export function ModalInsertCoin({
  selectedCustomer,
  onChangePartner,
}: {
  selectedCustomer: SelectedCustomer;
  onChangePartner: (selectedPartner: SelectedCustomer) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [insertSaldoValue, setInsertSaldoValue] = useState("");
  const [tipoCarteira, setTipoCarteira] = useState("GERAL");
  const [tipoCredito, setTipoCredito] = useState(null);
  // const { user } = useApplicationContext();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setInsertSaldoValue("");
    setTipoCarteira("GERAL");
    setTipoCredito(null);
  };

  useEventListener("openModalInsertCoin", onOpen);

  const handleSubmit = async (valor: number, tipoCarteira: string | null, tipoCredito: string | null) => {
    setIsLoading(true);
    try {
      const { data } = await apiCarteira.post(
        `/carteiras/creditos/add/${selectedCustomer.id}`,
        { valor, tipoCarteira, tipoCredito }
      );
      Toast({
        title: "Saldo inserido com sucesso",
        description: `Inserido no cliente: ${selectedCustomer.name}`,
        status: "success",
      });
      onClose();

      onChangePartner({ ...selectedCustomer });
    } catch (e) {
      Toast({
        title: "Erro ao inserir saldo",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const tipoCarteiraArray = [
    {
      name: "Geral",
      value: "GERAL",
    },
    {
      name: "Fgts",
      value: "FGTS",
    },
  ];

  const tipoCreditoArray = [
    {
      name: "Selecione",
      value: null,
    },
    {
      name: "Cashback",
      value: "CASHBACK",
    },
    {
      name: "Cortesia",
      value: "CORTESIA",
    },
    {
      name: "Transferência",
      value: "TRANSFERENCIA",
    },
  ]

  const modalFooter = (

    <>
      <Button
        leftIcon={<DolarIcon2 variant="white" />}
        isLoading={isLoading}
        loadingText="Inserindo"
        onClick={() => {
          handleSubmit(
            Number(insertSaldoValue.replace(/\./g, "").replace(",", ".")),
            tipoCarteira, tipoCredito
          );
        }}
        isDisabled={insertSaldoValue === "0,00" || !insertSaldoValue || !tipoCarteira}
      >
        Inserir
      </Button>
      <Button
        onClick={onClose}
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Cancelar
      </Button>
    </>
  );
  return (
    <CustomModal
      modalTitle="Adicionar Saldo"
      modalFooter={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <Box>
        <Flex alignItems="center" mb="8px">
          <Text>Carteira:</Text>
        </Flex>

        <Dropdown
          options={tipoCarteiraArray}
          w="100%"
          variant="outline-custom"
          placeholder={tipoCarteiraArray[0].name}
          defaultValue={tipoCarteiraArray[0].value}
          value={tipoCarteira}
          onChange={(value) => {
            setTipoCarteira(value);
          }}
        />

        <Flex alignItems="center" mb="8px" mt="12px">
          <Text>Valor:</Text>
        </Flex>
        <Input
          w="100%"
          mb="12px"
          variant="outline-custom"
          onChange={(e) => setInsertSaldoValue(currencyMask(e.target.value))}
          value={currencyMask(insertSaldoValue)}
          //   type="number"
        />

        <Flex alignItems="center" mb="8px" mt="12px">
          <Text>Tipo Crédito:</Text>
        </Flex>

        <Dropdown
          options={tipoCreditoArray}
          w="100%"
          variant="outline-custom"
          value={tipoCredito}
          onChange={(value) => {
            setTipoCredito(value);
          }}
        />
      </Box>
    </CustomModal>
  );
}

export const openModalInsertCoin = () => makeEvent("openModalInsertCoin");
