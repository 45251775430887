import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAttendanceContext } from "contexts/attendance-context";
import { Dispatch, SetStateAction, useCallback } from "react";
import { DigitacaoAntecipacaoFGTSColombo } from "./digitacao-fgts-colombo";
import { INSSDigitacao } from "./digitacao-inss";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export function DigitacaoAtendimentoFormPart({
  isLoading,
  setIsLoading,
  onChangeTab,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const { user } = useApplicationContext();
  const formValues = formControl.values;
  const convenio = formValues.convenio;

  const onChangeInput = useCallback(
    (fieldName: keyof Attendance) => (value: string) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: value },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const onChangeDropdown = useCallback(
    (fieldName: keyof Attendance) => (newValue: any) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: newValue },
      });
    },
    []
  );

  return (
    <>
      {convenio === "INSS" ? (
        <INSSDigitacao />
      ) : convenio === "ANTECIPACAO_FGTS" ? (
        <DigitacaoAntecipacaoFGTSColombo />
      ) : null}
    </>
  );
}
