import { Grid } from "@chakra-ui/react";
import api from "api/api";
import { GetLayout } from "components/get-layout";
import { ServiceCard } from "components/servicos-components/service-card";
import {
  BankBgIcon,
  CardBgIcon,
  DocumentBgIcon,
  PhoneBgIcon,
  TalkBallonBgIcon,
} from "components/servicos-components/service-card/icons";
import { ModalAtivoStatus } from "components/servicos-components/service-card/modal-ativo-status";
import { ModalChangeCobranca } from "components/servicos-components/service-card/modal-change-cobranca";
import { WhatsAppServiceCard } from "components/servicos-components/service-card/whatsapp-service-card";
import { PartnersSearch } from "components/carteira-components/modal-partners-search";
import { useApplicationContext } from "contexts/ApplicationContext";
import { SelectedCustomer } from "pages/carteira";
import { useState } from "react";
import { MargemConvênioServiceCard } from "components/servicos-components/service-card/margem-convenio-card";
import { SimulacaoFGTSServiceCard } from "components/servicos-components/service-card/simulacao-fgts";
import apiCarteira from "api/api-carteira";
import { Toast } from "components/toast";

export function ServicosPage() {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>({
    id: -1,
    name: "",
    codigoNova: "",
    cashback: "PENDING",
  });
  const [serviceData, setServiceData] = useState<any>({});
  const [cardData, setCardData] = useState<
    Partial<{ simulacaoFGTS: { ativo: boolean } }>
  >({});
  const { user } = useApplicationContext();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [loadingApiCarteira, setLoadingAPICarteira] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("partnerId");

  const getDataAPICarteira = async (id: number) => {
    setLoadingAPICarteira(true);
    try {
      const { data } = await apiCarteira.get(`/services?customer_id=${id}`);
      setCardData(data);
    } catch (e) {
      Toast({ title: "Erro ao obter dados", status: "error" });
    } finally {
      setLoadingAPICarteira(false);
    }
  };

  const loadServices = async ({
    id,
    ...rest
  }: Partial<SelectedCustomer> = {}) => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/services?customerId=${id}`);
      await getDataAPICarteira(id!);
      setSelectedCustomer({ id, ...rest } as SelectedCustomer);
      setServiceData(data);
    } catch (e) {
      Toast({ title: "Erro ao obter serviços", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GetLayout>
      <PartnersSearch
        selectedCustomer={selectedCustomer}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChangePartner={loadServices}
        defaultCustomerId={partnerId ? Number(partnerId) : null}
      />
      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr 1fr",
          lg: "1fr 1fr 1fr",
          xl: "1fr 1fr 1fr 1fr",
        }}
        gap={{ base: "16px", "2xl": "36px" }}
      >
        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Higienização Refin"
          priceDesc="R$ 19,90 mensal (ou R$ 0,05 por consulta)"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="servicesDatafast"
          bgIcon={<BankBgIcon />}
          isLoading={isLoading}
          containerProps={{ gridArea: "span 2 / auto" }}
        />

        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Geração Mailing"
          priceDesc="R$ 0,0025 por lead"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="geracaoMailing"
          bgIcon={<DocumentBgIcon />}
          isLoading={isLoading}
        />
        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Consulta Offline"
          priceDesc="R$ 0,015 por consulta"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="consultaOffline"
          bgIcon={<DocumentBgIcon />}
          isLoading={isLoading}
        />

        <ServiceCard
          selectedPartner={selectedCustomer}
          title="IN100"
          priceDesc="R$ 0,50 por consulta"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="in100"
          bgIcon={<DocumentBgIcon />}
          isLoading={isLoading}
        />

        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Higienização Telefones"
          priceDesc="R$ 0,08 por consulta"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="telefonia"
          bgIcon={<PhoneBgIcon />}
          isLoading={isLoading}
        />

        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Consulta Saque Complementar"
          priceDesc="R$ 19,90 mensal (ou R$ 0,05 por consulta)"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="servicesAPICartaoBancoDTO"
          bgIcon={<CardBgIcon />}
          isLoading={isLoading}
        />

        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Envio SMS"
          priceDesc="R$ 0,15 por envio"
          serviceData={serviceData}
          setServiceData={setServiceData}
          field="sms"
          bgIcon={<TalkBallonBgIcon />}
          isLoading={isLoading}
        />

        <ServiceCard
          selectedPartner={selectedCustomer}
          title="Extrato"
          priceDesc="R$ 4,00 por extrato"
          field="extratoOnline"
          serviceData={serviceData}
          setServiceData={setServiceData}
          bgIcon={<DocumentBgIcon />}
          isLoading={isLoading}
        />
        <WhatsAppServiceCard
          isLoading={isLoading}
          title="Sessões WhatsApp"
          priceDesc="R$ 15,00 mensal (por sessão)"
          serviceData={serviceData}
          selectedCustomer={selectedCustomer}
        />
        <SimulacaoFGTSServiceCard
          isLoading={loadingApiCarteira || isLoading}
          title="Simulacao FGTS"
          priceDesc="R$ 0,10 avulso"
          serviceData={cardData}
          selectedCustomer={selectedCustomer}
          setCardData={setCardData}
        />
        {[1, 1775, 1950, 1621, 5803].includes(user.userData.customerId!) ? (
          <MargemConvênioServiceCard
            isLoading={isLoading}
            title="Margem Convênio"
            priceDesc="R$ 350,00 p/ quantidade de 15000"
            serviceData={serviceData}
            selectedCustomer={selectedCustomer}
          />
        ) : null}
      </Grid>
      <ModalAtivoStatus />
      <ModalChangeCobranca />
    </GetLayout>
  );
}
