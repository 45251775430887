import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import apiHigienizacao from "api/api-higienizacao";
import { ConsultaCPFProps } from "components/atendimentos-components/pagina-atendimento/attendance-data-form/variables";
import { GetLayout } from "components/get-layout";
import { InputField } from "components/input-field";
import { TitleWithBackButton } from "components/title-with-back-button";
import { Toast } from "components/toast";
import { WhatsAppIcon } from "components/vectors/whatsapp-icons";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { maskCPFFormat, maskPhoneFormat } from "utils/string-formats";

export function ConsultaTelefonia() {
  const [cpf, setCPF] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [consulta, setConsulta] = useState<ConsultaCPFProps | null>(null);
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    const prev = Cookies.get("consult-cpf");
    if (prev) setHistory(JSON.parse(prev));
  }, []);

  const deleteHistory = (cpf: string) => {
    const newHistory = history.filter((crr) => crr !== cpf);
    Cookies.set("consult-cpf", JSON.stringify(newHistory));
    setHistory(newHistory);
  };

  const assignHistory = (cpf: string) => {
    const newHistory = [...new Set([cpf, ...history].slice(0, 5))];
    Cookies.set("consult-cpf", JSON.stringify(newHistory));
    setHistory(newHistory);
  };

  const onConsult = async (currCpf = cpf) => {
    setIsLoading(true);
    try {
      const { data }: { data: ConsultaCPFProps } = await apiHigienizacao.get(
        `/telefonia/dados/${currCpf}`,
        { params: { whatsapp: true } }
      );
      setConsulta(data);
      assignHistory(data.cpf);
      setCPF(data.cpf);
    } catch (e: any) {
      const msgInterface = e?.response?.data?.msgInterface;
      const status = e?.response?.status;
      Toast({
        title: msgInterface ?? "Erro ao fazer consulta",
        status: status === 500 ? "error" : "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GetLayout>
      <TitleWithBackButton title="Consulta Telefonia" />
      <Box borderRadius="8px" w="100%" mb="16px">
        <Text mb="10px">Consulta Telefonia:</Text>
        <Grid templateColumns="auto 200px" gap="16px">
          <Input
            placeholder="Digite um CPF"
            onKeyDown={(e) =>
              e.key === "Enter" && cpf.length === 11 ? onConsult() : undefined
            }
            onChange={(e) => {
              setCPF(maskCPFFormat(e.target.value).raw);
            }}
            value={maskCPFFormat(cpf).value}
            variant="outline-custom"
            isDisabled={isLoading}
          />
          <Button
            leftIcon={<Search2Icon />}
            onClick={() => onConsult()}
            isLoading={isLoading}
            loadingText="Consultando"
            isDisabled={cpf.length < 11}
            size="sm"
          >
            Consultar
          </Button>
        </Grid>
      </Box>
      <Box
        w="100%"
        bg={history.length || isLoading || consulta ? "white" : undefined}
        borderRadius={"8"}
        p={consulta ? "10px 20px 15px 20px" : "10px 20px 10px 20px"}
      >
        {history.length ? (
          <Flex justifyContent="flex-start" w="100%" mb="10px">
            {history.map((cpf, index) => {
              return (
                <HistoryTab
                  key={index}
                  cpf={cpf}
                  currCpf={consulta?.cpf}
                  deleteHistory={deleteHistory}
                  index={index}
                  onConsult={onConsult}
                />
              );
            })}
          </Flex>
        ) : null}

        {isLoading ? (
          <Center w="100%" h="400px">
            <Spinner />
          </Center>
        ) : consulta ? (
          <>
            <Box w="100%" mb="10px">
              <Text fontWeight="semibold" fontSize="20">
                Dados Cadastrais
              </Text>
              <Box
                w="100%"
                my="8px"
                borderBottom="1px solid var(--chakra-colors-gray-200)"
              ></Box>
              <Grid templateColumns="1fr 1fr 1fr" gap="10px">
                <Box>
                  <Text mb="8px">CPF:</Text>
                  <Input value={consulta?.cpf || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">Nome Completo:</Text>
                  <Input value={consulta?.nomeCompleto || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">Data Nascimento:</Text>
                  <Input value={consulta?.dataNascimento || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">Nome Mãe:</Text>
                  <Input value={consulta?.nomeMae || ""} isReadOnly />
                </Box>
                {consulta?.emails?.length
                  ? consulta.emails.map(({ email }, i) => {
                      return (
                        <Box key={i}>
                          <Text mb="8px">Email {i + 1}:</Text>
                          <Input value={email} isReadOnly />
                        </Box>
                      );
                    })
                  : null}
              </Grid>
            </Box>
            <Box w="100%" my="10px">
              <Text fontWeight="semibold" fontSize="20">
                Telefones
              </Text>
              <Box
                w="100%"
                my="8px"
                borderBottom="1px solid var(--chakra-colors-gray-200)"
              ></Box>
              <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
                {consulta.telefones?.map(({ phone, whatsApp }, i) => {
                  return (
                    <Box key={i}>
                      <Text mb="8px">Telefone {i + 1}:</Text>
                      <InputField
                        value={
                          phone.length === 11
                            ? maskPhoneFormat(phone).formatedValue
                            : phone
                        }
                        rightIcon={whatsApp && <WhatsAppIcon />}
                        inputProps={{ isReadOnly: true }}
                      />
                    </Box>
                  );
                })}
              </Grid>
            </Box>

            <Box w="100%">
              <Text fontWeight="semibold" fontSize="20">
                Endereço
              </Text>
              <Box
                w="100%"
                my="8px"
                borderBottom="1px solid var(--chakra-colors-gray-200)"
              ></Box>
              <Grid templateColumns="1fr 1fr 1fr" gap="10px">
                <Box>
                  <Text mb="8px">Logradouro:</Text>
                  <Input
                    value={consulta?.endereco.logradouro || ""}
                    isReadOnly
                  />
                </Box>
                <Box>
                  <Text mb="8px">Número:</Text>
                  <Input value={consulta?.endereco.numero || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">Complemento:</Text>
                  <Input
                    value={consulta?.endereco.complemento || ""}
                    isReadOnly
                  />
                </Box>
                <Box>
                  <Text mb="8px">Bairro:</Text>
                  <Input value={consulta?.endereco.bairro || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">Cidade:</Text>
                  <Input value={consulta?.endereco.cidade || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">UF:</Text>
                  <Input value={consulta?.endereco.uf || ""} isReadOnly />
                </Box>
                <Box>
                  <Text mb="8px">CEP:</Text>
                  <Input value={consulta?.endereco.cep || ""} isReadOnly />
                </Box>
              </Grid>
            </Box>
          </>
        ) : null}
      </Box>
    </GetLayout>
  );
}

function HistoryTab({
  index,
  cpf,
  currCpf,
  onConsult,
  deleteHistory,
}: {
  currCpf?: string;
  cpf: string;
  index: number;
  onConsult: (cpf: string) => Promise<void>;
  deleteHistory: (cpf: string) => void;
}) {
  const isActive = currCpf === cpf;
  return (
    <Center
      flexDir="column"
      p="10px 12px 10px"
      _hover={{ bg: "gray.100" }}
      borderRadius="6px 6px 0px 0px"
      onClick={() => onConsult(cpf)}
      cursor="pointer"
      justifyContent="flex-start"
      userSelect="none"
      pos="relative"
      borderLeft={
        index === 0 ? undefined : "1px solid var(--chakra-colors-gray-200)"
      }
    >
      <Center>
        <Text>{cpf}</Text>
        <IconButton
          aria-label=""
          size="xs"
          ml="8px"
          variant="outline"
          w="20px"
          h="20px"
          minW="20px"
          icon={<CloseIcon w="8px" h="8px" />}
          onClick={(e) => {
            e.stopPropagation();
            deleteHistory(cpf);
          }}
        />
      </Center>
      {isActive ? (
        <Box
          pos="absolute"
          bottom="0px"
          left="0px"
          right="0px"
          borderRadius="10px"
          h="4px"
          bg="primary.300"
        />
      ) : null}
    </Center>
  );
}
