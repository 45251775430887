import { Text } from "@chakra-ui/react";
import api from "api/api";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, Fragment, SetStateAction } from "react";
import { calcularMargemCartao, calcularSaqueCartao } from "../../fields-data";
import { Attendance, ContratoInss, ContratoRMCInss } from "../../types";
import {
  AttendanceActionsTypes,
  FormControl,
  currentAttendanceForm,
} from "../../../../../contexts/attendance-context/actions-reducer";
import { KeyForward } from "./types";
import { Toast } from "components/toast";

const formatDataContract = (data: string | null) => {
  if (data) {
    const comTracos = data.indexOf("-") !== -1;
    const isAnoNoComeco = data.split("-")[0].length === 4;
    if (isAnoNoComeco && comTracos) {
      data = data.split("-").reverse().join("/");
    } else if (!isAnoNoComeco && comTracos) {
      data = data.split("-").join("/");
    }
    if (!comTracos) {
      if (data.length === 6) data = `${data.slice(4, 6)}/${data.slice(0, 4)}`;
    }
  }
  return data;
};

const parseContractInss = (contract: any) => {
  const formated: Partial<ContratoInss> = {};
  delete contract["nomeBanco"];
  formated.codTipoEmprestimo = "98";
  formated.saldoQuitacao = contract.saldoDevedor;
  formated.codBanco = contract.codBanco;
  formated.valorParcela = contract.valorParcela;
  formated.quantidadeParcelas = contract.prazo;
  formated.taxa = contract.taxa;
  formated.quantidadeParcelasEmAberto = contract.parcelasRestante;
  formated.contrato = contract.contrato;
  formated.valorEmprestado = contract.valorEmprestimo;
  formated.competenciaInicioDesconto = formatDataContract(
    contract.inicioDesconto
  );
  formated.competenciaFimDesconto = formatDataContract(contract.fimDesconto);
  formated.dataInicioContrato = formatDataContract(contract.dataAverbacao);
  formated.origem = contract.origem;
  formated.situacao = contract.situacao;
  return formated;
};

const parseContractRMCorRCC = (contract: any, isRMC: boolean) => {
  const formated: Partial<ContratoRMCInss> | Partial<ContratoRMCInss> = {};
  formated.codBanco = contract.codBanco;
  formated.contrato = contract.contrato;
  formated.limiteCartao = contract.valorEmprestimo;
  formated.dataInclusao = formatDataContract(contract.dataInclusao);
  formated.codTipoEmprestimo = isRMC ? "76" : "44";
  formated.valorReservado = contract.valorParcela;
  formated.origem = contract.origem;
  formated.dataInicioContrato = formatDataContract(contract.dataInclusao);
  formated.situacao = contract.situacao;
  return formated;
};

export const copyExtratoDataToAtendimento = (
  attendance: Attendance,
  dispatch: React.Dispatch<AttendanceActionsTypes>
) => {
  let margens = attendance.margens || {};
  let extratoFields: any = attendance.extratoOnline?.extratoOnlinePadrao || {};
  extratoFields.possuiCartaoBeneficio = !!extratoFields?.cartaoRcc;
  extratoFields.possuiCartaoRmc = !!extratoFields?.cartaoRmc;
  console.log(extratoFields);
  // extratoFields?.emprestimos;

  const keyForward: KeyForward<Attendance> = {
    numeroBeneficio: (data) => {
      if (data) {
        data = data.toString();
        while (data.length < 10) data = "0" + data;
      }
      return {
        data,
        field: "nb",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    dataNascimento: (data) => {
      if (data) {
        data = data.split("-").reverse().join("/");
      }
      return {
        data,
        field: "dataNascimento",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    nome: (data) => {
      return { data, field: "nome", assignJson: { flag: "EXTRATO_ONLINE" } };
    },
    especie: (data) => {
      data = data ? Number(data) : data;
      return {
        data,
        field: "especieNB",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    ddb: (data: string | null | undefined) => {
      data = data?.split("-").reverse().join("/");
      return {
        data,
        field: "ddb",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    situacaoBeneficio: (data) => {
      if (data?.toLocaleUpperCase().includes("ATIVO")) {
        data = "ATIVO";
      } else if (data?.toLocaleUpperCase().includes("CANCELADO")) {
        data = "CANCELADO";
      } else if (data?.toLocaleUpperCase().includes("CESSADO")) {
        data = "CESSADO";
      } else if (data?.toLocaleUpperCase().includes("SUSPENSO")) {
        data = "SUSPENSO";
      } else data = null;
      return {
        data,
        field: "situacaoNB",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    valorBeneficio: (data) => {
      return {
        data,
        field: "valorSalario",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    ufBeneficio: (data) => {
      return {
        data,
        field: "ufNB",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    cpf: (data) => {
      if (data != null) {
        data = `${data}`.trim();
        while (data.length < 11) data = "0" + data;
      }
      return {
        data,
        field: "cpf",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    bloqueadoEmprestimo: (data) => {
      return {
        data,
        field: "bloqueadoEmprestimo",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    elegivelEmprestimo: (data) => {
      return {
        data,
        field: "elegivelEmprestimo",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    possuiRepresentanteLegal: (data) => {
      return {
        data,
        field: "possuiRepresentante",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    pensaoAlimenticia: (data) => {
      return {
        data,
        field: "pensaoAlimenticia",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    meioPagamento: (data) => {
      if (data === "Cartão Magnético") {
        data = "CARTAO_MAGNETICO";
      } else if (data === "CONTA CORRENTE") {
        data = "CONTA_CORRENTE";
      } else data = undefined;
      return {
        data,
        field: "tipoPagamento",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    codBanco: (data) => {
      return {
        data,
        field: "codBancoPagamento",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    agencia: (data) => {
      if (data) {
        dispatch({
          type: "changeField",
          payload: { fieldName: "dvAgenciaPagamento", data: "" },
        });
      }
      return {
        data,
        field: "agenciaPagamento",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    numeroConta: (data) => {
      if (data) {
        dispatch({
          type: "changeField",
          payload: { fieldName: "dvContaPagamento", data: "" },
        });
      }
      return {
        data,
        field: "contaPagamento",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    margemDisponivelEmprestimo: (data) => {
      margens = { ...margens, valorMargemLivreNovo: data };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          flag: "EXTRATO_ONLINE",
          attendanceKeyPath: "margens.valorMargemLivreNovo",
        },
      };
    },
    emprestimos: (data) => {
      const parseData = data?.map((c: any) => {
        c.origem = "EXTRATO_ONLINE";
        return parseContractInss(c);
      });
      return {
        data: parseData,
        field: "contratosEmprestimosInss",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    cartaoRcc: (data) => {
      if (data) {
        data.origem = "EXTRATO_ONLINE";
        data = parseContractRMCorRCC(data, false);
      }
      return {
        data: data ? [data] : undefined,
        field: "contratosRCCInss",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    cartaoRmc: (data) => {
      if (data) {
        data.origem = "EXTRATO_ONLINE";
        data = parseContractRMCorRCC(data, true);
      }
      return {
        data: data ? [data] : undefined,
        field: "contratosRMCInss",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },

    possuiCartaoBeneficio: (data) => {
      return {
        data,
        field: "possuiCartaoBeneficio",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
    possuiCartaoRmc: (data) => {
      return {
        data,
        field: "possuiCartaoRmc",
        assignJson: { flag: "EXTRATO_ONLINE" },
      };
    },
  };

  if (attendance.extratoOnline?.extratoOnlinePadrao)
    mapObject({ keyForward, data: extratoFields, dispatch });
};

export function checkRequiredFields(
  attendance: Attendance,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const optionalFields: (keyof Attendance)[] = ["nomePai"];
  const requiredFields: (keyof Attendance)[] = [
    "convenio",
    "cpf",
    "nome",
    "dataNascimento",
    "sexo",
    "documento",
    "especieNB",
    "dataEmissaoDoc",
    "nomeMae",
    "cep",
    "endereco",
    "numero",
    "bairro",
    "cidade",
    "uf",
    "agenciaPagamento",
    "contaPagamento",
    "dvContaPagamento",
    "valorSalario",
    "estadoCivil",
    "prazo",
    "bancoDigitacao",
    "naturalidade",
    "orgaoEmissorDoc",
    "ufEmissaoDoc",
    "ufNaturalidade",
    "tipoOperacao",
    "codBancoPagamento",
    "nb",
    "ufNB",
    "tipoPagamento",
    "codigoTabela",
    "tipoContaPagamento",
  ];

  let isValid = true;
  let values: any = {};
  const {
    bancoDigitacao,
    tipoOperacao,
    valorSalario,
    needTokenTabela,
    possuiRepresentante,
  } = attendance;

  //additional values
  values["atendimentoId"] = attendance.id;
  if (tipoOperacao === "CARTAO_BENEFICIO_INSS") {
    values["valorMargemCartaoBeneficio"] = calcularMargemCartao(valorSalario);
    values["valorSaqueCartaoBeneficio"] = calcularSaqueCartao(
      attendance.margens?.valorMargemCartaoRCC,
      attendance.margens?.multiplicadorRCC,
      attendance.margens?.porcentagemSaqueRMC
    );
  }
  if (possuiRepresentante) {
    requiredFields.push("cpfRepresentanteLegal");
    requiredFields.push("nomeRepresentanteLegal");
  }
  if (tipoOperacao === "NOVO_INSS" || tipoOperacao === "AUMENTO_MARGEM") {
    requiredFields.push("valorSolicitadoCliente");
  }
  if (104 === Number(attendance.codBancoPagamento)) {
    requiredFields.push("tipoContaPagamento");
  }
  if (bancoDigitacao === "FACTA") {
    requiredFields.push("nomePai");
    requiredFields.push("telefoneCelular");
  }
  if (needTokenTabela) {
    requiredFields.push("tokenTabela");
  }
  optionalFields.forEach((key) => {
    if (attendance[key]) {
      values[key] = attendance[key];
    }
  });

  let invalidFields: string[] = [];

  requiredFields.forEach((key) => {
    if (!attendance[key]) {
      dispatch({
        type: "setInvalidField",
        payload: { action: "append", fieldName: key },
      });
      invalidFields.push(key);
      isValid = false;
    } else {
      values[key] = attendance[key];
    }
  });

  return { isValid, values, invalidFields };
}

export const getDescAtendimento = (key: keyof Attendance) => {
  return (
    {
      telefoneCelular: "Telefone Celular",
      valorSolicitadoCliente: "Valor Solicitado Cliente",
      cpfRepresentanteLegal: "CPF Representante",
      nomeRepresentanteLegal: "Nome do representante",
      valorSaqueCartaoBeneficio: "Valor Saque Cartão Benefício",
      valorMargemCartaoBeneficio: "Valor Margem Cartão Benefício",
      nomePai: "Nome Pai",
      convenio: "Convênio",
      cpf: "CPF",
      nome: "Nome",
      dataNascimento: "Data Nascimento",
      sexo: "Sexo",
      documento: "Documento",
      especieNB: "Espécie Benefício",
      dataEmissaoDoc: "Data Emissão Documento",
      nomeMae: "Nome Mãe",
      cep: "CEP",
      endereco: "Endereço",
      numero: "Número",
      bairro: "Bairro",
      cidade: "Cidade",
      uf: "UF",
      agenciaPagamento: "Agência Pagamento",
      contaPagamento: "Conta Pagamento",
      dvContaPagamento: "DV (Conta)",
      valorSalario: "Valor Salário",
      estadoCivil: "Estado Civil",
      prazo: "Prazo",
      bancoDigitacao: "Banco Digitação",
      naturalidade: "Naturalidade",
      orgaoEmissorDoc: "Orgão Emissor Doc",
      ufEmissaoDoc: "UF Emissão Documento",
      ufNaturalidade: "UF Naturalidade",
      tipoOperacao: "Tipo Operação",
      codBancoPagamento: "Código Banco Pagamento",
      nb: "Número benefício",
      ufNB: "UF Benefício",
      tipoPagamento: "Tipo pagamento",
      codigoTabela: "Código tabela",
      tipoContaPagamento: "Tipo conta pagamento",
    } as any
  )[key];
};

async function onDigitation(
  dispatch: Dispatch<AttendanceActionsTypes>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  values: any
) {
  setLoading(true);
  try {
    const { data } = await api.post("/propostas/digitar", values);
    setLoading(false);
    Toast({
      title: "Proposta criada com sucesso",
    });
    if (data.numeroProposta) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "ade",
          data: data.numeroProposta,
        },
      });
    }
    if (
      data.numeroProposta ||
      (values.bancoDigitacao === "FACTA" &&
        data.statusProposta === "NA FILA DE DIGITAÇÃO")
    ) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "digitadoApi",
          data: true,
        },
      });
    }
    if (data.linkFormalizacao) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "linkFormalizacao",
          data: data.linkFormalizacao,
        },
      });
    }
    if (data.resultadoDigitacao) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "resultadoDigitacao",
          data: data.resultadoDigitacao,
        },
      });
    }
  } catch (e: any) {
    setLoading(false);
    openModalError({
      message: getErrorByMessage(
        e,
        e?.response?.data?.message ?? "Erro ao digitar"
      ),
    });
  }
}

export async function createDigitacaoFACTA(
  setModalLoading: Dispatch<SetStateAction<boolean>>,
  formControl: FormControl,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const { isValid, values, invalidFields } = checkRequiredFields(
    formControl.values as Attendance,
    dispatch
  );
  if (isValid) {
    openModalConfirm({
      message: "Gerar proposta?",
      onConfirm: () => onDigitation(dispatch, setModalLoading, values),
      confirmButtonStyle: { variant: undefined },
    });
  } else {
    setModalLoading(false);
    openModalError({
      message: (
        <Text>
          Os seguintes campos estão faltando:
          <br />
          <br />
          {invalidFields.map((item) => {
            return (
              <Fragment key={item}>
                <Text as="span" color="red">
                  {getDescAtendimento(item as keyof Attendance)}
                </Text>
                <br />
              </Fragment>
            );
          })}
        </Text>
      ),
      title: "Campos estão faltando",
    });
    Toast({
      title: "Preencher Campos necessários!",
      status: "error",
    });
    return "Fields_Error";
  }
}

export async function createDigitacaoPAN(
  setModalLoading: Dispatch<SetStateAction<boolean>>,
  formControl: FormControl,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const { isValid, values, invalidFields } = checkRequiredFields(
    formControl.values as Attendance,
    dispatch
  );
  if (isValid) {
    openModalConfirm({
      message: "Gerar proposta?",
      onConfirm: () => onDigitation(dispatch, setModalLoading, values),
    });
  } else {
    setModalLoading(false);
    openModalError({
      message: (
        <Text>
          Os seguintes campos estão faltando:
          <br />
          <br />
          {invalidFields.map((item) => {
            return (
              <Fragment key={item}>
                <Text as="span" color="red">
                  {getDescAtendimento(item as keyof Attendance)}
                </Text>
              </Fragment>
            );
          })}
        </Text>
      ),
      title: "Campos estão faltando",
    });
    Toast({
      title: "Preencher Campos necessários!",
      status: "error",
    });
  }
}

export async function createDigitacao(
  setModalLoading: Dispatch<SetStateAction<boolean>>,
  formControl: FormControl,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const { isValid } = checkRequiredFields(
    formControl.values as Attendance,
    dispatch
  );
  const { id: atendimentoId } = formControl.values;
  if (isValid) {
    openModalConfirm({
      message: "Gerar proposta?",
      onConfirm: () =>
        onDigitation(dispatch, setModalLoading, { atendimentoId }),
    });
  } else {
    setModalLoading(false);
    Toast({
      title: "Preencher Campos necessários!",
      status: "error",
    });
  }
}
const isObject = (data: any) =>
  typeof data === "object" && !Array.isArray(data) && data !== null;

export function mapObject({
  keyForward,
  data,
  dispatch,
  ignoreNullFields = true,
}: {
  keyForward: KeyForward<Attendance>;
  data: any;
  dispatch: Dispatch<AttendanceActionsTypes>;
  ignoreNullFields?: boolean;
}) {
  const keys = Object.keys(keyForward);

  for (let key of keys) {
    const splitKey = key.split(".");
    let currData: any = null;
    splitKey.forEach((subKey) => {
      const attendance = currentAttendanceForm.values;
      if (isObject(data?.[subKey]) && splitKey.length > 1) {
        currData = data?.[subKey];
        return;
      }
      if (currData) {
        if (isObject(currData?.[subKey])) {
          currData = currData?.[subKey];
          return;
        }
      }
      let fieldData = currData?.[subKey] ?? data?.[subKey];

      if (!ignoreNullFields || fieldData != null) {
        let attendanceField = keyForward[key];
        let dispatchOptionsProps: any;
        let jsonOptions:
          | { flag: string; attendanceKeyPath?: string }
          | undefined;

        if (typeof attendanceField === "function") {
          const { data, field, dispatchOptions, assignJson } = attendanceField(
            fieldData,
            attendance as Attendance
          );
          fieldData = data;
          attendanceField = field;
          dispatchOptionsProps = dispatchOptions;
          jsonOptions = assignJson;
        }

        let json = attendance?.sourceFieldsJson || "{}";
        let sourceFieldsJson: { [k: string]: { updatedBy: string } } =
          JSON.parse(json);
        let { attendanceKeyPath, flag } = jsonOptions || {};
        attendanceKeyPath =
          attendanceKeyPath == null ? attendanceField : attendanceKeyPath;

        let isSetted = false;

        if (flag === "CONSULTA OFFLINE") {
          const updatedBy = sourceFieldsJson[attendanceKeyPath]?.updatedBy;
          if (updatedBy === flag || updatedBy == null) {
            dispatch({
              type: "changeField",
              payload: { fieldName: attendanceField, data: fieldData },
              ...dispatchOptionsProps,
            });
            if (attendance) (attendance as any)[attendanceField] = fieldData;
            isSetted = true;
          }
        } else {
          dispatch({
            type: "changeField",
            payload: { fieldName: attendanceField, data: fieldData },
            ...dispatchOptionsProps,
          });
          if (attendance) (attendance as any)[attendanceField] = fieldData;
          isSetted = true;
        }

        if (jsonOptions && attendance && isSetted) {
          let currentValues = sourceFieldsJson[attendanceKeyPath];
          sourceFieldsJson = {
            ...sourceFieldsJson,
            [attendanceKeyPath]: { ...currentValues, updatedBy: flag! },
          };
          json = JSON.stringify(sourceFieldsJson);

          dispatch({
            type: "changeField",
            payload: { fieldName: "sourceFieldsJson", data: json },
          });
          attendance.sourceFieldsJson = json;
        }
      }
    });
  }
}

export function mapObjectToObject({
  keyForward,
  data,
  ignoreNullFields = true,
}: {
  keyForward: KeyForward<Attendance>;
  data: any;
  ignoreNullFields?: boolean;
}) {
  const keys = Object.keys(keyForward);
  let result: any = {};
  for (let key of keys) {
    const splitKey = key.split(".");
    let currData: any = null;
    splitKey.forEach((subKey) => {
      const attendance = currentAttendanceForm.values;
      if (isObject(data?.[subKey]) && splitKey.length > 1) {
        currData = data?.[subKey];
        return;
      }
      if (currData) {
        if (isObject(currData?.[subKey])) {
          currData = currData?.[subKey];
          return;
        }
      }
      let fieldData = currData?.[subKey] ?? data?.[subKey];

      if (!ignoreNullFields || fieldData != null) {
        let attendanceField = keyForward[key];
        let dispatchOptionsProps: any;
        let jsonOptions:
          | { flag: string; attendanceKeyPath?: string }
          | undefined;

        if (typeof attendanceField === "function") {
          const { data, field, dispatchOptions, assignJson } = attendanceField(
            fieldData,
            attendance as Attendance
          );
          fieldData = data;
          attendanceField = field;
          dispatchOptionsProps = dispatchOptions;
          jsonOptions = assignJson;
        }

        let json = attendance?.sourceFieldsJson || "{}";
        let sourceFieldsJson: { [k: string]: { updatedBy: string } } =
          JSON.parse(json);
        let { attendanceKeyPath, flag } = jsonOptions || {};
        attendanceKeyPath =
          attendanceKeyPath == null ? attendanceField : attendanceKeyPath;

        let isSetted = false;

        if (flag === "CONSULTA OFFLINE") {
          const updatedBy = sourceFieldsJson[attendanceKeyPath]?.updatedBy;
          if (updatedBy === flag || updatedBy == null) {
            result[attendanceField] = fieldData;
            // dispatch({
            //   type: "changeField",
            //   payload: { fieldName: attendanceField, data: fieldData },
            //   ...dispatchOptionsProps,
            // });
            if (attendance) (attendance as any)[attendanceField] = fieldData;
            isSetted = true;
          }
        } else {
          result[attendanceField] = fieldData;
          if (attendance) (attendance as any)[attendanceField] = fieldData;
          isSetted = true;
        }
      }
    });
  }
  return result;
}

export function getErrorByMessage(e: any, defaultMessage: string) {
  const statusError = e.response?.data?.message;
  return statusError?.indexOf("msg_interface") === 0
    ? statusError.replace("msg_interface:", "")
    : responseStatusError[statusError] ?? defaultMessage;
}

export const getErrorUploadingMailingByMessage = (
  e: any,
  defaultMessage: string
) => {
  const statusError = e.response?.data?.message;
  if (statusError === "limit_50000_lines") {
    return "Arquivo deve conter no máximo 50 mil linhas";
  } else {
    return defaultMessage;
  }
};

export const digitationFunction = {
  PAN: createDigitacaoPAN,
  FACTA: createDigitacaoFACTA,
  BMG: createDigitacao,
  MASTER: createDigitacao,
};

export const responseStatusError: any = {
  digitador_not_found: "Não há digitador cadastrado!",
  sem_tabela_configurada:
    "Não há tabela configurada para essa operação e prazo!",
  under_development: <>Serviço em desenvolvimento! &#128512;</>,
  digitacao_pan_especie_nao_consignavel:
    "Erro na digitação PAN: espécie não consignável!",
  digitacao_pan_data_invalida: "Erro na digitação PAN: Data Inválida!",
  digitacao_pan_especie_invalida: "Erro na digitação PAN: Espécie Inválida!",
  digitacao_pan_cartao_magnetico_unavailable:
    "Meio de pagamento CARTÃO MAGNETICO inválido pra digitação no PAN. Válido apenas para CONTA-CORRENTE!",
  attendance_already_has_proposal: "Atendimento já possui proposta!",
  servico_indisponivel_tente_novamente:
    "Não foi possível digitar. Tente novamente!",
  valor_parcela_maior_limite_disponivel:
    "Valor da parcela não pode ser maior que o limite disponível!",
  problema_api_pan_tente_novamente_mais_tarde:
    "Problemas na API PAN - Tente novamente mais tarde!",
};
