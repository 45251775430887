import { CloseIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Image,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import apiCarteira from "api/api-carteira";
import { CustomModal } from "components/custom-modal";
import { PIXIcon } from "components/vectors/pix-icon";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { currencyMaskFormat } from "utils/string-formats";
import { Toast } from "components/toast";
import Dropdown from "components/dropdown";

const initialValue = {
  tipoCarteira: "GERAL",
  name: "",
  cpfCnpj: "",
  value: 0,
};

interface PIXProps {
  encodedImage: string;
  payload: string;
  expirationDate: string;
}

export function ModalAdicionarSaldoPIX({
  updateTransacoes,
}: {
  updateTransacoes: () => Promise<void>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  let [modalData, setModalData] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [pixData, setPIXData] = useState<PIXProps>()

  const isDisabledGerarPIX =
    !modalData.cpfCnpj || !modalData.name || !modalData.value || !modalData.tipoCarteira;

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setModalData(initialValue);
    setIsLoading(false);
    setPIXData(undefined);
    updateTransacoes();
  };

  const handleSubmit = async () => {
    if (!isDisabledGerarPIX) {
      setIsLoading(true);
      try {
        const { data } = await apiCarteira.post(
          `/cobrancas/pix/criar-novo-qrcode`,
          modalData
        );
        setPIXData({
          ...data,
          encodedImage: "data:image/png;base64," + data.encodedImage,
        });
      } catch (e: any) {
        const codError = e?.response?.data?.message;
        const errorMessage = (
          {
            cobranca_menor_50: "Insira valor mínimo 50 reais",
            nome_invalido: "Nome inválido",
            cpfCnpj_invalido: "CPF/CNPJ Inválido",
          } as any
        )[codError];
        Toast({ title: errorMessage ?? "Erro ao gerar PIX", status: "error" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEventListener("openModalAdicionarSaldoPIX", onOpen);

  const modalFooter = (
    <>
      {pixData ? null : (
        <Tooltip label={"Preencha os campos corretamente"}>
          <Button
            onClick={handleSubmit}
            opacity={isDisabledGerarPIX ? 0.5 : undefined}
            leftIcon={<PIXIcon width="18px" height="18px" />}
          >
            Gerar PIX
          </Button>
        </Tooltip>
      )}
      <Button
        onClick={onClose}
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  const tipoCarteiraArray = [
    {
      name: "Geral",
      value: "GERAL",
    },
    {
      name: "Fgts",
      value: "FGTS",
    },
  ];

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Adicione Saldo via PIX"
      size="3xl"
      modalFooter={modalFooter}
      isLoading={isLoading}
      containerProps={{ alignItems: "flex-start", py: "40px" }}
    >
      <Grid w="100%" templateColumns={"1fr 1fr"} gap="15px">
        <Flex flexDir="column" gridArea={pixData ? "" : "auto / span 2"}>
          <Box mb="10px">
            <Text mb="8px">Carteira:</Text>
            <Dropdown
              options={tipoCarteiraArray}
              w="100%"
              isDisabled={isLoading || !!pixData}
              placeholder={tipoCarteiraArray[0].name}
              defaultValue={tipoCarteiraArray[0].value}
              value={modalData.tipoCarteira}
              onChange={(value) => {
                console.log("tipo carteira: " + value);
                setModalData({ ...modalData, tipoCarteira: value })
              }}
            />
          </Box>
          <Box mb="10px">
            <Text mb="8px">Nome:</Text>
            <Input
              value={modalData.name}
              onChange={(e) =>
                setModalData({ ...modalData, name: e.target.value })
              }
              isDisabled={isLoading || !!pixData}
            />
          </Box>
          <Box mb="10px">
            <Text mb="8px">CPF ou CNPJ:</Text>
            <Input
              value={modalData.cpfCnpj}
              onChange={(e) =>
                setModalData({ ...modalData, cpfCnpj: e.target.value })
              }
              isDisabled={isLoading || !!pixData}
            />
          </Box>
          <Box>
            <Text mb="8px">Valor (min. R$ 50,00):</Text>
            <Input
              value={currencyMaskFormat(modalData.value).value}
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  value: currencyMaskFormat(e.target.value).raw,
                })
              }
              isDisabled={!!pixData}
            />
          </Box>

          {pixData ? (
            <Box
              px="15px"
              py="10px"
              bg="gray.100"
              p="5px 10px"
              fontSize="14"
              borderRadius="5px"
              mt="auto"
              border="1px solid var(--chakra-colors-gray-200)"
            >
              <Text mb="8px" fontSize="14px" color="red.600">
                Após realização do pagamento, é necessário aguardar alguns
                instantes e atualizar a tabela de transações
              </Text>
            </Box>
          ) : null}
        </Flex>
        <Center w="100%" flexDir="column">
          {pixData ? (
            <>
              <Box
                w="100%"
                border="1px solid var(--chakra-colors-gray-200)"
                borderRadius="5px"
              >
                <Image src={pixData.encodedImage} w="100%" />
              </Box>
              <Grid templateColumns="1fr auto" gap="10px" w="100%" mt="10px">
                <Box>
                  <Text mb="10px">Pix Copia e Cola:</Text>
                  <Input value={pixData?.payload} />{" "}
                </Box>
                <Box>
                  <Text mb="10px">&nbsp;</Text>
                  <Button
                    h="36px"
                    size="sm"
                    leftIcon={<CopyIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(pixData?.payload);
                      Toast({ title: "Copiado" });
                    }}
                  >
                    Copiar
                  </Button>
                </Box>
              </Grid>
            </>
          ) : null}
        </Center>
      </Grid>
    </CustomModal>
  );
}

export const openModalAdicionarSaldoPIX = () =>
  makeEvent("openModalAdicionarSaldoPIX");
