import { useEffect, useState } from "react";
import { AttendanceChatFilterProps } from "..";
import api from "api/api";
import { Toast } from "components/toast";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { DropdownOptionProps } from "components/dropdown";
import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import { NewDropdown } from "components/dropdown/new-dropdown";
import {
  dropdownConvenioAtendimento,
  dropdownTabulacao,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { Popover } from "components/popover";
import { Filter2Icon } from "components/vectors/filter-icon";
import { Checkbox } from "components/checkbox";
import { CalendarFilter } from "components/atendimentos-components/attendance-filter/calendar-filter";

export function AttendanceFilterChats({
  setFilter,
  filters,
  copyFilters,
  setCopyFilters,
}: {
  filters: AttendanceChatFilterProps;
  setFilter: React.Dispatch<React.SetStateAction<AttendanceChatFilterProps>>;
  copyFilters: AttendanceChatFilterProps;
  setCopyFilters: React.Dispatch<
    React.SetStateAction<AttendanceChatFilterProps>
  >;
}) {
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [usersList, setUsersList] = useState<DropdownOptionProps[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  //   const { isLoadingAttendances } = useAttendanceKanbanContext();

  const { controls3cPlus } = use3cPlusContext();

  const getUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const { data } = await api.get("/usuarios/possible-transfer-attendance");
      setUsersList(
        data.map((user: any) => ({ name: user.name, value: user.id }))
      );
    } catch (e) {
      Toast({ title: "Erro ao carregar usuários!", status: "error" });
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onChange = (value: any, key: keyof AttendanceChatFilterProps) => {
    setErrors((err) => {
      delete err[key];
      return err;
    });
    if (Array.isArray(value))
      setCopyFilters((filter) => {
        if (!value.length) {
          delete filter[key];
          return { ...filter };
        }
        return { ...filter, [key]: value };
      });
    else {
      if (value == null || value === "")
        setCopyFilters((attendanceFilter) => {
          delete attendanceFilter[key];
          return { ...attendanceFilter };
        });
      else setCopyFilters({ ...copyFilters, [key]: value });
    }
  };

  const onChangeMultiselect = (value: any, field: "userIds" | "tabulacoes") => {
    const currentEl = copyFilters[field] as any[] | null;
    if (currentEl?.length && currentEl != null) {
      const isInclude = currentEl.includes(value);
      if (isInclude)
        onChange(
          currentEl.filter((curr: any) => curr !== value),
          field
        );
      else onChange([...copyFilters[field]!, value], field);
    } else onChange([value], field);
  };

  useEffect(() => {
    getUsers();
  }, []);

  // const sliderMax = 1000;
  // const sliderMin = 0;

  // const changeSlider = (slider: HTMLInputElement, value: string) => {
  //   slider.value = value;
  //   const progress =
  //     ((parseInt(value) - sliderMin) / (sliderMax - sliderMin)) * 100;
  //   slider.style.backgroundSize = `${progress}% 100%`;
  // };

  // const columnsOptions = initialKanbanColumns.map(({ colName, colId }) => ({
  //   name: colName,
  //   value: colId,
  // }));

  return (
    <Popover
      button={
        <Button
          variant="ghost"
          alignItems="center"
          cursor="pointer"
          _hover={{ bg: "gray.200" }}
          px="10px"
          borderRadius="6px"
          leftIcon={<Filter2Icon />}
          fontSize="14px"
          fontWeight="medium"
          isDisabled={
            controls3cPlus.inCall
            // || isLoadingAttendances
          }
          pos="relative"
        >
          Filtrar por
          {Object.keys(filters).length > 1 ? (
            <Center
              rounded="full"
              bg="red"
              color="white"
              w="14px"
              h="14px"
              fontSize="10px"
              pos="absolute"
              top="-5px"
              right="-5px"
            >
              {Object.keys(filters).length - 1}
            </Center>
          ) : null}
        </Button>
      }
      title="Filtrar por"
      position="left-bottom"
      popupStyles={{ minWidth: "320px" }}
      contentProps={{ padding: "0px" }}
    >
      {({ onClose }) => (
        <>
          <Box overflow="auto" maxHeight="350px" padding="8px">
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Usuário
              </Text>
              <NewDropdown
                maxH={150}
                multiSelect
                isLoading={isLoadingUsers}
                options={usersList}
                onChange={(option) =>
                  onChangeMultiselect(option.value, "userIds")
                }
                value={copyFilters.userIds}
                // isDisabled={isLoadingAttendances}
              />
            </Box>
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Convênio
              </Text>
              <NewDropdown
                maxH={150}
                containerProps={{ maxH: "200px" }}
                options={[
                  { name: "Todos", value: undefined },
                  ...dropdownConvenioAtendimento,
                ]}
                value={copyFilters.convenio}
                onChange={(option) => {
                  onChange(option.value, "convenio");
                }}
                // isDisabled={isLoadingAttendances}
              />
            </Box>
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Tabulações
              </Text>
              <NewDropdown
                maxH={150}
                containerProps={{ maxH: "200px" }}
                multiSelect
                options={dropdownTabulacao}
                value={copyFilters.tabulacoes}
                onChange={(option) => {
                  onChangeMultiselect(option.value, "tabulacoes");
                }}
                // isDisabled={isLoadingAttendances}
              />
            </Box>
            <Box mb="20px">
              <Text mb="8px" fontWeight="medium">
                Data de criação
              </Text>
              <CalendarFilter
                onChange={onChange}
                filter={copyFilters}
                keyDataInicio="fromDataCriacao"
                keyDataFim="toDataCriacao"
              />
            </Box>
            <Flex alignItems={"center"}>
              <Checkbox
                isChecked={!!copyFilters.onlyUnreadMessage}
                onChange={(isChecked) =>
                  onChange(isChecked ? true : null, "onlyUnreadMessage")
                }
              >
                Somente não lidos
              </Checkbox>
            </Flex>
          </Box>
          <Box padding="8px">
            <Button
              w="100%"
              size="lg"
              fontSize="14"
              borderRadius="4"
              onClick={() => {
                // getAllColumnAttendances();
                onClose();
                setFilter(copyFilters);
              }}
            >
              Aplicar
            </Button>
          </Box>
        </>
      )}
    </Popover>
  );
}
