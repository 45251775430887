import { Flex } from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { Message } from "components/chat-window";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";
import { sortArrayBy } from "utils/filter-array-by";
import { ModalWhatsAppImage } from "components/chat-window/message-template/modal-image";
import MessageTemplate from "components/chat-window/message-template";
import { useChatsContext } from "../../chats-context";

export function MessageList({
  wsSession,
  messages,
}: {
  wsSession: WebSocketHook<any, MessageEvent<any> | null>;
  messages: Map<string, Message>;
}) {
  const { selectedAttendance } = useChatsContext();
  const messagesArray = (
    sortArrayBy(Array.from(messages.values()), "timestamp", "DESC") as Message[]
  ).filter(({ attendanceId }) => attendanceId === selectedAttendance?.id);

  return (
    <Flex
      h="100%"
      sx={defaultScroll}
      overflow="auto"
      maxH={`calc(100% - 64px - 53px - 37px)`}
      flexDir="column-reverse"
      p="0px 8px 8px 8px"
    >
      {messagesArray.map((message, index) => (
        <MessageTemplate wsSession={wsSession} key={message.id} {...message} />
      ))}
      <ModalWhatsAppImage />
      {/* END CHAT */}
    </Flex>
  );
}
