import { Flex } from "@chakra-ui/react";
import { ModalChangeUser } from "../modal-change-user";
import { Kanban } from "./kanban";
import { useAttendanceKanbanContext } from "./kanban/kanban-context";
import { AttendanceFilter } from "../attendance-filter";
import { ModalAgendar } from "../modal-agendar";
import { InputSearchNbCpf } from "./search-nb-cpf";
import { WhatsAppAttendances } from "../whatsapp-attendances";
import { NovoAttendanceModal } from "../new-attendance-modal";
import { ChatsContextProvider } from "../whatsapp-attendances/components/chats-context";

export function KanbanAndTableAtendimento() {
  const { visualizationMode, setKanbanColumns } = useAttendanceKanbanContext();

  return (
    <>
      {visualizationMode === "kanban" ? (
        <Flex
          justifyContent="space-between"
          w="100%"
          borderBottom="1px solid #E8EAED"
          p="11px 30px 9px"
          mb="30px"
        >
          <InputSearchNbCpf />
          <Flex alignItems="center">
            <AttendanceFilter />
          </Flex>
        </Flex>
      ) : null}
      {visualizationMode === "kanban" ? (
        <>
          <Kanban />
          <NovoAttendanceModal />
        </>
      ) : visualizationMode === "whats-app-attendances" ? (
        <ChatsContextProvider>
          <WhatsAppAttendances />
        </ChatsContextProvider>
      ) : null}
      <ModalChangeUser
        onUpdate={({ newUserName, attendanceId }) => {
          setKanbanColumns((columns) =>
            columns.map((column) => {
              const searchId = column.attendancesList.find(
                (curr) => curr.id === attendanceId
              );
              if (searchId) {
                column.attendancesList = column.attendancesList.map(
                  (attendace) => {
                    if (attendace.id === attendanceId)
                      attendace.userName = newUserName;
                    return attendace;
                  }
                );
              }
              return column;
            })
          );
        }}
      />
      <ModalAgendar />
    </>
  );
}
