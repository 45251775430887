import { Box, Button, Center, Flex, Grid, IconButton } from "@chakra-ui/react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAttendanceContext } from "contexts/attendance-context";
import {
  Attendance,
  ContratoInss,
  ContratoRCCInss,
  ContratoRMCInss,
} from "components/atendimentos-components/atendimento-form/types";
import { Accordion } from "../../../accordion";
import { CardEmprestimoBancario } from "./contrato-emprestimo-bancario";
import { CardEmprestimoCartao } from "./contrato-cartao";
import { ChevronDownIcon } from "components/vectors/chevron-down-icon";
import { RepeatIcon } from "@chakra-ui/icons";
import { ModalUpdateContract } from "./modal-update-contract";
import { updateContratos } from "../utils";
import {
  ErrorMessage,
  ErrorMessageProps,
  InfoMessage,
  InfoMessageProps,
} from "./error-message";
import { colorsMap } from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export type GETConsultationStatusProps = {
  banco?: string;
  codBanco?: number;
  tipoHigienizacao: "SAQUE_COMPLEMENTAR";
  tipoCartao?: string;
  finalizado: boolean;
  dataFinalizacao?: string;
  error?: string;
  contratoRCC?: ContratoRCCInss;
  contratoRMC?: ContratoRMCInss;
  contratos?: ContratoInss[];
  mensagem: null | string;
}[];

const initialErrorsValue = {
  RCC: undefined,
  RMC: undefined,
  REFIN: undefined,
};

export function ContratosAtendimentoFormPart({
  isLoading,
  setIsLoading,
  activeTab,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  activeTab: AttendanceTabKey;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const [higienizacaoLoadings, setHigienizacaoLoadings] = useState({
    RCC: false,
    RMC: false,
    REFIN: false,
  });
  const [accordionErrors, setAccordionErrors] = useState<{
    RCC?: ErrorMessageProps["errorMessage"];
    RMC?: ErrorMessageProps["errorMessage"];
    REFIN?: ErrorMessageProps["errorMessage"];
  }>(initialErrorsValue);
  const [accordionInfos, setAccordionInfos] = useState<{
    RCC?: InfoMessageProps["infoMessage"];
    RMC?: InfoMessageProps["infoMessage"];
    REFIN?: InfoMessageProps["infoMessage"];
  }>(initialErrorsValue);
  const { user } = useApplicationContext();
  const attendance = formControl.values as Attendance;
  const { contratosRMCInss: contratosCartaoRMCInss } = attendance;
  const { contratosRCCInss: contratosCartaoRCCInss } = formControl.values;
  const { contratosEmprestimosInss } = attendance;
  const { statusHigienizacoesAtendimento } = attendance;

  const getOriginTag = (fieldName: string) => {
    const jsonSerialized = JSON.parse(
      formControl.values.sourceFieldsJson || "{}"
    );
    let tagText = jsonSerialized[fieldName]?.updatedBy;
    if (tagText === "CONSULTA OFFLINE") tagText = "";
    const color = colorsMap.get(tagText);
    return { tagText, color, isVisible: !!tagText };
  };

  const onChangeInput = useCallback(
    (fieldName: keyof Attendance) => (value: string) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: value },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const onChangeDropdown = useCallback(
    (fieldName: keyof Attendance) => (newValue: any) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: newValue },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const updateAllContratos = () => {
    updateContratos({
      attendance,
      dispatch,
      setHigienizacaoLoadings,
      type: "REFIN",
      setAccordionErrors,
      setAccordionInfos,
      forceRequest: true,
    });
    updateContratos({
      attendance,
      dispatch,
      setHigienizacaoLoadings,
      type: "RMC",
      setAccordionErrors,
      setAccordionInfos,
      forceRequest: true,
    });
    updateContratos({
      attendance,
      dispatch,
      setHigienizacaoLoadings,
      type: "RCC",
      setAccordionErrors,
      setAccordionInfos,
      forceRequest: true,
    });
  };

  useEffect(() => {
    if (formControl.pendingChanges) {
      if (!formControl.isExtratoCopiado) {
        updateAllContratos();
      }
    } else {
      updateAllContratos();
    }
  }, []);

  return (
    <>
      <ModalUpdateContract />
      <Accordion
        title="Contratos Empréstimos"
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => (
          <Flex alignItems="center">
            <Box onClick={(e) => e.stopPropagation()} mr="8px">
              <InfoMessage
                isMessage={accordionInfos.REFIN != null}
                infoMessage={accordionInfos.REFIN}
              />
            </Box>
            <Box onClick={(e) => e.stopPropagation()} mr="8px">
              <ErrorMessage
                isError={accordionErrors.REFIN != null}
                errorMessage={accordionErrors.REFIN}
              />
            </Box>
            <Button
              aria-label=""
              leftIcon={<RepeatIcon />}
              isLoading={higienizacaoLoadings.REFIN}
              onClick={async (e) => {
                e.stopPropagation();
                updateContratos({
                  attendance,
                  dispatch,
                  setHigienizacaoLoadings,
                  type: "REFIN",
                  setAccordionErrors,
                  setAccordionInfos,
                });
              }}
              size="sm"
              borderRadius="5px"
              px="8px"
              mr="8px"
              h="28px"
              variant="outline"
            >
              {!!statusHigienizacoesAtendimento?.pendingEmprestimos?.length
                ? "Atualizar"
                : "Higienizar"}
            </Button>
            <IconButton
              aria-label=""
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
              size="xs"
              borderRadius="full"
              w="28px"
              minW="28px"
              h="28px"
              icon={<ChevronDownIcon />}
              transform={isOpen ? "rotate(180deg)" : ""}
            />
          </Flex>
        )}
      >
        <Grid templateColumns="1fr" gap="10px">
          {contratosEmprestimosInss?.map((emprestimo, index, arr) => {
            return (
              <CardEmprestimoBancario
                key={index}
                emprestimo={emprestimo}
                dispatch={dispatch}
                emprestimoList={arr}
                index={index}
                activeTab={activeTab}
              />
            );
          })}
        </Grid>
      </Accordion>

      <Accordion
        title="Contrato Cartão (RMC)"
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => (
          <Flex alignItems="center">
            {getOriginTag("contratosCartaoRMCInss").isVisible ? (
              <Center
                p="2px 5px"
                fontSize="11"
                borderRadius="5px"
                bg={getOriginTag("contratosCartaoRMCInss").color}
                color="#fff"
                ml="8px"
              >
                {getOriginTag("contratosCartaoRMCInss").tagText}
              </Center>
            ) : null}
            <Box mr="8px" onClick={(e) => e.stopPropagation()}>
              <InfoMessage
                isMessage={accordionInfos.RMC != null}
                infoMessage={accordionInfos.RMC}
              />
            </Box>
            <Box onClick={(e) => e.stopPropagation()}>
              <ErrorMessage
                isError={accordionErrors.RMC != null}
                errorMessage={accordionErrors.RMC}
              />
            </Box>
            <Button
              aria-label=""
              leftIcon={<RepeatIcon />}
              isLoading={higienizacaoLoadings.RMC}
              onClick={async (e) => {
                e.stopPropagation();
                updateContratos({
                  attendance,
                  dispatch,
                  setHigienizacaoLoadings,
                  type: "RMC",
                  setAccordionErrors,
                  setAccordionInfos,
                });
              }}
              size="sm"
              borderRadius="5px"
              px="8px"
              mr="8px"
              h="28px"
              variant="outline"
            >
              {statusHigienizacoesAtendimento?.hasPendingRMC
                ? "Atualizar"
                : "Higienizar"}
            </Button>
            <IconButton
              aria-label=""
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
              size="xs"
              borderRadius="full"
              w="28px"
              minW="28px"
              h="28px"
              icon={<ChevronDownIcon />}
              transform={isOpen ? "rotate(180deg)" : ""}
            />
          </Flex>
        )}
      >
        <Grid templateColumns="1fr">
          {contratosCartaoRMCInss?.map((emprestimo, index, arr) => {
            return (
              <CardEmprestimoCartao
                key={index}
                emprestimo={emprestimo as any}
                field="contratosRMCInss"
              />
            );
          })}
        </Grid>
      </Accordion>

      <Accordion
        title="Contrato Cartão (RCC)"
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => (
          <Flex alignItems="center">
            {getOriginTag("contratosCartaoRCCInss").isVisible ? (
              <Center
                p="2px 5px"
                fontSize="11"
                borderRadius="5px"
                bg={getOriginTag("contratosCartaoRCCInss").color}
                color="#fff"
                ml="8px"
              >
                {getOriginTag("contratosCartaoRCCInss").tagText}
              </Center>
            ) : null}
            <Box onClick={(e) => e.stopPropagation()}>
              <InfoMessage
                isMessage={accordionInfos.RCC != null}
                infoMessage={accordionInfos.RCC}
              />
            </Box>
            <Box onClick={(e) => e.stopPropagation()}>
              <ErrorMessage
                isError={accordionErrors.RCC != null}
                errorMessage={accordionErrors.RCC}
              />
            </Box>
            <Button
              aria-label=""
              leftIcon={<RepeatIcon />}
              isLoading={higienizacaoLoadings.RCC}
              onClick={async (e) => {
                e.stopPropagation();
                updateContratos({
                  attendance,
                  dispatch,
                  setHigienizacaoLoadings,
                  type: "RCC",
                  setAccordionErrors,
                  setAccordionInfos,
                });
              }}
              size="sm"
              borderRadius="5px"
              px="8px"
              mr="8px"
              h="28px"
              variant="outline"
            >
              {statusHigienizacoesAtendimento?.hasPendingRCC
                ? "Atualizar"
                : "Higienizar"}
            </Button>
            <IconButton
              aria-label=""
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
              size="xs"
              borderRadius="full"
              w="28px"
              minW="28px"
              h="28px"
              icon={<ChevronDownIcon />}
              transform={isOpen ? "rotate(180deg)" : ""}
            />
          </Flex>
        )}
      >
        <Grid templateColumns="1fr">
          {contratosCartaoRCCInss?.map((emprestimo, index, arr) => {
            return (
              <CardEmprestimoCartao
                key={index}
                emprestimo={emprestimo}
                field="contratosRCCInss"
              />
            );
          })}
        </Grid>
      </Accordion>
    </>
  );
}
