import { Toast } from "components/toast";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import {
  Attendance,
  AttendanceFactaFGTS,
} from "components/atendimentos-components/atendimento-form/types";
import {
  AttendanceActionsTypes,
  currentAttendanceForm,
  FormControl,
} from "contexts/attendance-context/actions-reducer";
import {
  consultaFGTS,
  mapConsultaFGTS,
} from "components/atendimentos-components/atendimento-form/functions/consulta";
import { DropdownOptionProps } from "components/dropdown";
import { Dispatch, SetStateAction } from "react";
import { fieldValidation } from "utils/field-validation";
import { objectSchema } from "utils/object-methods";
import { TabelaDigitacaoProps } from "components/atendimentos-components/pagina-atendimento/pagined-form/parts/simulacoes/simulacoes";
import apiDigitacao from "api/api-digitacao";
import { CustomTableColorColumn } from "components/dynamic-table/color-table";
import { Checkbox } from "components/checkbox";
import { Router } from "routes/router-control/use-router";

export interface TabelaOptionsColomboProps {
  id: number;
  convenio: string;
  tipoOperacao: null;
  banco: string;
  prazo: null;
  codigo: string;
  descricaoCompleta: string;
  needToken: boolean;
  token: null;
}

export interface TabelaOptionsProps {
  banco: string;
  codigo: string;
  convenio: string;
  descricao: string;
  prazo: null;
  tipoOperacao: null;
}

export const dropdownBancosSimulacaoFGTSColombo = [
  { name: "BMG", value: "BMG" },
  { name: "FACTA", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "SAFRA", value: "SAFRA" },
  { name: "MASTER", value: "MASTER" },
  { name: "NOVO SAQUE", value: "NOVO_SAQUE" },
  { name: "LOTUS TAYA", value: "LOTUS_TAYA" },
];

export const dropdownBancosSimulacaoFGTS = [
  { name: "Facta", value: "FACTA" },
  // { name: "PAN", value: "PAN" },
  // { name: "Safra", value: "SAFRA" },
  // { name: "Master", value: "MASTER" },
  { name: "Itaú", value: "ITAU" },
  // { name: "Bmg", value: "BMG" },
  // { name: "Novo Saque", value: "NOVO_SAQUE" },
  { name: "Lotus", value: "LOTUS_TAYA" },
  // { name: "UY3", value: "UY3" },
  { name: "VCTeX", value: "VCTEX" },

  { name: "Tá Quitado", value: "TA_QUITADO" },
  // { name: "V8", value: "V8" },
];

export const simulacoesColumnsColombo = ({
  dispatch,
  formValues,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  formValues: Attendance | Partial<Attendance>;
}): CustomTableColorColumn[] => {
  const simulacaoAntecipacaoFactaFGTS =
    formValues.simulacaoAntecipacaoFactaFGTS || [];
  const onChangeCheckBoxes = (isChecked: boolean, row: any) => {
    let isExecuted = false;
    const changeChecked = simulacaoAntecipacaoFactaFGTS.map((crrRow, index) => {
      if (row.sequence - 1 === index) {
        const nextCheckBox = simulacaoAntecipacaoFactaFGTS[index + 1];
        isExecuted = true;
        if (!isChecked && nextCheckBox?.checked)
          return { ...crrRow, checked: true };
        return { ...crrRow, checked: isChecked };
      }
      if (!isExecuted) {
        return { ...crrRow, checked: true };
      }
      return { ...crrRow, checked: false };
    });
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        data: changeChecked,
        fieldName: "simulacaoAntecipacaoFactaFGTS",
      },
    });
  };
  return [
    {
      name: "",
      render: (row) => (
        <Checkbox
          onChange={(isChecked) => onChangeCheckBoxes(isChecked, row)}
          isChecked={row.checked}
        />
      ),
    },
    {
      name: "DATA",
      render: (row) => {
        return row.data;
      },
    },
    {
      name: "VALOR DESCONTADO",
      render: (row) => {
        return row.valorDescontado;
      },
    },
  ];
};

export const simulacoesColumns = ({
  formValues,
  parcelas,
  setTabelaDigitacao,
}: {
  setTabelaDigitacao: Dispatch<SetStateAction<Partial<TabelaDigitacaoProps>>>;
  formValues: Attendance | Partial<Attendance>;
  parcelas: TabelaDigitacaoProps["parcelas"];
}): CustomTableColorColumn[] => {
  const simulacaoAntecipacaoFactaFGTS = parcelas || [];
  const onChangeCheckBoxes = (isChecked: boolean, row: any) => {
    let isExecuted = false;
    const changeChecked = simulacaoAntecipacaoFactaFGTS.map((crrRow, index) => {
      if (row.sequence - 1 === index) {
        const nextCheckBox = simulacaoAntecipacaoFactaFGTS[index + 1];
        isExecuted = true;
        if (!isChecked && nextCheckBox?.checked)
          return { ...crrRow, checked: true };
        return { ...crrRow, checked: isChecked };
      }
      if (!isExecuted) {
        return { ...crrRow, checked: true };
      }
      return { ...crrRow, checked: false };
    }) as TabelaDigitacaoProps["parcelas"];

    setTabelaDigitacao((prev) => ({ ...prev, parcelas: changeChecked }));
  };

  return [
    {
      name: "",
      render: (row) => (
        <Checkbox
          onChange={(isChecked) => onChangeCheckBoxes(isChecked, row)}
          isChecked={row.checked}
        />
      ),
    },
    {
      name: "DATA",
      render: (row) => {
        return row.data;
      },
    },
    {
      name: "VALOR DESCONTADO",
      render: (row) => {
        return row.valorDescontado;
      },
    },
  ];
};

export const getTabelaOptionsColombo = async ({
  setLoadingTabela,
  setDropdownTabela,
  bancoDigitacaoFGTSAPI,
  dispatch,
}: {
  setDropdownTabela: Dispatch<
    SetStateAction<{ name: string; value: string }[]>
  >;
  setLoadingTabela: Dispatch<SetStateAction<boolean>>;
  bancoDigitacaoFGTSAPI: string;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) => {
  setLoadingTabela(true);
  const url =
    new Map([
      ["BMG", "/tabelas-digitacao/fgts/BMG"],
      ["FACTA", "/tabelas-digitacao/facta/fgts"],
      ["PAN", "/tabelas-digitacao/fgts/PAN"],
      ["SAFRA", "/tabelas-digitacao/fgts/SAFRA"],
      ["MASTER", "/tabelas-digitacao/fgts/MASTER"],
      ["NOVO_SAQUE", "/tabelas-digitacao/fgts/NOVO_SAQUE"],
      ["LOTUS_TAYA", "/tabelas-digitacao/fgts/LOTUS_TAYA"],
    ]).get(bancoDigitacaoFGTSAPI) || "";
  try {
    const { data }: { data: TabelaOptionsColomboProps[] } = await api.get(url);
    setDropdownTabela(
      data.map(({ codigo, descricaoCompleta }) => {
        return { name: `${codigo} - ${descricaoCompleta}`, value: codigo };
      })
    );
  } catch (e) {
    setDropdownTabela([]);
    Toast({ title: "Erro ao obter tabelas" });
  } finally {
    setLoadingTabela(false);
  }
};

export const getTabelaOptions = async ({
  setLoadingTabela,
  setDropdownTabela,
  bancoDigitacaoFGTSAPI,
  dispatch,
}: {
  setDropdownTabela: Dispatch<
    SetStateAction<{ name: string; value: string }[]>
  >;
  setLoadingTabela: Dispatch<SetStateAction<boolean>>;
  bancoDigitacaoFGTSAPI: string;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) => {
  setLoadingTabela(true);
  try {
    const { data }: { data: TabelaOptionsProps[] } = await apiDigitacao.get(
      `/api/tabelas-digitacao/enable?convenio=ANTECIPACAO_FGTS&banco=${bancoDigitacaoFGTSAPI}`
    );
    setDropdownTabela(
      data.map(({ codigo, descricao }) => {
        return { name: `${codigo} - ${descricao}`, value: codigo };
      })
    );
  } catch (e) {
    setDropdownTabela([]);
    Toast({ title: "Erro ao obter tabelas" });
  } finally {
    setLoadingTabela(false);
  }
};

export const operacaoOptions = [
  { name: "Refin.", value: "REFIN" },
  { name: "Port. com Refin.", value: "PORT_REFIN" },
  { name: "Port. Pura", value: "PORT_PURA" },
];

export const novoPrazoOptions: DropdownOptionProps[] = [
  {
    name: "96",
    value: 96,
  },
  {
    name: "84",
    value: 84,
  },
  {
    name: "72",
    value: 72,
  },
  {
    name: "60",
    value: 60,
  },
];

export const valorParcelaReduzida = (
  taxa: number | string | undefined,
  saldoQuitacao: number | string,
  formValues: Attendance,
  quantidadeParcelasEmAberto: number | string
) => {
  if (taxa != null && saldoQuitacao != null)
    try {
      let result: undefined | number;
      const parsedTaxa = Number(taxa);
      const parsedSaldoQuitacao = Number(saldoQuitacao);
      if (
        ["REFIN", "PORT_REFIN"].includes(formValues.simulacaoOperacao) &&
        formValues.simulacaoNovoPrazo
      ) {
        result =
          (parsedTaxa /
            100 /
            (1 -
              Math.pow(1 + parsedTaxa / 100, -formValues.simulacaoNovoPrazo))) *
          parsedSaldoQuitacao;
        return Number(result.toFixed(2));
      } else if (quantidadeParcelasEmAberto) {
        result =
          (parsedTaxa /
            100 /
            (1 -
              Math.pow(
                1 + parsedTaxa / 100,
                -Number(quantidadeParcelasEmAberto)
              ))) *
          parsedSaldoQuitacao;
        return Number(result.toFixed(2));
      }
    } catch (e) {}
};

const checkSimulacaoFields = ({
  formControl,
  dispatch,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  formControl: FormControl;
}) => {
  formControl.values.atendimentoFactaFGTS =
    formControl.values.atendimentoFactaFGTS || ({} as AttendanceFactaFGTS);

  return objectSchema(
    {
      cpf: (value) => {
        const message = "CPF inválido";
        const { isValid, errorMessage } = fieldValidation({ value }).cpf({
          message,
        });
        if (!isValid)
          dispatch({
            type: "setInvalidField",
            payload: { fieldName: "cpf", action: "append" },
          });
        return { valid: isValid, message: errorMessage };
      },
      atendimentoFactaFGTS: {
        bancoDigitacaoFGTSAPI: (value) => {
          const message = "Selecione o banco";
          const { isValid, errorMessage } = fieldValidation({ value }).required(
            {
              message,
            }
          );
          if (!isValid)
            dispatch({
              type: "setInvalidField",
              payload: {
                fieldName: "atendimentoFactaFGTS.bancoDigitacaoFGTSAPI",
                action: "append",
              },
            });
          return { valid: isValid, message: errorMessage };
        },
        codTable: (value) => {
          const message = "Selecione a tabela";
          const { isValid, errorMessage } = fieldValidation({ value }).required(
            { message }
          );
          if (!isValid)
            dispatch({
              type: "setInvalidField",
              payload: {
                fieldName: "atendimentoFactaFGTS.codTable",
                action: "append",
              },
            });
          return { valid: isValid, message: errorMessage };
        },
      },
    },
    formControl.values
  );
};

export const consultarFGTS = ({
  dispatch,
  formControl,
  loading,
  tabelaDigitacao,
  setTabelaDigitacao,
  queryAlreadyCreated,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  formControl: FormControl;
  loading: Dispatch<SetStateAction<boolean>>;
  tabelaDigitacao: Partial<TabelaDigitacaoProps>;
  setTabelaDigitacao: React.Dispatch<
    React.SetStateAction<Partial<TabelaDigitacaoProps>>
  >;
  queryAlreadyCreated?: boolean;
}) => {
  const cpf = formControl.values.cpf;
  const atendimentoId = formControl.values.id;
  const banco = tabelaDigitacao.banco;
  const codTable = tabelaDigitacao.codTable!;

  if (!fieldValidation({ value: cpf }).cpf().isValid) {
    dispatch({
      type: "setInvalidField",
      payload: { fieldName: "cpf", action: "append" },
    });
    return Toast({ title: "Insira um CPF Válido" });
  }

  if (formControl.pendingChanges) {
    return Toast({
      title: "Salve as alterações para fazer a consulta",
    });
  }

  consultaFGTS({
    loading,
    consultar: queryAlreadyCreated
      ? undefined
      : () =>
          apiDigitacao.post(`/api/simulacoes/fgts/solicitar-saldo`, {
            codTable,
            banco,
            atendimentoId,
            cpf,
          }),
    verificar: () => {
      console.log(currentAttendanceForm.values.id, atendimentoId);
      return apiDigitacao.get(
        `api/simulacoes/fgts/consultar-solicitacao-saldo?atendimento_id=${atendimentoId}`
      );
    },
    isEndLoop: (response) => {
      return (
        response.data?.statusSaldo !== "PENDING" ||
        currentAttendanceForm.values.id !== atendimentoId
      );
    },
    onFinish: (response) => {
      setTabelaDigitacao(response.data);
    },
  });
};

export const consultarFGTSColombo = ({
  dispatch,
  formControl,
  loading,
  codTable,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  loading: Dispatch<SetStateAction<boolean>>;
  codTable: string;
}) => {
  const atendimentoId = formControl.values.id;
  const banco = formControl.values.atendimentoFactaFGTS?.bancoDigitacaoFGTSAPI;
  const { errors, fieldsErrors } = checkSimulacaoFields({
    dispatch,
    formControl,
  });
  for (let k of fieldsErrors) {
    if (errors[k]) {
      return Toast({ title: errors[k] });
    }
  }
  if (formControl.pendingChanges) {
    return Toast({
      title: "Salve as alterações para fazer a consulta",
    });
  }

  consultaFGTS({
    loading,
    consultar: () =>
      api.get(`/facta/fgts/solicitar-saldo/${atendimentoId}`, {
        params: { codTable, banco },
      }),
    verificar: () =>
      api.get(`/facta/fgts/consultar-solicitacao-saldo/${atendimentoId}`),
    isEndLoop: (response) =>
      response.data?.statusSaldo !== "PENDING" ||
      currentAttendanceForm.values.id !== atendimentoId,
    onFinish: (response) => {
      const formValues = formControl.values as Attendance;
      mapConsultaFGTS(response?.data, dispatch, formValues);
    },
  });
};

export const reCalcularFGTS = ({
  dispatch,
  formControl,
  loading,
  setTabelaDigitacao,
  tabelaDigitacao,
  queryAlreadyCreated,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  formControl: FormControl;
  loading: Dispatch<SetStateAction<boolean>>;
  tabelaDigitacao: Partial<TabelaDigitacaoProps>;
  setTabelaDigitacao: React.Dispatch<
    React.SetStateAction<Partial<TabelaDigitacaoProps>>
  >;
  queryAlreadyCreated?: boolean;
}) => {
  const cpf = formControl.values.cpf;
  const atendimentoId = formControl.values.id;
  const banco = tabelaDigitacao.banco;
  const codTable = tabelaDigitacao.codTable;
  const parcelas = tabelaDigitacao.parcelas;

  if (!fieldValidation({ value: cpf }).cpf().isValid) {
    dispatch({
      type: "setInvalidField",
      payload: { fieldName: "cpf", action: "append" },
    });
    return Toast({ title: "Insira um CPF Válido" });
  }
  if (formControl.pendingChanges) {
    return Toast({
      title: "Salve as alterações para fazer a consulta",
    });
  }

  consultaFGTS({
    loading,
    consultar: queryAlreadyCreated
      ? undefined
      : () =>
          apiDigitacao.post(`/api/simulacoes/fgts/solicitar-recalculo`, {
            codTable,
            banco,
            atendimentoId,
            cpf,
            parcelas,
          }),
    verificar: () =>
      apiDigitacao.get(
        `/api/simulacoes/fgts/consultar-solicitacao-recalculo?atendimento_id=${atendimentoId}`
      ),
    errorMessage: "Erro ao recalcular",
    isEndLoop: (response) => {
      return (
        response.data?.statusRecalculo !== "PENDING" ||
        currentAttendanceForm.values.id !== atendimentoId
      );
    },
    onFinish: (response) => {
      setTabelaDigitacao(response.data);
    },
  });
};

export const reCalcularFGTSColombo = ({
  dispatch,
  formControl,
  loading,
  codTable,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  loading: Dispatch<SetStateAction<boolean>>;
  codTable: string;
}) => {
  const cpf = formControl.values.cpf;
  const atendimentoId = formControl.values.id;
  const simulacaoAntecipacaoFactaFGTS =
    formControl.values.simulacaoAntecipacaoFactaFGTS ?? [];

  if (!fieldValidation({ value: cpf }).cpf().isValid) {
    dispatch({
      type: "setInvalidField",
      payload: { fieldName: "cpf", action: "append" },
    });
    return Toast({ title: "Insira um CPF Válido" });
  }
  if (formControl.pendingChanges) {
    return Toast({
      title: "Salve as alterações para fazer a consulta",
    });
  }

  consultaFGTS({
    loading,
    consultar: () =>
      api.post(`/facta/fgts/solicitar-recalculo/${atendimentoId}`, {
        cpf,
        simulacaoAntecipacaoFactaFGTS,
        codTable: codTable,
      }),
    verificar: () =>
      api.get(`/facta/fgts/consultar-solicitacao-recalculo/${atendimentoId}`),
    errorMessage: "Erro ao recalcular",
    isEndLoop: (response) => {
      return (
        response.data?.statusRecalculo !== "PENDING" ||
        currentAttendanceForm.values.id !== atendimentoId
      );
    },
    onFinish: (response) => {
      const formValues = formControl.values as Attendance;
      mapConsultaFGTS(response?.data, dispatch, formValues);
    },
  });
};
