import { Box, Center, Flex } from "@chakra-ui/react";
import { UserIcon2 } from "components/vectors/user-icon";
import { phoneFormat } from "utils/string-formats";
import { ContactInfo } from "../hooks/use-whats-app-session-controls";
import { useChatsContext } from "../../chats-context";
import {
  grayWhatsapp,
  greenWhatsapp,
} from "../../conversation-attendance-card";
import {
  openTelefoneContatoDialog,
  TelefoneContatoDialog,
} from "atendimento/TelefoneContatoDialog";
import { EditIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useAttendanceContext } from "contexts/attendance-context";

export function ChatHeader({
  contactInfo,
}: {
  contactInfo: Partial<ContactInfo>;
}) {
  const [isLoading, setIsLaoding] = useState(false);
  const { selectedAttendance } = useChatsContext();
  const { formControl } = useAttendanceContext();
  const attendance = formControl.values;
  const nome = attendance.nome || selectedAttendance?.nome;
  const phone =
    attendance.telefoneContatoNormalizado ||
    selectedAttendance?.telefoneContatoNormalizado;

  return (
    <Flex w="100%" h="64px" py="10px" px="15px" bg={greenWhatsapp[700]}>
      <Center
        rounded="full"
        h="49px"
        w="49px"
        bgSize="cover"
        bgPos="center"
        bgImage={`url(${contactInfo.profilePicUrl})`}
        bgColor={contactInfo.profilePicUrl ? undefined : grayWhatsapp[300]}
      >
        {contactInfo.profilePicUrl ? null : (
          <UserIcon2 width="40px" height="40px" />
        )}
      </Center>
      <Flex
        alignItems="center"
        fontSize="16px"
        ml="10px"
        fontWeight="medium"
        color="#fff"
      >
        {(phone ? nome : null) ||
          phoneFormat(phone, { withCountryNumber: phone?.length! > 11 })}
        {phone ? (
          <Flex
            as="span"
            ml="5px"
            h={contactInfo.name ? "20px" : "25px"}
            w={contactInfo.name ? "20px" : "25px"}
            alignItems="center"
            justifyContent="center"
            borderRadius="3"
            _hover={{ bg: "rgba(255,255,255, 0.12)" }}
            onClick={() => openTelefoneContatoDialog()}
          >
            <EditIcon />
          </Flex>
        ) : (
          <Box cursor="pointer" onClick={() => openTelefoneContatoDialog()}>
            Adicionar telefone (WhatsApp)
          </Box>
        )}
      </Flex>
      <TelefoneContatoDialog isLoading={isLoading} setLoading={setIsLaoding} />
    </Flex>
  );
}
